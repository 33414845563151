import { InvoiceDisplayStatus, InvoicePaymentDisplayStatusType } from 'anchor-scout/types';
import { differenceInDays } from 'date-fns';
import { timeModel } from "../models";
import { InvoiceStatus } from "../types/api.model";
const displayedStatusToLabel = {
  issued: 'Issued',
  overdue: 'Overdue',
  paymentInitiated: 'Processing',
  paid: 'Paid',
  voided: 'Voided',
  refunded: 'Refunded',
  refundFailed: 'Refund failed',
  disputed: 'Disputed'
};
export function getStatusLabel(displayedStatus) {
  return displayedStatusToLabel[displayedStatus];
}
export function getDisplayedStatus(status, dueDate, now) {
  switch (status) {
    case InvoiceStatus.Pending:
    case InvoiceStatus.RequiresPaymentApproval:
    case InvoiceStatus.PaymentActionRequired:
    case InvoiceStatus.PaymentFailed:
    case InvoiceStatus.PaymentStopped:
      {
        const isOverdue = differenceInDays(dueDate, now) < 0;
        return isOverdue ? InvoiceDisplayStatus.Overdue : InvoiceDisplayStatus.Issued;
      }
    case InvoiceStatus.ProcessingCharge:
    case InvoiceStatus.PaymentConfirmed:
      return InvoiceDisplayStatus.PaymentInitiated;
    case InvoiceStatus.Paid:
    case InvoiceStatus.PaidExternally:
      return InvoiceDisplayStatus.Paid;
    case InvoiceStatus.Voided:
      return InvoiceDisplayStatus.Voided;
    case InvoiceStatus.Refunded:
      return InvoiceDisplayStatus.Refunded;
    case InvoiceStatus.RefundFailed:
      return InvoiceDisplayStatus.RefundFailed;
    case InvoiceStatus.PaymentDisputed:
      return InvoiceDisplayStatus.PaymentDisputed;
  }
}
export function getPaymentStatusText(displayedPaymentStatus, isVendor) {
  if (!displayedPaymentStatus) {
    return '';
  } else {
    switch (displayedPaymentStatus.type) {
      case InvoicePaymentDisplayStatusType.PaymentMethodNotSet:
        return 'Payment method not set';
      case InvoicePaymentDisplayStatusType.PaymentMethodUnverified:
        return 'Payment method not verified';
      case InvoicePaymentDisplayStatusType.PaymentFailed:
        return 'Payment failed';
      case InvoicePaymentDisplayStatusType.Paid:
        {
          const formattedDate = timeModel.formatDate(displayedPaymentStatus.timestamp);
          return displayedPaymentStatus.paidInFutureDay ? "Deposit expected on ".concat(formattedDate) : "Deposited on ".concat(formattedDate);
        }
      case InvoicePaymentDisplayStatusType.PaidExternally:
        return "Paid externally on ".concat(timeModel.formatDate(displayedPaymentStatus.timestamp));
      case InvoicePaymentDisplayStatusType.RequiresPaymentApproval:
        return isVendor ? 'Client approval required' : 'Pending your approval';
      case InvoicePaymentDisplayStatusType.RequiresPaymentValidation:
        return 'Payment validation required';
      case InvoicePaymentDisplayStatusType.Pending:
        return 'Payment pending initiation';
      case InvoicePaymentDisplayStatusType.Processing:
        return 'Payment initiated';
      case InvoicePaymentDisplayStatusType.Collected:
        return 'Payment collected';
      case InvoicePaymentDisplayStatusType.Refunded:
        return "Refunded on ".concat(timeModel.formatDate(displayedPaymentStatus.timestamp));
      case InvoicePaymentDisplayStatusType.RefundFailed:
        return "Refund failed on ".concat(timeModel.formatDate(displayedPaymentStatus.timestamp));
      case InvoicePaymentDisplayStatusType.StoppedPayment:
        {
          const date = timeModel.formatDate(displayedPaymentStatus.timestamp);
          return isVendor ? "Client stopped payment on ".concat(date) : "Payment stopped on ".concat(date);
        }
      case InvoicePaymentDisplayStatusType.Disputed:
        return "Disputed on ".concat(timeModel.formatDate(displayedPaymentStatus.timestamp));
      case InvoicePaymentDisplayStatusType.RefundRequested:
        return 'Refund requested';
    }
  }
}
export function allowResendAction(status, isVendor) {
  return isVendor && status !== InvoiceStatus.PaymentDisputed;
}
export function allowVoidAction(status, isVendor) {
  if (!isVendor) {
    return false;
  }
  switch (status) {
    case InvoiceStatus.RequiresPaymentApproval:
    case InvoiceStatus.PaymentFailed:
    case InvoiceStatus.PaymentStopped:
    case InvoiceStatus.Pending:
    case InvoiceStatus.PaymentActionRequired:
      return true;
    default:
      return false;
  }
}