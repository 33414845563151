// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Slate = require("slate");
var SlateReact = require("slate-react");
var RichTextEditor_Leaf$AnchorFrontend = require("./RichTextEditor_Leaf.bs.js");
var RichTextEditor_Element$AnchorFrontend = require("./RichTextEditor_Element.bs.js");
function RichTextViewer(Props) {
  var value = Props.value;
  var editor = React.useMemo(function () {
    return SlateReact.withReact(Slate.createEditor());
  }, []);
  var renderElement = React.useCallback(function (props) {
    return React.createElement(RichTextEditor_Element$AnchorFrontend.make, props);
  });
  var renderLeaf = React.useCallback(function (props) {
    return React.createElement(RichTextEditor_Leaf$AnchorFrontend.make, props);
  });
  return React.createElement(SlateReact.Slate, {
    editor: editor,
    value: value,
    children: React.createElement(SlateReact.Editable, {
      readOnly: true,
      renderElement: renderElement,
      renderLeaf: renderLeaf
    })
  });
}
var make = RichTextViewer;
exports.make = make;
/* react Not a pure module */