function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import pick from 'lodash/pick';
import { fromDatestampObject } from "../models/time";
import { prepareCreateChargeDto } from "../types/api/createChargeDto";
import { billingControlDataModel, contactModel, integratedInvoiceModel, invoiceDraftModel, proposalTemplateModel, proposalTemplatesSummaryModel, qboCustomerModel, relationshipModel, relationshipSummaryModel, serviceModel, serviceTemplateModel, timeModel } from "../models";
import { selectors } from "./state-selectors";
import { draftModel } from "../models/draft";
import { invoiceSummaryModel, payoutModel, activityLogModel, referralModel, invoiceModel } from "../models";
import { filtersConfig } from "../pages/AgreementsDashboard/agreementsDashboardFilters";
import { utils } from "../utils";
import { ServiceStatusType } from "../models/service/model";
import { APIHost } from "./sendRequest";
import { ServerError } from "../infra/ErrorResponse";
export function initStoreApi(_ref) {
  let {
    getState,
    getNow,
    sendRequest
  } = _ref;
  const mapResponseBody = res => res.data;
  return {
    charge: {
      approveAll(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          url: "/relationship/".concat(relationshipId, "/charge/approve"),
          urlForSentry: '/relationship/:id/charge/approve',
          data: {}
        }).then(mapResponseBody);
      },
      async approve(relationshipId, chargeId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/charge/").concat(chargeId, "/approve"),
          urlForSentry: '/relationship/:id/charge/:id/approve'
        });
      },
      delete(relationshipId, chargeId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/charge/").concat(chargeId, "/cancel"),
          urlForSentry: '/relationship/:id/charge/:id/cancel'
        });
      },
      billNow(relationshipId, chargeId, body) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/charge/").concat(chargeId, "/bill"),
          urlForSentry: '/relationship/:id/charge/:id/bill',
          data: prepareCreateChargeDto(body)
        }).then(mapResponseBody);
      },
      /** Returns the chargeId in the response. */
      create(relationshipId, body) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/charge"),
          urlForSentry: '/relationship/:id/charge',
          data: prepareCreateChargeDto(body)
        }).then(mapResponseBody);
      },
      pauseCredit(relationshipId, chargeId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/charge/").concat(chargeId, "/pause"),
          urlForSentry: '/relationship/:id/charge/:id/pause'
        });
      },
      resumeCredit(relationshipId, chargeId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/charge/").concat(chargeId, "/resume"),
          urlForSentry: '/relationship/:id/charge/:id/resume'
        });
      }
    },
    service: {
      togglePauseResumeBilling(relationshipId, serviceId, pause) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/pause"),
          urlForSentry: '/relationship/:id/service/:id/pause',
          data: {
            pause
          }
        });
      },
      billNow(relationshipId, serviceId, data) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/bill"),
          urlForSentry: '/relationship/:id/service/:id/bill',
          data
        }).then(mapResponseBody);
      },
      billLater(relationshipId, serviceId, data) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/bill-later"),
          urlForSentry: '/relationship/:id/service/:id/bill-later',
          data
        }).then(mapResponseBody);
      },
      nextBillingDate(relationshipId, serviceId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/next-billing-date"),
          urlForSentry: '/relationship/:id/service/:id/next-billing-date'
        }).then(res => timeModel.fromDatestampObject(res.data.date));
      }
    },
    billCommand: {
      get(relationshipId, serviceId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/bill-command"),
          urlForSentry: '/relationship/:id/service/:id/bill-command'
        }).then(mapResponseBody);
      },
      create(relationshipId, serviceId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/bill-command/"),
          urlForSentry: '/relationship/:id/service/:id/bill-command'
        }).then(mapResponseBody);
      },
      update(relationshipId, serviceId, billCommandId, data) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/bill-command/").concat(billCommandId),
          urlForSentry: '/relationship/:id/service/:id/bill-command/:id',
          data
        }).then(mapResponseBody);
      },
      remove(relationshipId, serviceId, billCommandId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/bill-command/").concat(billCommandId, "/cancel"),
          urlForSentry: '/relationship/:id/service/:id/bill-command/:id/cancel'
        });
      },
      withdraw(relationshipId, serviceId, billCommandId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/bill-command/").concat(billCommandId, "/withdraw"),
          urlForSentry: '/relationship/:id/service/:id/bill-command/:id/withdraw'
        }).then(mapResponseBody);
      },
      getUploadDestination(relationshipId, serviceId, billCommandId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/bill-command/").concat(billCommandId, "/upload-destination"),
          urlForSentry: '/relationship/:id/service/:id/bill-command/:id/upload-destination'
        }).then(res => {
          var _res$data$url;
          res.data.url = (_res$data$url = res.data.url) === null || _res$data$url === void 0 ? void 0 : _res$data$url.replace(/"/g, '');
          return res.data;
        });
      },
      addDeliverable(relationshipId, serviceId, billCommandId, deliverable) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/bill-command/").concat(billCommandId, "/add-deliverable"),
          urlForSentry: '/relationship/:id/service/:id/bill-command/:id/add-deliverable',
          data: deliverable
        }).then(mapResponseBody);
      },
      deleteDeliverable(relationshipId, serviceId, billCommandId, deliverableId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/bill-command/").concat(billCommandId, "/remove-deliverable/").concat(deliverableId),
          urlForSentry: '/relationship/:id/service/:id/bill-command/:id/remove-deliverable/:id'
        }).then(mapResponseBody);
      },
      getDeliverableUrl(relationshipId, serviceId, billCommandId, fileId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/bill-command/").concat(billCommandId, "/deliverables/").concat(fileId),
          urlForSentry: '/relationship/:id/service/:id/bill-command/:id/deliverables/:id'
        }).then(res => res.data.deliverable);
      },
      askForClientApproval(relationshipId, serviceId, billCommandId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/bill-command/").concat(billCommandId, "/ask-for-client-approval"),
          urlForSentry: '/relationship/:id/service/:id/bill-command/:id/ask-for-client-approval'
        }).then(mapResponseBody);
      },
      clientApprovesDelivery(relationshipId, serviceId, billCommandId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/bill-command/").concat(billCommandId, "/approve"),
          urlForSentry: '/relationship/:id/service/:id/bill-command/:id/approve'
        }).then(mapResponseBody);
      },
      clientRejectsDelivery(relationshipId, serviceId, billCommandId, data) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/bill-command/").concat(billCommandId, "/reject"),
          urlForSentry: '/relationship/:id/service/:id/bill-command/:id/reject',
          data
        }).then(mapResponseBody);
      }
    },
    relationship: {
      getServiceNames(businessId, isVendor, type) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/relationships-summary-mv/service-names"),
          urlForSentry: '/business/:id/relationships-summary-mv/service-names',
          params: {
            party: isVendor ? 'vendor' : 'customer',
            status: type === 'agreement' ? "".concat(ServiceStatusType.Approved, ",").concat(ServiceStatusType.Terminated) : ServiceStatusType.AwaitingApproval
          }
        }).then(res => res.data.map(obj => obj.serviceName));
      },
      getSummaries(config) {
        const {
          businessId,
          type,
          isVendor,
          search,
          filters,
          clientContactId
        } = config;
        const filterParams = {};
        if (filters) {
          Object.entries(filtersConfig).forEach(_ref2 => {
            let [key, config] = _ref2;
            filters[key] && Object.assign(filterParams, config.getRequestParam(filters[key]));
          });
        }
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/relationships-summary-mv"),
          urlForSentry: '/business/:id/relationships-summary-mv',
          params: _objectSpread({
            party: isVendor ? 'vendor' : 'customer',
            status: type === 'agreement' ? "".concat(ServiceStatusType.Approved, ",").concat(ServiceStatusType.Terminated) : ServiceStatusType.AwaitingApproval,
            search: search || undefined,
            clientContactId: clientContactId || undefined
          }, filterParams)
        }).then(res => (res.data || []).map(relationshipSummaryModel.fromDTO));
      },
      /** Returns null if the client didn't connect any payment-method. */
      getSelectedPaymentMethod(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/funding-source"),
          urlForSentry: '/relationship/:id/funding-source'
        }).then(res => res.data.details);
      },
      changePaymentMethod(relationshipId, fundingSourceId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/funding-source/").concat(fundingSourceId),
          urlForSentry: '/relationship/:id/funding-source/:id',
          allowedForAccountants: true
        });
      },
      createFromDraft(draftId) {
        return sendRequest({
          useMgmtHost: true,
          url: '/relationship/create-from-draft',
          data: {
            draftId
          },
          disableSentry: e => e.hasServerError(ServerError.BuyerUserAssociatedWithVendor) || e.hasPhoneValidationError()
        }).then(mapResponseBody);
      },
      get(relationshipId) {
        let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        const {
          resolvePaymentMethod
        } = options;
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId),
          urlForSentry: '/relationship/:id',
          params: {
            resolvePaymentMethod
          }
        }).then(res => relationshipModel.fromDTO(res.data));
      },
      getOriginalProposal(id) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(id, "/latest-proposal"),
          urlForSentry: '/relationship/:id/latest-proposal'
        }).then(mapResponseBody).then(relationshipModel.fromDTO);
      },
      /** Returns the new relationship version. */
      async update(relationshipId, body) {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/update"),
          urlForSentry: "/relationship/:id/update",
          data: body,
          disableSentry: err => err.hasServerError(ServerError.MismatchingRelationshipVersion)
        });
        return res.data;
      },
      /** The client approves a proposal. */
      approve(relationshipData, additionalData) {
        const {
          id,
          version
        } = relationshipData;
        const {
          fundingSourceId,
          selectedBundleId,
          selectedOptionalServices,
          requestAccountantAccessApproved,
          signature
        } = additionalData;
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(id, "/approve"),
          urlForSentry: '/relationship/:id/approve',
          data: {
            version,
            signature,
            fundingSourceId,
            selectedBundleId,
            selectedOptionalServices,
            requestAccountantAccessApproved
          },
          disableSentry: err => err.hasServerError([ServerError.MismatchingRelationshipVersion, ServerError.InvalidRelationshipStatus])
        });
      },
      terminateAgreement(relationshipId, notification) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/terminate"),
          urlForSentry: '/relationship/:id/terminate',
          data: notification ? {
            action: 'notify',
            notification
          } : {
            action: 'silent'
          }
        });
      },
      requestPaymentMethod(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/request-payment-method"),
          urlForSentry: '/relationship/:id/request-payment-method'
        });
      },
      getNextReminder(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/next-reminder"),
          urlForSentry: '/relationship/:id/next-reminder'
        });
      },
      associateCustomer(relationshipId, customerId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/associate-customer"),
          urlForSentry: '/relationship/:id/associate-customer',
          data: {
            customerId
          }
        });
      },
      stopAutoApproveAmendments(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/stop-auto-approve-amendments"),
          urlForSentry: '/relationship/:id/stop-auto-approve-amendments'
        });
      },
      getSingleUseApprovalLink(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          disableAuth: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/single-use-approval-link"),
          urlForSentry: '/relationship/:id/single-use-approval-link',
          disableSentry: err => err.hasServerError(ServerError.RelationshipAlreadyApproved)
        }).then(mapResponseBody);
      },
      getProposalLinks(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/proposal-links"),
          urlForSentry: '/relationship/:id/proposal-links',
          disableSentry: err => err.hasServerError(ServerError.RelationshipAlreadyApproved)
        }).then(mapResponseBody);
      },
      getBillingControlData(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/billing/control-data"),
          urlForSentry: '/relationship/:id/billing/control-data'
        }).then(res => billingControlDataModel.fromDTO(res.data));
      },
      resendApprovalEmail(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/resend-notification"),
          urlForSentry: '/relationship/:id/resend-notification'
        });
      },
      sendProposalApprovalReminder(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/reminder/proposal-approval"),
          urlForSentry: '/relationship/:id/reminder/proposal-approval'
        });
      },
      cancelProposal(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/cancel"),
          urlForSentry: '/relationship/:id/cancel',
          disableSentry: err => err.hasServerError(ServerError.InvalidRelationshipStatus)
        });
      },
      /** Will get a direct link to a relationship or an invoice, depending on the payload provided. */
      getDirectLink(relationshipId, data) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/direct-link"),
          urlForSentry: '/relationship/:id/direct-link',
          allowedForAccountants: true,
          data: {
            data: {
              [data.type]: data.id
            }
          }
        }).then(mapResponseBody);
      },
      approveAllInvoices(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          url: "/relationship/".concat(relationshipId, "/approve-invoices"),
          urlForSentry: '/relationship/:id/approve-invoices',
          data: {}
        });
      },
      /** Updates the progressStatus of an agreement. */
      updateProgressStatus(agreementId, progressStatus) {
        return sendRequest({
          useMgmtHost: true,
          url: "/relationship/".concat(agreementId, "/approval-progress"),
          urlForSentry: '/relationship/:id/approval-progress',
          data: {
            progressStatus
          }
        });
      },
      approveAllAmendments(relationshipId, relationshipVersion) {
        return sendRequest({
          useMgmtHost: true,
          url: "/relationship/".concat(relationshipId, "/approve-amendments"),
          urlForSentry: '/relationship/:id/approve-amendments',
          data: {
            version: relationshipVersion
          },
          disableSentry: err => err.hasServerError([ServerError.MismatchingRelationshipVersion, ServerError.InvalidRelationshipStatus])
        }).then(mapResponseBody);
      }
    },
    draft: {
      getSummaries() {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/relationships-drafts'
        }).then(mapResponseBody);
      },
      getById(draftId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationships-drafts/".concat(draftId),
          urlForSentry: '/relationships-drafts/:id'
        }).then(res => draftModel.fromDTO(res.data));
      },
      save(data) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/relationships-drafts/',
          data
        }).then(mapResponseBody);
      },
      deleteById(draftId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationships-drafts/".concat(draftId, "/delete"),
          urlForSentry: '/relationships-drafts/:id/delete'
        });
      },
      deleteAll() {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/relationships-drafts/delete'
        });
      }
    },
    contact: {
      getAll() {
        return sendRequest({
          method: 'get',
          useMgmtHost: true,
          url: '/clients'
        }).then(mapResponseBody).then(contacts => contacts.map(contactModel.fromDTO));
      },
      getById(contactId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/clients/".concat(contactId),
          urlForSentry: '/clients/:id'
        }).then(mapResponseBody).then(contactModel.fromDTO);
      },
      create(newContact) {
        const contactDTO = contactModel.toNewDTO(newContact);
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/clients',
          data: contactDTO,
          disableSentry: e => e.hasPhoneValidationError() || e.hasServerError([ServerError.DuplicateContact, ServerError.UserWithEmailAlreadyExists])
        }).then(res => contactModel.fromDTO(res.data));
      },
      async update(contact) {
        const fieldsToUpdate = ['firstName', 'lastName', 'companyName', 'email', 'phone', 'version'];
        const contactDTO = contactModel.toDTO(contact);
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/clients/".concat(contact.id, "/update"),
          urlForSentry: '/clients/:id/update',
          data: pick(contactDTO, fieldsToUpdate),
          disableSentry: e => e.hasPhoneValidationError() || e.hasServerError([ServerError.DuplicateContact, ServerError.ContactVersionMismatch, ServerError.ContactNotEditable, ServerError.UserWithEmailAlreadyExists])
        });
        return contactModel.fromDTO(res.data);
      },
      async qboLink(contactId, data) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/clients/".concat(contactId, "/link"),
          urlForSentry: '/clients/:id/link',
          data
        });
      },
      async archive(contactId, data) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/clients/".concat(contactId, "/archive"),
          urlForSentry: '/clients/:id/archive',
          data,
          disableSentry: err => err.hasServerError([ServerError.FailedToArchiveContactDueToExistingRelationships, ServerError.ContactVersionMismatch])
        });
      },
      async restore(contactId, data) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/clients/".concat(contactId, "/restore"),
          urlForSentry: '/clients/:id/restore',
          data
        });
      },
      qboCreate(contactId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/clients/".concat(contactId, "/qbo-create"),
          urlForSentry: '/clients/:id/qbo-create',
          disableSentry: err => err.hasServerError(ServerError.DuplicateNameExists)
        }).then(mapResponseBody);
      }
    },
    auth: {
      loginEmailStep(email, pageUrl) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/login/flow/".concat(email),
          urlForSentry: '/login/flow/:email',
          disableAuth: true,
          config: {
            headers: {
              'Anchor-Referrer': pageUrl
            }
          }
        }).then(mapResponseBody);
      },
      loginWithPassword(data) {
        const {
          email,
          password,
          utms,
          anchorReferrer
        } = data;
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/login',
          data: {
            email,
            password,
            utms
          },
          disableSentry: [400],
          config: {
            headers: {
              'Anchor-Referrer': anchorReferrer
            }
          }
        }).then(mapResponseBody);
      },
      async intuitSignIn(_ref3) {
        let {
          redirectUrl,
          authDomain,
          utms,
          ensureEmail
        } = _ref3;
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/qbo-integration/sign-in',
          data: ensureEmail ? {
            redirectUrl,
            authDomain,
            utms,
            ensureEmail
          } : {
            redirectUrl,
            authDomain,
            utms
          }
        });
        return res.data;
      },
      async signup(_ref4) {
        let {
          fullName,
          email,
          password,
          utms,
          pageUrl
        } = _ref4;
        const res = await sendRequest({
          useMgmtHost: true,
          url: '/signup',
          data: {
            fullName,
            email,
            password,
            utms
          },
          disableSentry: [400, 409],
          config: {
            headers: {
              'Anchor-Referrer': pageUrl
            }
          }
        });
        return res.data;
      },
      approveTos(userId) {
        return sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: "/user/".concat(userId, "/approve-tos"),
          urlForSentry: '/user/:id/approve-tos'
        });
      },
      setPassword(password) {
        return sendRequest({
          method: 'post',
          url: '/api/password/',
          data: {
            password
          },
          forceAuthorizationHeader: true
        });
      },
      getUserInfo() {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/user/me?expand=worksAt",
          disableSentry: [401]
        }).then(mapResponseBody).catch(err => {
          var _err$response;
          if (((_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.status) === 401) {
            // This will happen when the user is removed from his team by another team-member.
            // Should trigger an automatic logout.
            return null;
          }
          throw err;
        });
      },
      getUserVideos() {
        return sendRequest({
          method: 'get',
          url: "/users/videos",
          apiHost: APIHost.Scout
        }).then(mapResponseBody);
      },
      editUserDetails(userDetails) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/user/me',
          allowedForAccountants: true,
          data: userDetails,
          disableSentry: [400]
        }).then(mapResponseBody);
      },
      googleSignIn(_ref5) {
        let {
          pageUrl,
          idToken,
          utms,
          ensureEmail
        } = _ref5;
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/oauth',
          data: {
            provider: 'google',
            id_token: idToken,
            utms,
            ensureEmail
          },
          config: {
            headers: {
              'Anchor-Referrer': pageUrl
            }
          },
          disableSentry: [403] //an invalid email
        }).then(res => res.data.token);
      },
      resetPassword(email) {
        return sendRequest({
          method: 'post',
          url: '/api/forgot-password/reset',
          data: {
            email
          },
          disableAuth: false,
          forceAuthorizationHeader: true
        });
      },
      resendEmailVerification(email) {
        return sendRequest({
          method: 'post',
          url: '/__/resend-email-verification',
          data: {
            recipient: email
          },
          disableAuth: true
        });
      },
      requestNewOTP(email) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/login/otp/send',
          data: {
            email
          }
        });
      },
      validateOTP(email, otp, utms) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/login/otp',
          data: {
            email,
            otp,
            utms
          },
          disableSentry: [400]
        }).then(res => res.data.authToken);
      }
    },
    video: {
      markVideoAsWatched(id) {
        return sendRequest({
          method: 'post',
          url: "/users/videos/".concat(id, "/watched"),
          apiHost: APIHost.Scout
        }).then(mapResponseBody);
      }
    },
    user: {
      switchBusiness(businessId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/user/me/switch-workspace',
          allowedForAccountants: true,
          data: {
            businessId
          }
        }).then(res => res.data.authToken);
      },
      createNewClientBusiness(businessName) {
        return sendRequest({
          useMgmtHost: true,
          url: '/business/customer',
          data: {
            name: businessName,
            accountsPayable: true,
            accountsReceivable: false
          }
        }).then(mapResponseBody);
      },
      getUserClientData(userId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/user/".concat(userId, "/client-data"),
          urlForSentry: '/user/:id/client-data'
        }).then(mapResponseBody);
      },
      setUserClientData(userId, data) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/user/".concat(userId, "/client-data"),
          urlForSentry: '/user/:id/client-data',
          data
        });
      },
      getBusinessClientData(businessId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/user/".concat(businessId, "/client-data"),
          urlForSentry: '/user/:id/client-data'
        }).then(mapResponseBody);
      },
      setBusinessClientData(businessId, data) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/user/".concat(businessId, "/client-data"),
          urlForSentry: '/user/:id/client-data',
          data
        });
      },
      sendVendorOnboardingLink(email) {
        return sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: '/user/send-vendor-onboarding-link',
          data: {
            email
          },
          disableAuth: true
        });
      },
      referral() {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/user/referral',
          urlForSentry: '/user/referral'
        }).then(mapResponseBody).then(referralModel.fromDTO);
      },
      updateBranding(businessId, branding) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/branding"),
          urlForSentry: '/business/:id/branding',
          data: branding
        }).then(mapResponseBody);
      }
    },
    vendor: {
      async create(data) {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/business/vendor',
          data,
          disableSentry: err => err.isStripeError()
        });
        return res.data;
      },
      async update(data) {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/business/vendor/update',
          data,
          disableSentry: err => err.isStripeError()
        });
        return res.data;
      },
      authorizeVendorBankAccount(_ref6) {
        let {
          routingNumber,
          accountNumber
        } = _ref6;
        return sendRequest({
          apiHost: APIHost.Plaid,
          method: 'post',
          url: '/direct/authorize-account',
          urlForSentry: '/direct/authorize-account',
          data: {
            routingNumber,
            accountNumber
          }
        });
      },
      updateBusinessDetails(businessId, data) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/details"),
          urlForSentry: '/business/:id/details',
          data
        });
      },
      updateBusinessSettings(businessId, data) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/settings"),
          urlForSentry: '/business/:id/settings',
          data
        });
      },
      getBusinessSettings(businessId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/settings"),
          urlForSentry: '/business/:id/settings'
        }).then(mapResponseBody);
      },
      getHostedKYBUrl(businessId) {
        return sendRequest({
          apiHost: APIHost.KYB,
          method: 'get',
          url: "/business/".concat(businessId, "/stripe-hosted-kyb"),
          urlForSentry: '/business/:id/stripe-hosted-kyb'
        });
      },
      getKYBInquiry(businessId) {
        return sendRequest({
          apiHost: APIHost.KYB,
          method: 'get',
          url: "/business/".concat(businessId, "/inquiry"),
          urlForSentry: '/business/:id/inquiry'
        }).then(mapResponseBody);
      },
      async getKYBStatus(businessId) {
        const res = await sendRequest({
          apiHost: APIHost.KYB,
          method: 'get',
          url: "/business/".concat(businessId, "/kyb-status"),
          urlForSentry: '/business/:id/kyb-status'
        });
        return res.data.status;
      }
    },
    zapier: {
      getApiKey() {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/integrations/zapier/api-key',
          urlForSentry: '/integrations/zapier/api-key',
          allowedForAccountants: true
        });
      }
    },
    member: {
      getAll(businessId) {
        const bid = businessId || selectors.user.businessId(getState());
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(bid, "/member"),
          urlForSentry: '/business/:id/member'
        }).then(mapResponseBody);
      },
      async invite(data) {
        const businessId = selectors.user.businessId(getState());
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/member/invite"),
          urlForSentry: '/business/:id/member/invite',
          data,
          disableSentry: err => err.hasServerError([ServerError.UserAlreadyInBusiness, ServerError.UserAlreadyAffiliatedWithBusiness, ServerError.PendingInvitationAlreadyExists])
        });
      },
      resendInvitation(businessId, invitedUserId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/member/resend-invitation"),
          urlForSentry: '/business/:id/member/resend-invitation',
          data: {
            invitedUserId
          }
        });
      },
      changeRole(data) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/member/change-role",
          data
        });
      },
      remove(data) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/business/member/deactivate',
          data
        });
      },
      getAssignedRelationships(memberId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/count/user/".concat(memberId)
        });
      },
      reassignAllRelationships(data) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/relationship/reassign/all',
          data
        });
      }
    },
    activityLog: {
      get(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/activity-log"),
          urlForSentry: '/relationship/:id/activity-log'
        }).then(mapResponseBody).then(activityLogModel.fromDTO);
      }
    },
    invoice: {
      getInvoicesCSV(params) {
        return sendRequest({
          useMgmtHost: true,
          url: "/invoices/csv-download",
          method: 'get',
          params,
          config: {
            responseType: 'blob'
          }
        }).then(mapResponseBody);
      },
      getByRelationship(relationshipId, isCustomer) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/invoices"),
          params: {
            isCustomer
          },
          urlForSentry: '/relationship/:id/invoices'
        }).then(res => {
          const now = getNow();
          return res.data.map(inv => invoiceModel.fromDTO(inv, now));
        });
      },
      void(invoiceId) {
        return sendRequest({
          useMgmtHost: true,
          url: "/invoice/".concat(invoiceId, "/void"),
          urlForSentry: '/invoice/:id/void'
        });
      },
      integrationDetails(invoiceId, isZeroAmount) {
        return sendRequest({
          method: 'get',
          useMgmtHost: true,
          url: "/invoice/".concat(invoiceId, "/integrated-invoice"),
          urlForSentry: '/invoice/:id/integrated-invoice'
        }).then(res => integratedInvoiceModel.fromDTO(res.data, isZeroAmount));
      },
      getByID(invoiceId, isCustomer) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/invoice/".concat(invoiceId),
          urlForSentry: '/invoice/:id',
          params: {
            isCustomer
          }
        }).then(res => {
          const now = getNow();
          return invoiceModel.fromDTO(res.data, now);
        });
      },
      getByIDScout(invoiceId, isCustomer) {
        return sendRequest({
          apiHost: APIHost.Scout,
          method: 'get',
          url: "/invoice/".concat(invoiceId),
          urlForSentry: '/invoice/:id',
          params: {
            view: isCustomer ? 'customer' : 'vendor'
          }
        }).then(res => {
          return invoiceModel.fromScoutDTO(!isCustomer, res.data);
        });
      },
      downloadPDF(invoiceId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/invoice/".concat(invoiceId, "/pdf"),
          urlForSentry: '/invoice/:id/pdf'
        }).then(res => res.data.url);
      },
      resend(invoiceId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/invoice/".concat(invoiceId, "/resend"),
          urlForSentry: '/invoice/:id/resend'
        });
      },
      retryCharge(invoiceId) {
        return sendRequest({
          useMgmtHost: true,
          url: "/invoice/".concat(invoiceId, "/retry-payment"),
          urlForSentry: '/invoice/:id/retry-payment'
        });
      },
      async markAsPaidExternally(invoiceId) {
        await sendRequest({
          useMgmtHost: true,
          url: "/invoice/".concat(invoiceId, "/paid-externally"),
          urlForSentry: '/invoice/:id/paid-externally',
          disableSentry: err => err.hasServerError(ServerError.IncorrectStatusForPaidExternally)
        });
      },
      approve(invoiceId) {
        const isCustomer = selectors.user.isClient(getState());
        if (!isCustomer) throw new Error('only clients can approve invoice');
        return sendRequest({
          useMgmtHost: true,
          url: "/invoice/".concat(invoiceId, "/approve"),
          urlForSentry: '/invoice/:id/approve',
          params: {
            isCustomer
          }
        }).then(res => invoiceModel.fromDTO(res.data, getNow()));
      },
      getSummaries(relationshipId) {
        const isVendor = selectors.user.isVendor(getState());
        const kycCompleted = selectors.user.kycCompleted(getState());
        const hasLinkedBankAccount = selectors.user.hasLinkedBankAccount(getState());
        const disablePayments = selectors.user.hasDisabledPayments(getState());
        return sendRequest({
          method: 'get',
          useMgmtHost: true,
          url: '/invoices',
          params: {
            isCustomer: !isVendor,
            relationshipId
          }
        }).then(res => {
          const now = getNow();
          return res.data.map(inv => invoiceSummaryModel.fromDTO(inv, now, isVendor, hasLinkedBankAccount, kycCompleted, disablePayments));
        });
      },
      getSummariesScout(relationship) {
        const isVendor = selectors.user.isVendor(getState());
        return sendRequest({
          method: 'get',
          apiHost: APIHost.Scout,
          url: '/invoices-summary',
          params: {
            view: isVendor ? 'vendor' : 'customer',
            relationship
          }
        }).then(res => {
          return res.data.map(inv => invoiceSummaryModel.fromScoutDTO(isVendor, inv));
        });
      },
      getCounters(relationshipId) {
        const isVendor = selectors.user.isVendor(getState());
        return sendRequest({
          method: 'get',
          useMgmtHost: true,
          url: '/invoices/aggregated-by-status',
          params: {
            isCustomer: !isVendor,
            relationshipId
          }
        }).then(mapResponseBody);
      },
      stopPayment(invoiceId, reason) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/invoice/".concat(invoiceId, "/stop-payment"),
          data: {
            reason
          }
        });
      },
      resumePayment(invoiceId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/invoice/".concat(invoiceId, "/resume-payment")
        });
      },
      refundPayment(invoiceId, reason) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/invoice/".concat(invoiceId, "/refund-payment"),
          data: {
            reason
          }
        });
      },
      payNow(invoiceId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/invoice/".concat(invoiceId, "/pay-now")
        });
      },
      disableAutoApprove(invoiceID) {
        return sendRequest({
          useMgmtHost: true,
          urlForSentry: '/invoice/:id/auto-approve/disable',
          method: 'post',
          url: "/invoice/".concat(invoiceID, "/auto-approve/disable")
        }).then(mapResponseBody);
      }
    },
    serviceTemplate: {
      getAll(businessId, params) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/service-template"),
          urlForSentry: "/business/:id/service-template",
          params
        }).then(res => {
          const {
            services
          } = res.data;
          const raw = services.map(serviceTemplateModel.fromDTO);
          const active = [];
          const deleted = [];
          raw.forEach(s => {
            if (serviceTemplateModel.isDeleted(s)) {
              deleted.push(s);
            } else {
              active.push(s);
            }
          });
          return {
            active,
            deleted,
            raw
          };
        });
      },
      getById(businessId, serviceTemplateId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/service-template/").concat(serviceTemplateId),
          urlForSentry: '/business/:id/service-template/:serviceId'
        }).then(res => serviceTemplateModel.fromDTO(res.data));
      },
      create(serviceTemplate, businessId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/service-template/create"),
          urlForSentry: "/business/:id/service-template/create",
          data: serviceTemplateModel.toDTO(serviceTemplate),
          disableSentry: err => err.hasServerError(ServerError.ServiceTemplateAlreadyExists)
        }).then(mapResponseBody);
      },
      update(serviceTemplate, businessId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/service-template/update"),
          urlForSentry: "/business/:id/service-template/update",
          data: serviceTemplateModel.toDTO(serviceTemplate),
          disableSentry: err => err.hasServerError(ServerError.ServiceTemplateAlreadyExists)
        }).then(mapResponseBody);
      },
      delete(id, businessId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/service-template/delete"),
          urlForSentry: "/business/:id/service-template/delete",
          data: {
            id
          }
        });
      }
    },
    integration: {
      getAll() {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/integrations',
          disableSentry: [403]
        }).then(res => res.data.integrations);
      },
      create(source, redirectUrl) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/integrations',
          data: {
            source,
            redirectUrl
          }
        }).then(mapResponseBody);
      },
      disconnect() {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/integrations/disconnect'
        }).then(mapResponseBody);
      },
      syncData() {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/integrations/sync-data'
        }).then(mapResponseBody);
      },
      async updateSettings(settings) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/integrations/update",
          urlForSentry: '/integrations/:id/update',
          data: settings
        });
      }
    },
    qbo: {
      getAccounts() {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/integrations/accounts'
        }).then(mapResponseBody);
      },
      getCustomers() {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/integrations/customers"
        }).then(mapResponseBody).then(_ref7 => {
          let {
            customers
          } = _ref7;
          return customers;
        }).then(customers => customers.map(qboCustomerModel.fromDTO));
      },
      getItems() {
        return sendRequest({
          method: 'get',
          useMgmtHost: true,
          url: "/integrations/items",
          urlForSentry: '/integrations/items'
        }).then(mapResponseBody);
      },
      async linkAccount(data) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/integrations/accounts/link',
          data
        });
      },
      linkServiceTemplate(businessId, serviceTemplateId, integratedId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/service-template/link"),
          urlForSentry: '/business/:id/service-template/link',
          data: {
            integratedId,
            id: serviceTemplateId
          }
        });
      },
      async syncInvoice(invoiceId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/integrations/invoices/".concat(invoiceId),
          urlForSentry: '/integrations/invoices/:id'
        });
      },
      async retrySyncInvoice(invoiceId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/integrations/invoices/".concat(invoiceId, "/retry"),
          urlForSentry: '/integrations/invoices/:id/retry'
        });
      },
      syncPayout(payoutId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/integrations/transfers/".concat(payoutId),
          urlForSentry: '/integrations/transfers/:id'
        });
      },
      async getClasses() {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/integrations/classes'
        });
        return res.data.classes;
      },
      async amendClasses(relationshipId, changes) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/integrations/relationships/".concat(relationshipId, "/link"),
          data: {
            changes
          }
        });
      }
    },
    serviceInvoices: {
      getByBusinessId(businessId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/serviceBilling/".concat(businessId),
          urlForSentry: '/serviceBilling/:businessId'
        }).then(res => res.data.Entries || []);
      },
      getCredit() {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/business/payments-credit'
        }).then(res => utils.centsToDollars(res.data.amount));
      },
      getDownloadURL(filename) {
        const businessId = selectors.user.businessId(getState());
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/file/").concat(filename),
          urlForSentry: '/business/:id/file/:id'
        }).then(mapResponseBody);
      }
    },
    paymentMethod: {
      /**
       * Fetches the payment methods for a given agreement. Intended to be used by clients only.
       * When the vendor sends the request he will get an empty array.
       */
      getByVendor(vendorId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/payment-method/vendor/".concat(vendorId),
          urlForSentry: '/payment-method/vendor/:id'
        }).then(res => res.data.paymentMethods);
      },
      /** Used by clients to connect a payment-method to a relationship. */
      addToBusiness(paymentMethodId, vendorId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/payment-method/".concat(paymentMethodId, "/vendor/").concat(vendorId),
          urlForSentry: '/payment-method/:id/vendor/:id',
          allowedForAccountants: true
        }).then(res => res.data.details);
      },
      delete(fundingSourceId, vendorId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/payment-method/funding-source/".concat(fundingSourceId, "/delete"),
          urlForSentry: '/payment-method/funding-source/:id/delete',
          allowedForAccountants: true,
          data: {
            vendorId
          }
        });
      },
      createSetupIntent(vendorId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/payment-method/".concat(vendorId, "/create-payment-intent"),
          urlForSentry: '/payment-method/:vendorId/create-payment-intent',
          allowedForAccountants: true
        }).then(mapResponseBody);
      }
    },
    business: {
      getLegalTerms() {
        const businessId = selectors.user.businessId(getState());
        if (!businessId) throw new Error('no business ID');
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/legal-terms"),
          urlForSentry: '/business/:id/legal-terms'
        }).then(mapResponseBody);
      },
      removeLegalTerms(legalTermsId) {
        const businessId = selectors.user.businessId(getState());
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/legal-terms/delete"),
          urlForSentry: '/business/:id/legal-terms/delete',
          data: {
            id: legalTermsId
          }
        });
      },
      createLegalTerms(data) {
        const businessId = selectors.user.businessId(getState());
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/legal-terms/create"),
          urlForSentry: '/business/:id/legal-terms/create',
          data
        }).then(mapResponseBody);
      },
      getLegalTermsUploadURL() {
        const businessId = selectors.user.businessId(getState());
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/legal-terms/upload-url"),
          urlForSentry: '/business/:id/legal-terms/upload-url'
        }).then(mapResponseBody);
      },
      getLegalTermsDownloadURL(filename, businessId) {
        const actualBusinessId = businessId || selectors.user.businessId(getState());
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(actualBusinessId, "/legal-terms/download-url"),
          urlForSentry: '/business/:id/legal-terms/download-url',
          params: {
            filename
          }
        }).then(res => res.data.url);
      },
      getLogoUploadURL() {
        const businessId = selectors.user.businessId(getState());
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/logo/upload-url"),
          urlForSentry: '/business/:id/logo/upload-url'
        }).then(res => res.data.url);
      }
    },
    invoiceDraft: {
      getDates(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/billing/dates"),
          urlForSentry: '/relationship/:id/billing/dates'
        }).then(res => res.data.dates.map(timeModel.fromDatestampObject));
      },
      getByDate(relationshipId, date) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/billing/invoice-draft"),
          urlForSentry: '/relationship/:id/billing/invoice-draft',
          params: {
            issueDate: timeModel.toDatestampArray(date).join(',')
          }
        }).then(res => res.data && invoiceDraftModel.fromDTO(res.data));
      },
      getForAmendedService(relationshipId, service) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/amended-item-draft"),
          urlForSentry: '/relationship/:id/amended-item-draft',
          data: serviceModel.toDTO(service)
        }).then(res => ({
          issueDate: fromDatestampObject(res.data.issueDate),
          lineItem: invoiceDraftModel.lineItemFromDTO(res.data.lineItem)
        }));
      }
    },
    notification: {
      async getWhileLoggedIn(notificationId) {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/notification/auth/".concat(notificationId),
          urlForSentry: '/notification/auth/:id',
          disableSentry: err => err.status === 401 || err.hasServerError([ServerError.RecipientNotFound, ServerError.InvalidTeamInvitation])
        });
        return res.data;
      },
      async getWhileLoggedOut(notificationId) {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/notification/no-auth/".concat(notificationId),
          urlForSentry: '/notification/no-auth/:id',
          disableSentry: [401]
        });
        return res.data;
      },
      async getNotificationSettings(businessId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/settings/notifications"),
          urlForSentry: "/business/:businessId/settings/notifications"
        }).then(mapResponseBody);
      },
      async updateNotificationSettings(_ref8) {
        let {
          data,
          businessId,
          notificationName
        } = _ref8;
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/settings/notifications/").concat(notificationName),
          urlForSentry: "/business/:businessId/settings/notifications/:notificationType",
          data
        });
      }
    },
    inAppNotifications: {
      getAll() {
        return sendRequest({
          method: 'get',
          useMgmtHost: true,
          url: '/business/notifications-mailbox'
        }).then(mapResponseBody);
      },
      markAsSeen(notificationItemIds) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/notifications-mailbox/mark-as-seen",
          data: {
            ids: notificationItemIds
          }
        });
      },
      markAsRead(notificationItemId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/notifications-mailbox/mark-as-read",
          data: {
            id: notificationItemId
          }
        });
      }
    },
    accountants: {
      /** Request access as an accountant to the customer's business. */
      requestAccess(customerId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/accountants/request-access/".concat(customerId),
          urlForSentry: '/business/accountants/request-access/:id'
        });
      },
      /** Cancels an active request for access. */
      async cancelRequestAccess(customerId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/accountants/cancel-request-access/".concat(customerId),
          urlForSentry: '/business/accountants/cancel-request-access/:id'
        });
      },
      /** Removes access for the existing user as an accountant to the customer's business. */
      removeMe(customerId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/accountants/remove-me/".concat(customerId),
          urlForSentry: '/business/accountants/remove-me/:id'
        });
      },
      removeVendor(vendorId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/accountants/remove/".concat(vendorId),
          urlForSentry: '/business/accountants/remove/:id'
        });
      },
      approveVendor(vendorId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/accountants/approve/".concat(vendorId),
          urlForSentry: '/business/accountants/approve/:id'
        });
      },
      rejectVendor(vendorId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/accountants/reject/".concat(vendorId),
          urlForSentry: '/business/accountants/reject/:id'
        });
      }
    },
    payout: {
      getById(payoutId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/v2/business/payouts/".concat(payoutId),
          urlForSentry: '/v2/business/payouts/:id'
        }).then(mapResponseBody).then(payout => payoutModel.fromDTO(payout));
      },
      async getAllOLD() {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/v2/business/payouts',
          urlForSentry: '/v2/business/payouts'
        });
        return {
          invoiceHeaders: ['Invoice', 'Type', 'Client name', 'Issue date', 'Due date', 'Total', 'Fees', 'Amount'],
          payouts: res.data.map(payout => payoutModel.fromDTO(payout))
        };
      },
      async getAll() {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/business/payouts',
          urlForSentry: '/business/payouts'
        });
        return {
          invoiceHeaders: res.data.InvoiceHeaders,
          payouts: res.data.Payouts.map(payout => payoutModel.fromDTO(payout))
        };
      }
    },
    report: {
      getMonthlyEarnings() {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/business/report/monthly-earnings'
        }).then(mapResponseBody);
      },
      getSidebarCounters(businessId, isVendor) {
        return sendRequest({
          method: 'get',
          useMgmtHost: true,
          url: "/business/".concat(businessId, "/counters"),
          urlForSentry: '/business/:id/counters',
          params: {
            isCustomer: !isVendor
          }
        }).then(res => {
          const {
            Invoices
          } = res.data;
          const counters = {
            agreements: 0,
            drafts: 0,
            invoices: Invoices,
            contacts: 0
          };
          return counters;
        });
      }
    },
    featureFlag: {
      getAll(businessId, authToken) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/feature-flags',
          params: {
            businessId
          },
          authToken
        }).then(mapResponseBody);
      }
    },
    plaid: {
      getLinkToken(mode) {
        let {
          fundingSourceId,
          relationshipId
        } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        return sendRequest({
          apiHost: APIHost.Plaid,
          method: 'post',
          url: '/link-token',
          allowedForAccountants: true,
          data: {
            mode,
            fundingSourceId: fundingSourceId || null,
            relationshipId
          }
        }).then(response => response.data.token);
      },
      exchangePublicToken(body) {
        return sendRequest({
          apiHost: APIHost.Plaid,
          method: 'post',
          url: '/exchange-public-token',
          allowedForAccountants: true,
          data: body,
          disableSentry: err => err.isPlaidError()
        }).then(mapResponseBody);
      },
      verifyMicroDeposit(fundingSourceId) {
        return sendRequest({
          apiHost: APIHost.Plaid,
          method: 'post',
          url: "/verify/".concat(fundingSourceId),
          urlForSentry: '/verify/:fundingSourceId',
          allowedForAccountants: true
        });
      },
      failedVerification(fundingSourceId) {
        return sendRequest({
          apiHost: APIHost.Plaid,
          method: 'post',
          url: "/failed-verification/".concat(fundingSourceId),
          urlForSentry: '/verify-failed/:fundingSourceId',
          allowedForAccountants: true
        });
      },
      isLoginRequired(fundingSourceId) {
        return sendRequest({
          apiHost: APIHost.Plaid,
          method: 'post',
          url: "/funding-source/".concat(fundingSourceId, "/is-login-required"),
          urlForSentry: '/funging-source/:fundingSourceId/is-login-required',
          allowedForAccountants: true
        }).then(res => res.data.isLoginRequired);
      },
      loginRepaired(fundingSourceId) {
        return sendRequest({
          apiHost: APIHost.Plaid,
          method: 'post',
          url: "/funding-source/".concat(fundingSourceId, "/login-repaired"),
          urlForSentry: '/funging-source/:fundingSourceId/login-repaired',
          allowedForAccountants: true
        });
      }
    },
    proposalTemplates: {
      async getAll() {
        if (!selectors.user.hasBusiness(getState())) {
          // Server response is 403 if user has no business.
          return [];
        }
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/proposal-templates",
          urlForSentry: '/proposal-templates'
        }).then(mapResponseBody).then(templates => templates.map(proposalTemplatesSummaryModel.fromDTO));
      },
      async get(id) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/proposal-templates/".concat(id),
          urlForSentry: '/proposal-templates/:id'
        }).then(mapResponseBody).then(proposalTemplateModel.fromDTO);
      },
      async save(id, template) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/proposal-templates/".concat(id),
          urlForSentry: '/proposal-templates/:id',
          data: template
        }).then(mapResponseBody);
      },
      async rename(id, title) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/proposal-templates/".concat(id, "/rename"),
          urlForSentry: '/proposal-templates/:id/rename',
          data: {
            title
          }
        }).then(mapResponseBody);
      },
      async remove(id) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/proposal-templates/".concat(id, "/delete"),
          urlForSentry: '/proposal-templates/:id/delete'
        }).then(mapResponseBody);
      },
      async create(template) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/proposal-templates/create",
          urlForSentry: '/proposal-templates/create',
          data: template
        }).then(mapResponseBody);
      },
      async createDraft(id) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/proposal-templates/".concat(id, "/create-relationship-draft"),
          urlForSentry: '/proposal-templates/:id/create-relationship-draft'
        }).then(mapResponseBody);
      },
      async createFromDraft(id, title) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/proposal-templates/create-from-draft/".concat(id),
          urlForSentry: '/proposal-templates/create-from-draft/:id',
          data: {
            title
          }
        }).then(mapResponseBody);
      }
    },
    reports: {
      async revenueReportServices(_ref9) {
        let {
          businessId
        } = _ref9;
        return sendRequest({
          method: 'get',
          url: "/businesses/".concat(businessId, "/revenue-report/services"),
          urlForSentry: '/businesses/:businessId/revenue-report/services',
          apiHost: APIHost.Scout
        }).then(mapResponseBody).catch(() => []);
      },
      async revenueReportCustomers(_ref10) {
        let {
          businessId
        } = _ref10;
        return sendRequest({
          method: 'get',
          url: "/businesses/".concat(businessId, "/revenue-report/customers"),
          urlForSentry: '/businesses/:businessId/revenue-report/customers',
          apiHost: APIHost.Scout
        }).then(mapResponseBody).catch(() => []);
      },
      async revenueReport(_ref11) {
        let {
          businessId,
          start,
          end,
          breakdowns,
          aggregate,
          services,
          customers
        } = _ref11;
        const params = {
          start,
          end,
          breakdowns,
          aggregate
        };
        const queryParams = Object.entries(params).filter(_ref12 => {
          let [, value] = _ref12;
          return value !== undefined;
        }).map(_ref13 => {
          let [key, value] = _ref13;
          return "".concat(key, "=").concat(value);
        }).join('&');
        return sendRequest({
          method: 'post',
          url: "/businesses/".concat(businessId, "/revenue-report").concat(queryParams !== '' ? "?".concat(queryParams) : ''),
          urlForSentry: '/businesses/:businessId/revenue-report',
          apiHost: APIHost.Scout,
          data: {
            services: services.length > 0 ? services : undefined,
            customers: customers.length > 0 ? customers : undefined
          }
        }).then(mapResponseBody).catch(() => []);
      },
      async activeServicesReportServices(_ref14) {
        let {
          businessId
        } = _ref14;
        return sendRequest({
          method: 'get',
          url: "/businesses/".concat(businessId, "/active-services-report/services"),
          urlForSentry: '/businesses/:businessId/active-services-report/services',
          apiHost: APIHost.Scout
        }).then(mapResponseBody).catch(() => []);
      },
      async activeServicesReportCustomers(_ref15) {
        let {
          businessId
        } = _ref15;
        return sendRequest({
          method: 'get',
          url: "/businesses/".concat(businessId, "/active-services-report/customers"),
          urlForSentry: '/businesses/:businessId/active-services-report/customers',
          apiHost: APIHost.Scout
        }).then(mapResponseBody).catch(() => []);
      },
      async activeServicesReportAgreements(_ref16) {
        let {
          businessId
        } = _ref16;
        return sendRequest({
          method: 'get',
          url: "/businesses/".concat(businessId, "/active-services-report/agreements"),
          urlForSentry: '/businesses/:businessId/active-services-report/agreements',
          apiHost: APIHost.Scout
        }).then(mapResponseBody).catch(() => []);
      },
      async activeServicesReportAssignees(_ref17) {
        let {
          businessId
        } = _ref17;
        return sendRequest({
          method: 'get',
          url: "/businesses/".concat(businessId, "/active-services-report/assignees"),
          urlForSentry: '/businesses/:businessId/active-services-report/assignees',
          apiHost: APIHost.Scout
        }).then(mapResponseBody).catch(() => []);
      },
      async activeServicesReport(_ref18) {
        let {
          businessId,
          params,
          services,
          customers,
          agreements,
          assignees
        } = _ref18;
        const queryParams = Object.entries(params).filter(_ref19 => {
          let [, value] = _ref19;
          return value !== undefined;
        }).map(_ref20 => {
          let [key, value] = _ref20;
          return Array.isArray(value) ? "".concat(key, "=").concat(value.join(',')) : "".concat(key, "=").concat(value);
        }).join('&');
        return sendRequest({
          method: 'post',
          url: "/business/".concat(businessId, "/active-services-report").concat(queryParams !== '' ? "?".concat(queryParams) : ''),
          urlForSentry: '/businesses/:businessId/active-services-report',
          apiHost: APIHost.Scout,
          data: {
            services: services.length > 0 ? services : undefined,
            customers: customers.length > 0 ? customers : undefined,
            agreements: agreements.length > 0 ? customers : undefined,
            assignees: assignees.length > 0 ? customers : undefined
          }
        }).then(mapResponseBody).catch(() => []);
      }
    }
  };
}