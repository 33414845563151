import { integrationModel } from "../../models";
import { userSelectors } from "./userSelectors";
const integrations = s => s.integrations.data;
const qboIntegration = s => integrationModel.qboIntegration(integrations(s));
const isLinkedToQbo = s => {
  const qbo = qboIntegration(s);
  return !!qbo && integrationModel.isLinked(qbo);
};
const showQboIntegration = s => isLinkedToQbo(s) && userSelectors.isVendor(s);
const allowToCustomizeQboClasses = s => {
  const qbo = qboIntegration(s);
  return !!qbo && integrationModel.isLinked(qbo) && integrationModel.classTrackingAgreementOverride(qbo);
};
const isPendingQboIntegrationSetup = s => {
  var _qboIntegration$accou, _qboIntegration;
  const accountsList = Object.values((_qboIntegration$accou = (_qboIntegration = qboIntegration(s)) === null || _qboIntegration === void 0 ? void 0 : _qboIntegration.accounts) !== null && _qboIntegration$accou !== void 0 ? _qboIntegration$accou : {});
  const isValidAccountsLength = accountsList.length === 3;
  const areAllAccountsSynced = accountsList.every(account => Boolean(account.synced));
  return !isValidAccountsLength || !areAllAccountsSynced;
};
const qboCustomTxnNumbers = s => {
  var _qboIntegration2;
  return (_qboIntegration2 = qboIntegration(s)) === null || _qboIntegration2 === void 0 ? void 0 : _qboIntegration2.qboSettings.customTxnNumbers;
};
const showInvoiceQboSection = s => !!(showQboIntegration(s) && !isPendingQboIntegrationSetup(s));
export const integrationSelectors = {
  integrations,
  qboIntegration,
  isLinkedToQbo,
  showQboIntegration,
  isPendingQboIntegrationSetup,
  qboCustomTxnNumbers,
  showInvoiceQboSection,
  allowToCustomizeQboClasses
};