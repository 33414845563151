// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ltKG4{display:flex;flex-direction:row;gap:8px}.sJhBt{display:flex;flex-direction:row;gap:8px}.VFLoa{display:flex;flex-direction:column;gap:12px}.biGEB{padding:24px}.biGEB .bW8cV{padding-bottom:24px}.P51ca{width:168px}.LKRUU{display:flex;flex-direction:row;align-items:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": `ltKG4`,
	"pageSubHeader": `sJhBt`,
	"pageContainer": `VFLoa`,
	"section": `biGEB`,
	"header": `bW8cV`,
	"button": `P51ca`,
	"customTxnNumbersWarning": `LKRUU`
};
export default ___CSS_LOADER_EXPORT___;
