import { sendErrReport } from "../../infra/utils";
import { BillingDayOfMonthType } from "./model";
export function makeBillingDayOfMonth(day) {
  if (day != null && day !== 'last') {
    if (day < 1 || day > 27) {
      sendErrReport("bad billingDayOfMonth day: ".concat(day));
      return {
        type: BillingDayOfMonthType.LastDayOfMonth
      };
    }
    return {
      type: BillingDayOfMonthType.DayOfMonth,
      day
    };
  } else {
    return {
      type: BillingDayOfMonthType.LastDayOfMonth
    };
  }
}