import { ServerError } from "../../infra/ErrorResponse";
import { ordinal, numberAndUnit } from "../../infra/utils";
export function getCreateRelationshipError(err) {
  return err.hasServerError(ServerError.BuyerUserAssociatedWithVendor) ? {
    id: 'member',
    text: 'Proposals cannot be sent to someone within your own business. Please change contact'
  } : err.hasPhoneValidationError() ? {
    id: 'phone',
    text: 'The proposal was not sent because of an invalid phone number, please verify and send again'
  } : err.hasServerError(ServerError.NameAlreadyExists) ? {
    id: 'name',
    text: 'A template with this name already exists.'
  } : {
    id: 'unknown',
    text: 'An internal error occurred, please contact support for further help.'
  };
}
export function getInfoBoxText(day, isBilledUpfront, netTerms) {
  const isFirstDay = day === 1;
  const isLastDay = typeof day !== 'number';
  const netTermsText = netTerms > 0 ? "Payment will be processed automatically within ".concat(numberAndUnit(netTerms, 'day'), ".") : 'Payment will be processed immediately.';
  let issuedText;
  if (isLastDay) {
    issuedText = 'Invoices will be issued on the last day of each month, billing for that month.';
  } else if (isFirstDay) {
    issuedText = isBilledUpfront ? "Invoices will be issued on the ".concat(ordinal(day), " of each month, billing for the full month in advance.") : "Invoices will be issued on the ".concat(ordinal(day), " of each month, billing for the period that started on the ").concat(ordinal(day + 1), " of the previous month.");
  } else {
    issuedText = isBilledUpfront ? "Invoices will be issued on the ".concat(ordinal(day), " of each month, billing for the period that ends on the ").concat(ordinal(day - 1), " of the following month.") : "Invoices will be issued on the ".concat(ordinal(day), " of each month, billing for the period that started on the ").concat(ordinal(day + 1), " of the previous month.");
  }
  return [issuedText, netTermsText];
}
export const billingDayOfMonthToValue = _ref => {
  let {
    type,
    day
  } = _ref;
  return JSON.stringify([type, day]);
};
export const valueToBillingDayOfMonth = value => {
  const [type, day] = JSON.parse(value);
  return {
    type,
    day
  };
};
export const defaultCoverLetterNewClient = "We've created an agreement in Anchor, a billing platform that we use to automate the payment process so both of us can save time and effort.\n\nIt includes a detailed description of the services we agreed on, the price, and the billing terms.\n\nPlease review and approve the agreement.";
export const defaultCoverLetterExistingClient = "We've created a new agreement in Anchor, a billing platform that we use to automate the payment process so both of us can save time and effort.\n\nIt includes a detailed description of the services we agreed on, the price, and the billing terms.\n\nPlease review and approve the agreement.";
export function contactHasAgreement(contact, agreements, proposals) {
  if (!contact.customerId) return false;
  const hasContact = r => !r.isSample && r.customer.id === contact.customerId;
  return !!agreements && agreements.some(hasContact) || !!proposals && proposals.some(hasContact);
}