import { makeNetTerms } from "../../infra/translator";
import { AgreementChangeType } from "./model";
export function makeNetTermsChange(netTerms) {
  return {
    type: AgreementChangeType.NetTerms,
    value: {
      netTerms: makeNetTerms(netTerms)
    }
  };
}
export function makeLegalTermsChange(legalTerms) {
  return {
    type: AgreementChangeType.LegalTerms,
    value: {
      legalTerms
    }
  };
}
export function makeAmendmentsApprovalMethodChange(amendmentsApprovalMethod) {
  return {
    type: AgreementChangeType.AmendmentsApprovalMethod,
    value: {
      amendmentsApprovalMethod
    }
  };
}
export function makeInvoicesApprovalMethodChange(invoicesApprovalMethod) {
  return {
    type: AgreementChangeType.InvoicesApprovalMethod,
    value: {
      invoicesApprovalMethod
    }
  };
}
export function makeServicesChange(services) {
  return {
    type: AgreementChangeType.Services,
    value: {
      services
    }
  };
}
export function makeTitleChange(title) {
  return {
    type: AgreementChangeType.Title,
    value: {
      title
    }
  };
}
export function makeManualPaymentsChange(allowManualPayments) {
  return {
    type: AgreementChangeType.ManualPayments,
    value: {
      allowManualPayments
    }
  };
}
export function makeCoverCreditCardFeesChange(coverCreditCardFees) {
  return {
    type: AgreementChangeType.CoverCreditCardFees,
    value: {
      coverCreditCardFees
    }
  };
}
export function makeSellerChange(seller) {
  return {
    type: AgreementChangeType.Seller,
    value: {
      seller
    }
  };
}