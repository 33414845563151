// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mdVla{display:flex;flex-direction:column}.umjb_{margin-bottom:12px;margin-top:12px}.rrmsV{margin-bottom:16px}.jhnH2{padding-bottom:10px;padding-top:10px;width:600px}.i7NXd{padding-top:40px;align-self:flex-start}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successPageContainer": `mdVla`,
	"header": `umjb_`,
	"pendingAdditionalSignatures": `rrmsV`,
	"callout": `jhnH2`,
	"awaitingRecipients": `i7NXd`
};
export default ___CSS_LOADER_EXPORT___;
