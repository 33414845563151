import { getEmailValidator, nameValidation } from 'validators';
import { findDuplicates } from "../infra/utils";
export const reviewerNameValidator = nameValidation('', {
  required: true
});
export const reviewerEmailValidator = allRecipients => ({
  validate: {
    email: getEmailValidator('', true),
    unique: value => {
      if (typeof value === 'string') {
        const duplicates = findDuplicates(allRecipients.map(r => r.email || ''));
        return !duplicates.includes(value) || 'Email must be unique';
      } else {
        return false;
      }
    }
  }
});
export function validateReviewers(reviewers, primaryContact) {
  const primaryAndReviewers = (primaryContact ? [{
    email: primaryContact.email
  }] : []).concat(reviewers.map(r => ({
    email: r.email || ''
  })));
  const emailValidator = reviewerEmailValidator(primaryAndReviewers);
  return reviewers.every(r => reviewerNameValidator.validate.name(r.firstName) === true && reviewerNameValidator.validate.name(r.lastName) === true && emailValidator.validate.email(r.email) === true && emailValidator.validate.unique(r.email) === true);
}