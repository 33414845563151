import range from 'lodash/range';
export function getAutoApprovalRange() {
  return {
    minDays: 1,
    maxDays: 30
  };
}
export function getAllAutoApprovalDays() {
  const {
    minDays,
    maxDays
  } = getAutoApprovalRange();
  return range(minDays, maxDays + 1);
}
export function isAmendmentAutoApprovalValid(value) {
  const {
    minDays,
    maxDays
  } = getAutoApprovalRange();
  return value >= minDays && value <= maxDays;
}