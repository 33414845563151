import { IntegrationStatus } from "./model";
export function qboIntegration(integrations) {
  return integrations === null || integrations === void 0 ? void 0 : integrations.find(isQboIntegration);
}
export function isQboIntegration(integration) {
  return integration.source === 'direct-qbo';
}
export function isLinked(integration) {
  return integration.status === IntegrationStatus.Linked;
}
export function isErrored(integration) {
  return integration.status === IntegrationStatus.Error;
}
export function automaticInvoicesSync(integration) {
  return integration.settings.automaticInvoicesSync;
}
export function classTrackingAgreementOverride(integration) {
  return integration.settings.classTrackingAgreementOverride;
}