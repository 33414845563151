import { RelationshipFundingSourceType } from "./model";
import { BankAccountStatusTypes, PlaidBankAccountStatusTypes } from "../payment-method";
export function isPendingMicroDeposit(pm) {
  return !!pm && pm.type === RelationshipFundingSourceType.Ach && (pm.ach.status === BankAccountStatusTypes.New || pm.ach.plaidVerificationStatus === PlaidBankAccountStatusTypes.PendingManualVerification || pm.ach.plaidVerificationStatus === PlaidBankAccountStatusTypes.PendingAutomaticVerification);
}
export function failedVerification(pm) {
  return !!pm && pm.type === RelationshipFundingSourceType.Ach && (pm.ach.status === BankAccountStatusTypes.TwoAttemptsFailed || pm.ach.status === BankAccountStatusTypes.VerificationFailed || pm.ach.plaidVerificationStatus === PlaidBankAccountStatusTypes.VerificationFailed || pm.ach.plaidVerificationStatus === PlaidBankAccountStatusTypes.VerificationExpired);
}
export function isVerified(pm) {
  return !!pm && pm.type === RelationshipFundingSourceType.Ach && pm.ach.status === BankAccountStatusTypes.Verified;
}
export function verificationIncomplete(pm) {
  return !!pm && pm.type === RelationshipFundingSourceType.Ach && (pm.ach.status === BankAccountStatusTypes.New || pm.ach.status === BankAccountStatusTypes.TwoAttemptsFailed || pm.ach.status === BankAccountStatusTypes.VerificationFailed || pm.ach.plaidVerificationStatus === PlaidBankAccountStatusTypes.PendingAutomaticVerification || pm.ach.plaidVerificationStatus === PlaidBankAccountStatusTypes.PendingManualVerification || pm.ach.plaidVerificationStatus === PlaidBankAccountStatusTypes.VerificationExpired || pm.ach.plaidVerificationStatus === PlaidBankAccountStatusTypes.VerificationFailed);
}