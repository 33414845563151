import isNumber from 'lodash/isNumber';
import { format } from 'date-fns';
import { makeDatestampArray, makeDatestampObject } from "./makers";
export function fromDatestampObject(dto) {
  return new Date(dto.year, dto.month - 1, dto.day);
}
export function toDatestampObject(date) {
  const isTimestamp = isNumber(date);
  const isArray = Array.isArray(date);
  if (isArray) {
    return makeDatestampObject(date[0], date[1], date[2]);
  } else {
    const normalized = isTimestamp ? new Date(date) : date;
    return makeDatestampObject(normalized.getFullYear(), normalized.getMonth() + 1, normalized.getDate());
  }
}
export function fromDatestampArray(dto) {
  return new Date(dto[0], dto[1] - 1, dto[2]);
}
export function toDatestampArray(date) {
  if (typeof date === 'object' && 'year' in date) {
    return makeDatestampArray(date.year, date.month, date.day);
  }
  const normalized = typeof date === 'number' ? new Date(date) : date;
  return makeDatestampArray(normalized.getFullYear(), normalized.getMonth() + 1, normalized.getDate());
}
export function toDate(dto) {
  const isTimestamp = isNumber(dto);
  const isArray = Array.isArray(dto);
  if (isTimestamp) {
    return new Date(dto);
  } else if (isArray) {
    return fromDatestampArray(dto);
  } else {
    return fromDatestampObject(dto);
  }
}

/** string is expected to be YYYY-MM-DD */
export function fromDatestampString(str) {
  const [year, month, day] = str.split('-');
  return new Date(+year, +month - 1, +day);
}
export function toDatestampString(date) {
  return format(date, 'yyyy-MM-dd');
}