// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Q2v1k{width:680px;height:480px;display:flex;flex-direction:column;gap:8px;align-items:center;justify-content:center}.xpyjH{display:flex;flex-direction:column;gap:8px;align-items:center;text-align:center;width:410px}.bu_Va{display:flex;flex-direction:column;align-items:center;width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"postConnectionWrapper": `Q2v1k`,
	"textWrapper": `xpyjH`,
	"footer": `bu_Va`
};
export default ___CSS_LOADER_EXPORT___;
