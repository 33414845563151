import { Status } from "./model";
export function isSyncFailed(payoutIntegration) {
  return payoutIntegration.status === Status.SyncFailed;
}
export function isSynced(payoutIntegration) {
  return payoutIntegration.status === Status.Synced;
}
export function date(payoutIntegration) {
  switch (payoutIntegration.status) {
    case Status.Synced:
    case Status.SyncFailed:
      return new Date(payoutIntegration.date);
    case Status.PendingSync:
    case Status.SyncInProgress:
    default:
      return null;
  }
}
export function invoicesReady(payoutIntegration) {
  switch (payoutIntegration.status) {
    case Status.PendingSync:
      return payoutIntegration.invoicesReady;
    case Status.Synced:
    case Status.SyncFailed:
    case Status.SyncInProgress:
    default:
      return null;
  }
}