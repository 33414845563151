import { useMemo } from 'react';
import { useGlobalContext, useStoreActions } from "../../contexts";
import { handlePromise, sendErrReport } from "../../infra/utils";
import { relationshipModel } from "../../models";
import { useFeatureFlags } from "../useFeatureFlags";
export function useSendReminder() {
  const {
    api
  } = useStoreActions();
  const {
    MULTIPLE_RECEPIENTS = false
  } = useFeatureFlags();
  const {
    showSuccessToast,
    showErrorToast
  } = useGlobalContext();
  async function sendReminder(agreement) {
    const result = await handlePromise(MULTIPLE_RECEPIENTS ? api.relationship.sendProposalApprovalReminder(agreement.id) : api.relationship.resendApprovalEmail(agreement.id));
    if (result.success) {
      showSuccessToast("Reminder successfully sent".concat(MULTIPLE_RECEPIENTS ? '' : ' to ' + relationshipModel.customerName(agreement)));
    } else {
      showErrorToast('Failed to send reminder');
      sendErrReport('Failed to send reminder', result.error);
    }
  }
  return useMemo(() => ({
    sendReminder
  }), []);
}