import flattenDeep from 'lodash/flattenDeep';
import uniqBy from 'lodash/uniqBy';
import xor from 'lodash/xor';
import { BillingTriggerType } from "../../types/api.model";
import { ServiceCostType } from "../../models/service/model";
import { isBoolField, isBoolTrue, createErrorObject, parseCsv, validateCsvColumns, addCsvLineNumbers, validateCsvRow } from "../../components/ImportWizard/ImportWizard.utils";
import { serviceTemplateModel, serviceModel } from "../../models";
import { decimalFromStr } from "../../infra/utils";
import { convertStrToRichText } from 'seagull';
export const incompatiblePriceAndPricingMsg = 'Invalid entry. When pricing is set to Range, you must specify a positive number for max rate or both min and max rate, for example 100-500';
export const recurrencePeriodError = 'Invalid entry. Please select whether the Recurrence period is <i>One time</i>, <i>Weekly</i>, <i>Biweekly</i>, <i>Monthly</i>, <i>Quarterly</i>, or <i>Yearly</i>';
export const recurrencePeriodYearlyError = '<i>Yearly</i> cannot be selected when "Automatic Billing" is set to <i>Yes</i>. You can set "Automatic Billing" to <i>No</i> and "Billing occurrence" to <i>Yearly</i> or choose a different "Billing occurrence"';
export const pricingError = 'Invalid entry. Please select whether the Pricing is <i>Fixed</i>, <i>Hourly</i>, <i>Quantity</i>, or <i>Range</i>';
export const fixedPriceAutomaticServiceError = 'Invalid entry. Pricing must be <i>Fixed</i> for an automatic service';
export const priceError = ' Invalid entry. Enter a positive number only, without any special symbols or characters.';
export const rangePriceError = 'Invalid entry. When pricing is set to Range, you must specify a positive number for max rate or both min and max rate, for example <i>100-500</i>';
export const positiveError = 'Invalid entry. Please specify a positive number';
export const discountTypeError = 'Invalid entry. Please select whether the Discount is <i>Flat</i> or <i>Percent</i>';
export const nonUniqueServiceNameError = 'Invalid entry. This service name already exists in your Service Library. Please choose a different name';
export const minPriceExceedingError = "Min price can't exceed max price. Please enter a lower value for min price or a higher value for max price";
export const percentDiscountLargerThan100 = 'Invalid entry. A percent discount cannot be greater than 100. Please enter a value equal to or less than 100';
export const flatDiscountGreaterThanPrice = 'Invalid entry. A flat discount cannot be greater than the price. Please enter a flat discount value equal to or less than the price';
export const getEmptyStringError = fieldName => "Column \"".concat(fieldName, "\": Invalid entry. ").concat(fieldName, " must have a value");
export const getInvalidBooleanError = _fieldName => 'invalid entry. Please ensure this column value is set to either "Yes" or "No".';
export const servicesCsvColumnConfig = {
  ['service name']: {
    inputName: 'serviceName',
    required: true,
    validate: serviceName => {
      const error = serviceModel.validateName(serviceName);
      return error ? {
        valid: false,
        errorType: error
      } : {
        valid: true
      };
    },
    requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getEmptyStringError(headerName)),
    validateError: (headerName, rowNumber, errorType) => {
      if (errorType) {
        return createErrorObject(headerName, rowNumber, errorType);
      }
    }
  },
  ['service description']: {
    inputName: 'serviceDescription'
  },
  ['automatic billing']: {
    inputName: 'automaticBilling',
    required: true,
    requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName)),
    validate: isBoolField,
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName))
  },
  ['billing occurrence']: {
    inputName: 'billingOccurrence',
    validate: (recurrencePeriod, _ref) => {
      let {
        rowObject
      } = _ref;
      const {
        automaticBilling
      } = rowObject;
      const isAutomatic = isBoolTrue(automaticBilling);
      if ((recurrencePeriod === null || recurrencePeriod === void 0 ? void 0 : recurrencePeriod.toLowerCase()) === 'one time') return {
        valid: true
      };
      const isValidRecurrencePeriod = ['weekly', 'biweekly', 'monthly', 'quarterly', 'yearly'].includes(recurrencePeriod === null || recurrencePeriod === void 0 ? void 0 : recurrencePeriod.toLowerCase());
      if (!isValidRecurrencePeriod) return {
        valid: false,
        errorType: 'invalidValue'
      };
      const isYearly = (recurrencePeriod === null || recurrencePeriod === void 0 ? void 0 : recurrencePeriod.toLowerCase()) === 'yearly';
      return {
        valid: !(isYearly && isAutomatic),
        errorType: 'yearlyAndAutomatic'
      };
    },
    validateError: (headerName, rowNumber, errorType) => {
      switch (errorType) {
        case 'invalidValue':
          return createErrorObject(headerName, rowNumber, recurrencePeriodError);
        case 'yearlyAndAutomatic':
          return createErrorObject(headerName, rowNumber, recurrencePeriodYearlyError);
      }
    }
  },
  ['pricing']: {
    inputName: 'pricing',
    validate: (pricing, _ref2) => {
      let {
        rowObject
      } = _ref2;
      const normalized = pricing.toLowerCase();
      const validPricing = ['fixed', 'hourly', 'quantity', 'range'].includes(normalized);
      if (!validPricing) return {
        errorType: 'pricingError'
      };
      const isNonFixedPrice = normalized !== 'fixed';
      if (isNonFixedPrice && isBoolTrue(rowObject.automaticBilling)) return {
        errorType: 'nonFixedAutomatic'
      };
      return true;
    },
    validateError: (headerName, rowNumber, errorType) => {
      const errorMsg = errorType === 'pricingError' ? pricingError : errorType === 'nonFixedAutomatic' ? fixedPriceAutomaticServiceError : '';
      if (!errorMsg) return;
      return createErrorObject(headerName, rowNumber, errorMsg);
    }
  },
  ['rate']: {
    inputName: 'rate',
    required: true,
    requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, priceError),
    validate: (price, _ref3) => {
      let {
        rowObject
      } = _ref3;
      const isRangePricing = rowObject.pricing.toLowerCase() === 'range';
      if (price.startsWith('-')) {
        return {
          errorType: isRangePricing ? 'rangePriceError' : 'priceError'
        };
      }
      // split in case of range price format ${min}-${max}
      const priceSplit = price.split('-');
      const trimmedPrices = priceSplit.map(p => p.trim());
      const hasMoreThanOnePrice = priceSplit.length > 1 && priceSplit[0].length && priceSplit[1].length;
      if (hasMoreThanOnePrice) {
        if (!isRangePricing) return {
          errorType: 'incompatiblePriceAndPricing'
        };
        const [first, second] = trimmedPrices;
        const min = decimalFromStr(first);
        const max = decimalFromStr(second);
        if (min && max && min.gt(max)) return {
          errorType: 'minPriceExceedMaxPrice'
        };
      }
      const validPrices = trimmedPrices.every(p => {
        const decimal = decimalFromStr(p);
        return !!decimal && decimal.isPositive();
      });
      return validPrices || {
        errorType: isRangePricing ? 'rangePriceError' : 'priceError'
      };
    },
    validateError: (headerName, rowNumber, errorType) => {
      switch (errorType) {
        case 'priceError':
          return createErrorObject(headerName, rowNumber, priceError);
        case 'rangePriceError':
          return createErrorObject(headerName, rowNumber, rangePriceError);
        case 'incompatiblePriceAndPricing':
          return createErrorObject(headerName, rowNumber, incompatiblePriceAndPricingMsg);
        case 'minPriceExceedMaxPrice':
          return createErrorObject(headerName, rowNumber, minPriceExceedingError);
      }
    }
  },
  ['unit cap']: {
    inputName: 'unitCap',
    validate: unitCap => {
      if ((unitCap === null || unitCap === void 0 ? void 0 : unitCap.length) === 0) return true;
      const decimal = decimalFromStr(unitCap);
      if (!decimal) return false;
      const isPositiveNumber = decimal.isPositive();
      const isNonZero = !decimal.isZero();
      return isPositiveNumber && isNonZero;
    },
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, positiveError)
  },
  ['unit name']: {
    inputName: 'unitName',
    required: true,
    validate: (unitName, _ref4) => {
      let {
        rowObject
      } = _ref4;
      const isQuantityPricing = rowObject.pricing.toLowerCase() === 'quantity';
      return !(isQuantityPricing && !unitName);
    },
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getEmptyStringError(headerName))
  },
  ['discount']: {
    inputName: 'discount',
    validate: (discount, _ref5) => {
      let {
        rowObject
      } = _ref5;
      if ((discount === null || discount === void 0 ? void 0 : discount.length) === 0) return true;
      const decimal = decimalFromStr(discount);
      if (!decimal) return {
        errorType: 'negative'
      };
      const isPositiveNumber = decimal.isPositive();
      if (!isPositiveNumber) {
        return {
          errorType: 'negative'
        };
      }
      const discountType = rowObject.discountType.toLowerCase();
      if (discountType === 'percent' && decimal.gt(100)) {
        return {
          errorType: 'percentLargerThan100'
        };
      }
      if (discountType === 'flat' && rowObject.pricing.toLowerCase() !== 'range' && decimal.gt(rowObject.rate)) {
        return {
          errorType: 'flatLargerThanPrice'
        };
      }
      return true;
    },
    validateError: (headerName, rowNumber, errorType) => {
      switch (errorType) {
        case 'negative':
          return createErrorObject(headerName, rowNumber, positiveError);
        case 'percentLargerThan100':
          return createErrorObject(headerName, rowNumber, percentDiscountLargerThan100);
        case 'flatLargerThanPrice':
          return createErrorObject(headerName, rowNumber, flatDiscountGreaterThanPrice);
      }
    }
  },
  ['discount type']: {
    inputName: 'discountType',
    validate: discountType => ['flat', 'percent', ''].includes(discountType === null || discountType === void 0 ? void 0 : discountType.toLowerCase()),
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, discountTypeError)
  }
};
function runValidation(data, existingServiceTemplates) {
  if (!data.length) throw new Error('No data');
  validateCsvColumns(data, servicesCsvColumnConfig);

  // validate services
  const withLineNumbers = addCsvLineNumbers(data);
  const errors = withLineNumbers.map(rowObject => validateCsvRow(rowObject, servicesCsvColumnConfig));
  const nonUniqueServicesByName = xor(withLineNumbers, uniqBy(existingServiceTemplates.map(s => ({
    lineNumber: -1,
    serviceName: s.name
  })).concat(withLineNumbers), 'serviceName')).filter(s => s.lineNumber !== -1);
  if (nonUniqueServicesByName.length) {
    nonUniqueServicesByName.forEach(rowObject => errors.push([createErrorObject('service name', rowObject.lineNumber, nonUniqueServiceNameError)]));
  }
  return flattenDeep(errors);
}
const createServices = parsedCsv => {
  return parsedCsv.map(row => {
    var _decimalFromStr;
    const discountAmount = row.discount && !Number.isNaN(+row.discount) ? +row.discount : null;
    const pricing = row.pricing.toLowerCase();
    const pricingType = pricing === 'fixed' ? ServiceCostType.Fixed : pricing === 'range' ? ServiceCostType.Range : ServiceCostType.Variable;
    const hasMinPrice = pricingType === 'range' && row.rate.includes('-');
    const minPrice = hasMinPrice && ((_decimalFromStr = decimalFromStr(row.rate.split('-')[0])) === null || _decimalFromStr === void 0 ? void 0 : _decimalFromStr.toDP(2).toNumber()) || null;
    const price = decimalFromStr(hasMinPrice ? row.rate.split('-')[1] : row.rate).toDP(2);
    const isAutomaticBilling = isBoolTrue(row.automaticBilling);
    const normalizedRecurrencePeriod = row.billingOccurrence.toLowerCase();
    const isRecurring = normalizedRecurrencePeriod !== 'one time';
    const billingTrigger = isAutomaticBilling ? isRecurring ? BillingTriggerType.Ongoing : BillingTriggerType.OnApproval : isRecurring ? BillingTriggerType.RepeatableManual : BillingTriggerType.OneTimeManual;
    const serviceDiscount = discountAmount != null ? row.discountType.toLowerCase() === 'percent' ? serviceTemplateModel.makePercentDiscount(discountAmount) : serviceTemplateModel.makeValueDiscount(discountAmount) : null;
    const serviceCost = (() => {
      switch (pricingType) {
        case ServiceCostType.Fixed:
          return serviceTemplateModel.makeFixedCost(price, {
            discount: serviceDiscount
          });
        case ServiceCostType.Variable:
          {
            const decimalUnitCap = decimalFromStr(row.unitCap);
            return serviceTemplateModel.makeVariableCost(price, {
              unitCap: decimalUnitCap || 0,
              unitName: row.unitName || null,
              discount: serviceDiscount
            });
          }
        case ServiceCostType.Range:
          return serviceTemplateModel.makeRangeCost(price, {
            minPrice,
            discount: serviceDiscount
          });
      }
    })();
    return serviceTemplateModel.makeServiceTemplate({
      name: row.serviceName,
      description: convertStrToRichText(row.serviceDescription),
      cost: serviceCost,
      billing: billingTrigger === BillingTriggerType.OnApproval ? serviceTemplateModel.makeBillingOnApproval() : billingTrigger === BillingTriggerType.OneTimeManual ? serviceTemplateModel.makeBillingOneTimeManual() : billingTrigger === BillingTriggerType.Ongoing ? serviceTemplateModel.makeBillingOngoing(normalizedRecurrencePeriod) : serviceTemplateModel.makeBillingRepeatableManual(normalizedRecurrencePeriod)
    });
  });
};
function tryToParseError(error, or) {
  try {
    return JSON.parse(error);
  } catch (e) {
    return or;
  }
}
export const parseAndValidateServicesCsv = async (data, existingServiceTemplates) => {
  try {
    const parsedData = await parseCsv(data, servicesCsvColumnConfig);
    const errors = runValidation(parsedData, existingServiceTemplates);
    if (!errors.length) {
      return {
        items: createServices(parsedData),
        errors
      };
    } else {
      return {
        items: [],
        errors
      };
    }
  } catch (exn) {
    var _tryToParseError;
    return {
      items: [],
      errors: [{
        message: '' + exn,
        rowNumber: (exn === null || exn === void 0 ? void 0 : exn.message) != null ? (_tryToParseError = tryToParseError(exn.message, 0)) === null || _tryToParseError === void 0 ? void 0 : _tryToParseError.lineNumber : 0
      }]
    };
  }
};