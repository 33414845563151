import compact from 'lodash/compact';
import { RecurrencePeriodTypes } from "../../models/service/model";
import { ordinal } from "../../infra/utils";
import { billingDayOfMonthModel, serviceModel, timeModel } from "../../models";
import { isAfter } from 'date-fns';
import { formatDate } from "../../models/time";
export const getServiceBillingText = (service, agreementData) => {
  const priceIncrease = serviceModel.priceIncrease(service);
  return compact([...getBillingTextByBillingTrigger(service, agreementData), priceIncrease && {
    intent: 'price-increase',
    text: "Price increases by ".concat(priceIncrease.percentIncrease, "% every year ").concat(priceIncrease.increaseOn ? "starting on ".concat(formatDate(priceIncrease.increaseOn)) : 'on agreement anniversary')
  }]);
};
const getBillingTextByBillingTrigger = (service, agreementData) => {
  const billing = serviceModel.billingTrigger(service);
  const maxCharges = serviceModel.maxChargesEnabled(service) ? serviceModel.maxChargesLimit(service) : null;
  const isNewService = serviceModel.isAwaitingApproval(service.status);
  if (serviceModel.isOneTimeDelivery(billing)) {
    return [oneTimeDeliveryMainBullet()];
  } else if (serviceModel.isOneTimeManual(billing)) {
    return [oneTimeManualMainBullet()];
  } else if (serviceModel.isOnApproval(billing)) {
    const effectiveDate = serviceModel.effectiveDate(service);
    return [agreementData.isActiveAgreement && isNewService ? approvalAwaitingApprovalBullet() : agreementData.effectiveDate && effectiveDate && isAfter(effectiveDate, timeModel.fromDatestampObject(agreementData.effectiveDate)) ? approvalAddedAfterAgreementApprovalBullet() : approvalMainBullet()];
  } else if (serviceModel.isOngoing(billing)) {
    const billingDayOfWeek = serviceModel.billingDayOfWeek(service);
    const billingDayOfMonth = serviceModel.billingDayOfMonth(service);
    const recurrencePeriod = serviceModel.recurrencePeriod(billing);
    return compact([ongoingMainBullet(recurrencePeriod, maxCharges), serviceModel.isBilledUpfront(service) && !(agreementData.isActiveAgreement && isNewService) && billedUpfrontBullet(), recurrencePeriod === RecurrencePeriodTypes.Weekly && billingDayOfWeek && dayOfWeekWeeklyBullet(billingDayOfWeek), recurrencePeriod === RecurrencePeriodTypes.BiWeekly && billingDayOfWeek && dayOfWeekBiWeeklyBullet(billingDayOfWeek), recurrencePeriod === RecurrencePeriodTypes.Monthly && billingDayOfMonth && dayOfMonthBullet(billingDayOfMonth, recurrencePeriod), recurrencePeriod !== RecurrencePeriodTypes.Monthly && recurrencePeriod !== RecurrencePeriodTypes.Weekly && recurrencePeriod !== RecurrencePeriodTypes.BiWeekly && dayOfMonthBullet(serviceModel.isBilledUpfront(service) ? 1 : 'last', recurrencePeriod), serviceModel.isPaused(service) && pausedBullet(), serviceModel.isPaused(service) === false && serviceModel.isSkipBillingOnAcceptance(service) === false && !agreementData.isActiveAgreement && serviceModel.isBilledUpfront(service) && unpausedBullet(), serviceModel.isProrated(service) && proratedBullet()]);
  } else {
    // All that's left now is RepeatableManual
    const recurrencePeriod = serviceModel.recurrencePeriod(billing);
    return [repeatableManualMainBullet(recurrencePeriod, maxCharges)];
  }
};
function maxChargesPostText(maxCharges) {
  return maxCharges != null ? ", ".concat(maxCharges === 1 ? 'once' : maxCharges + ' times') : '';
}
export const manualBillingCycleMap = {
  weekly: 'a week',
  biweekly: 'every two weeks',
  monthly: 'a month',
  quarterly: 'a quarter',
  yearly: 'a year'
};
export const autoBillingCycleMap = {
  weekly: 'each week',
  biweekly: 'every two weeks',
  monthly: 'each month',
  quarterly: 'each quarter',
  yearly: 'each year'
};
const recurrencePeriodMap = {
  weekly: 'weekly',
  biweekly: 'every two weeks',
  monthly: 'monthly',
  quarterly: 'quarterly',
  yearly: 'yearly'
};
export const approvalMainBullet = () => ({
  intent: 'approval-main',
  text: 'Billed once, automatically, on the agreement effective date'
});
export const approvalAddedAfterAgreementApprovalBullet = () => ({
  intent: 'approval-added-after-agreement-approval',
  text: 'Billed once, automatically'
});
export const approvalAwaitingApprovalBullet = () => ({
  intent: 'approval-awaiting-approval',
  text: 'Billed once, automatically, on amendment approval'
});
export const ongoingMainBullet = (recurrencePeriod, maxCharges) => ({
  intent: 'ongoing-main',
  text: "Billed ".concat(recurrencePeriodMap[recurrencePeriod]).concat(maxChargesPostText(maxCharges))
});
export const repeatableManualMainBullet = (recurrencePeriod, maxCharges) => ({
  intent: 'repeatable-manual-main',
  text: "Can be billed once ".concat(manualBillingCycleMap[recurrencePeriod]).concat(maxChargesPostText(maxCharges))
});
export const dayOfMonthBullet = (dayOfMonth, recurrencePeriod) => {
  const normalized = typeof dayOfMonth === 'object' ? billingDayOfMonthModel.isLastDayOfMonth(dayOfMonth) ? 'last' : dayOfMonth.day : dayOfMonth;
  const isQuarter = recurrencePeriod === RecurrencePeriodTypes.Quarterly;
  return {
    intent: 'day-of-month',
    text: "Billed on the ".concat(normalized === 'last' ? normalized : ordinal(normalized)).concat(isQuarter || normalized === 'last' ? ' day' : '', " of ").concat(autoBillingCycleMap[recurrencePeriod])
  };
};
export const dayOfWeekWeeklyBullet = dayOfWeek => {
  return {
    intent: 'day-of-week',
    text: "Billed every ".concat(dayOfWeek)
  };
};
export const dayOfWeekBiWeeklyBullet = dayOfWeek => {
  return {
    intent: 'day-of-week',
    text: "Billed every other ".concat(dayOfWeek)
  };
};
export const pausedBullet = () => ({
  intent: 'paused',
  text: 'Billing will commence after service activation'
});
export const unpausedBullet = () => ({
  intent: 'unpaused',
  text: 'An invoice will be issued on the agreement effective date'
});
export const billedUpfrontBullet = () => ({
  text: 'Billed upfront',
  intent: 'billed-upfront'
});
export const oneTimeManualMainBullet = () => ({
  intent: 'onetime-manual-main',
  text: 'Can be billed once'
});
export const proratedBullet = () => ({
  intent: 'prorated',
  text: 'Prorated payment'
});
export const oneTimeDeliveryMainBullet = () => ({
  text: 'Billed on approval of delivery',
  intent: 'onetime-delivery-main'
});