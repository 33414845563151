import { BillingDayOfMonthType } from "./model";
export function toGoBillingDayOfMonth(_ref) {
  let {
    type,
    day
  } = _ref;
  return type === BillingDayOfMonthType.DayOfMonth ? {
    type,
    dayOfMonth: {
      day: day !== null && day !== void 0 ? day : 0
    }
  } : {
    type
  };
}