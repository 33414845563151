// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hENW6{display:flex;flex-direction:column;gap:8px}.o9Hg3{padding-left:28px;display:flex;align-items:center;gap:8px;margin-top:8px}.xBwx1{margin-top:16px;height:40px}.wBnEf{flex-shrink:0}.RLcLg{display:flex;align-items:center;gap:12px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settings": `hENW6`,
	"additionalControl": `o9Hg3`,
	"roundPrices": `xBwx1`,
	"noShrink": `wBnEf`,
	"container": `RLcLg`
};
export default ___CSS_LOADER_EXPORT___;
