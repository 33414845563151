import { addDays } from 'date-fns';
import { invoiceIntegrationModel } from "./..";
import { InvoicePaymentMethodStatus, InvoiceStatus } from "../../types/api.model";
import { InvoicePaymentDisplayStatusType } from 'anchor-scout/types';
import { ApprovalMethodNoticePeriodType } from "../approval-method-notice-period";
export function isPending(invoice) {
  return invoice.status === InvoiceStatus.Pending;
}
export function isPaid(invoice) {
  return invoice.status === InvoiceStatus.Paid;
}
export function isConfirmed(invoice) {
  return invoice.status === InvoiceStatus.PaymentConfirmed;
}
export function isProcessing(invoice) {
  return invoice.status === InvoiceStatus.ProcessingCharge;
}
export function isPaidExternally(invoice) {
  return invoice.status === InvoiceStatus.PaidExternally;
}
export function isVoided(invoice) {
  return invoice.status === InvoiceStatus.Voided;
}
export function isRefunded(invoice) {
  return invoice.status === InvoiceStatus.Refunded;
}
export function isPaymentStopped(invoice) {
  return invoice.status === InvoiceStatus.PaymentStopped;
}
export function isPaymentFailed(invoice) {
  return invoice.status === InvoiceStatus.PaymentFailed;
}
export function isRequiresPaymentApproval(invoice) {
  return invoice.status === InvoiceStatus.RequiresPaymentApproval;
}
export function isPaymentActionRequired(invoice) {
  return invoice.status === InvoiceStatus.PaymentActionRequired;
}
export function isDisputed(invoice) {
  return invoice.status === InvoiceStatus.PaymentDisputed;
}
export function isRefundRequested(paymentStatus) {
  return !!paymentStatus && paymentStatus.type === InvoicePaymentDisplayStatusType.RefundRequested;
}
export function status(invoice) {
  return invoice.status;
}
export function paymentMethodStatus(invoice) {
  return invoice.paymentMethodStatus;
}
export function dueDate(invoice) {
  return invoice.dueDate;
}
export function formattedNumber(_ref) {
  let {
    formattedNumber
  } = _ref;
  return formattedNumber;
}
export function issuedOn(_ref2) {
  let {
    issuedOn
  } = _ref2;
  return issuedOn;
}
export function paymentFailureMessage(_ref3) {
  let {
    paymentFailure
  } = _ref3;
  return paymentFailure === null || paymentFailure === void 0 ? void 0 : paymentFailure.message;
}
export function processingAction(_ref4) {
  let {
    processingAction
  } = _ref4;
  return {
    stripeUrl: processingAction === null || processingAction === void 0 ? void 0 : processingAction.url,
    clientSecret: processingAction === null || processingAction === void 0 ? void 0 : processingAction.clientSecret
  };
}
export function isPaymentMethodUnset(invoice) {
  return invoice.paymentMethodStatus === InvoicePaymentMethodStatus.PaymentMethodNotSet;
}
export function isPaymentMethodUnverified(invoice) {
  return invoice.paymentMethodStatus === InvoicePaymentMethodStatus.PaymentMethodUnverified;
}
export function isStableIntegrationStatus(invoice) {
  const {
    integration
  } = invoice;
  if (!integration) {
    return true;
  }
  switch (integration.status) {
    case invoiceIntegrationModel.Status.PendingSync:
    case invoiceIntegrationModel.Status.InvoiceSyncFailed:
    case invoiceIntegrationModel.Status.PaymentSyncFailed:
    case invoiceIntegrationModel.Status.RefundSyncFailed:
    case invoiceIntegrationModel.Status.VoidedRecorded:
    case invoiceIntegrationModel.Status.PaymentRecorded:
    case invoiceIntegrationModel.Status.RefundRecorded:
      return true;
    case invoiceIntegrationModel.Status.InvoiceRecorded:
      return isPending(invoice) || isPaidExternally(invoice) || isPaymentStopped(invoice) || isPaid(invoice) && invoice.isZeroAmount || isRequiresPaymentApproval(invoice) || isPaymentActionRequired(invoice);
  }
}
export const integration = _ref5 => {
  let {
    integration
  } = _ref5;
  return integration;
};
export function paymentStopReason(invoice) {
  return invoice.paymentStop ? invoice.paymentStop.reason : '';
}
export const autoApprovalDate = _ref6 => {
  let {
    approvalMethod,
    issuedOn
  } = _ref6;
  return approvalMethod && approvalMethod.type === ApprovalMethodNoticePeriodType.Automatic ? addDays(new Date(issuedOn), approvalMethod.auto.noticePeriodDays) : null;
};
export const totalAmount = _ref7 => {
  let {
    totalAmount
  } = _ref7;
  return totalAmount;
};
export const id = _ref8 => {
  let {
    id
  } = _ref8;
  return id;
};
export const itemName = _ref9 => {
  let {
    name
  } = _ref9;
  return name;
};
export const itemQuantity = _ref10 => {
  let {
    quantity
  } = _ref10;
  return quantity || '';
};
export const itemRate = _ref11 => {
  let {
    rate
  } = _ref11;
  return rate;
};
export const itemDiscount = _ref12 => {
  let {
    discount
  } = _ref12;
  return discount;
};
export const itemTotalAmount = _ref13 => {
  let {
    totalAmount
  } = _ref13;
  return totalAmount;
};