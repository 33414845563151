import { BankAccountStatusTypes, PlaidBankAccountStatusTypes } from "../payment-method";
import { RelationshipStatus } from "../relationship/model";
import { RelationshipFundingSourceType } from "../relationship-funding-source";
import { fullName } from "../../infra/utils";
export const id = rel => rel.id;
export const customerBusinessId = rel => rel.customer.id;
export const customerBusinessName = rel => rel.customer.name;
export const progressStatus = rel => rel.progressStatus;
export const fundingSource = rel => rel.fundingSource;
export const canCompleteVerification = rel => rel.fundingSource && rel.fundingSource.type === RelationshipFundingSourceType.Ach && (rel.fundingSource.ach.status === BankAccountStatusTypes.New || rel.fundingSource.ach.plaidVerificationStatus === PlaidBankAccountStatusTypes.PendingManualVerification);
export const opposingPartyName = (rel, isVendor) => isVendor ? rel.customer.name : rel.vendor.name;
export function customerName(rel) {
  return rel.suggestedCustomerName || rel.customer.name;
}
export const isApproved = rel => rel.status === RelationshipStatus.Approved;
export const isTerminated = rel => rel.status === RelationshipStatus.Terminated;
export const isSample = rel => rel.isSample;
export function sellerFullName(rel) {
  return fullName(rel.seller.firstName, rel.seller.lastName);
}
export function sellerId(rel) {
  return rel.seller.id;
}