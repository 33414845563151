import { getPaymentStatusText, getStatusLabel } from "../business-logic/invoiceLogic";
import { downloadFromBlob } from "../infra/utils";
import { sortAlphabetically } from "../infra/utils";
import { payoutModel } from "../models";
import { invoiceSummaryModel } from "../models";
export function exportCsv(csvRows, filename) {
  const blob = new Blob([csvRows.map(rowToCsv).join('\n')], {
    type: 'text/csv;charset=utf-8;'
  });
  downloadFromBlob(blob, filename);
}
function rowToCsv(row) {
  return row.map(quoteCsvFields).join(',');
}
function quoteCsvFields(text) {
  return "\"".concat(('' + text).replace(/"/g, '""'), "\"");
}
export function makePayoutsCsv(payouts) {
  return [['Invoice #', 'Client', 'Issue date', 'Due date', 'Deposit date', 'Sum']].concat(payouts.map(payout => sortAlphabetically(payout.invoices.slice(), 'formattedNumber', {
    ascending: true
  }).map(invoice => payoutInvoiceToArray(invoice, payoutModel.createdAt(payout)))).flat(1));
}
function payoutInvoiceToArray(payoutInvoice, depositDate) {
  return [payoutInvoice.formattedNumber, payoutInvoice.customer || '', payoutInvoice.createdOn.toLocaleDateString(), payoutInvoice.dueDate.toLocaleDateString(), depositDate.toLocaleDateString(), "$".concat(payoutInvoice.depositAmount.amount().toString())];
}
export const invoicesCsvFileName = 'exported-invoices.csv';
const invoicesCsvHeaderRow = ['InvoiceNo', 'Customer', 'InvoiceDate', 'DueDate', 'Item(Product/Service)', 'ItemQuantity', 'ItemRate', 'ItemAmount', 'InvoiceStatus', 'PaymentStatus'];
export function makeInvoicesCsv(invoices, isVendor) {
  return [invoicesCsvHeaderRow].concat(invoices.map(inv => {
    const invoiceNo = invoiceSummaryModel.formattedNumber(inv);
    const customer = invoiceSummaryModel.customerName(inv);
    const invoiceDate = invoiceSummaryModel.issueDate(inv);
    const invoiceStatus = getStatusLabel(inv.displayedStatus);
    const paymentStatus = getPaymentStatusText(inv.paymentStatus, isVendor);
    return inv.items.map(item => {
      const itemName = item.name;
      const itemQuantity = item.quantity ? item.quantity : '';
      const itemRate = item.rate[0] === '$' ? item.rate.slice(1) : item.rate;
      const itemAmount = item.totalAmount[0] === '$' ? item.totalAmount.slice(1) : item.totalAmount;
      return [invoiceNo, customer, invoiceDate, inv.dueDate, itemName, itemQuantity, itemRate, itemAmount, invoiceStatus, paymentStatus];
    });
  }).flat(1));
}