// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vhpdj{display:flex;flex-direction:column;gap:20px;align-items:flex-start}.SZeGi{margin:8px;width:70%;display:flex;align-items:center;gap:12px}.lwhIs{margin:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": `vhpdj`,
	"copyApiKeyContainer": `SZeGi`,
	"goToZapierContainer": `lwhIs`
};
export default ___CSS_LOADER_EXPORT___;
