function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import produce from 'immer';
import { ServiceCostType } from "../service/model";
import { billingTrigger, isRecurring, periodRequiresBillingDayOfWeek, isMonthlyService, isOngoing, effectiveDate, isAmended, isAwaitingApproval, isCompleted, changes } from "./selectors";
import * as selectors from "./selectors";
import { makeAmendedStatus, makeApprovedStatus, makeAwaitingApprovalStatus, makeCanceledStatus, makeCompletedStatus, makeMaxCharges, makeTerminatedStatus, makeChanges, makeBillUpfrontState } from "./makers";
import { ToggledField, toggleNewOngoingBilling } from "./newOngoingBilling";
export function setBillingTrigger(service, billingTrigger) {
  return _objectSpread(_objectSpread({}, service), {}, {
    billing: billingTrigger
  });
}
export function setBillUpfront(service, billUpfront) {
  return produce(service, s => {
    if (isOngoing(s.billing) && billUpfront !== s.billing.isBilledUpfront) {
      if (isAwaitingApproval(s.status)) {
        s.billing = toggleNewOngoingBilling(s.billing, ToggledField.IsBilledUpfront);
      } else {
        s.billing.isBilledUpfront = billUpfront;
      }
    }
  });
}
export function setSkipBillingOnAcceptance(service, skipBillingOnAcceptance) {
  return produce(service, s => {
    if (isOngoing(s.billing) && skipBillingOnAcceptance !== s.billing.billUpfrontState.skipBillingOnAcceptance) {
      if (isAwaitingApproval(s.status)) {
        s.billing = toggleNewOngoingBilling(s.billing, ToggledField.SkipBillingOnAcceptance);
      } else {
        s.billing.billUpfrontState = makeBillUpfrontState(skipBillingOnAcceptance);
      }
    }
  });
}
export function setBillingDayOfWeek(service, billingDayOfWeek) {
  const newBilling = setBillingDayOfWeekOnBilling(service.billing, billingDayOfWeek);
  return newBilling !== service.billing ? _objectSpread(_objectSpread({}, service), {}, {
    billing: newBilling
  }) : service;
}
export function setBillingDayOfWeekOnBilling(billing, billingDayOfWeek) {
  return isRecurring(billing) && periodRequiresBillingDayOfWeek(billing.recurrencePeriod) ? _objectSpread(_objectSpread({}, billing), {}, {
    billingDayOfWeek
  }) : billing;
}
export function setBillingDayOfMonth(service, billingDayOfMonth) {
  const newBilling = setBillingDayOfMonthOnBilling(service.billing, billingDayOfMonth);
  return newBilling !== service.billing ? _objectSpread(_objectSpread({}, service), {}, {
    billing: newBilling
  }) : service;
}
export function setBillingDayOfMonthOnBilling(billing, billingDayOfMonth) {
  return isMonthlyService({
    billing
  }) && isRecurring(billing) ? _objectSpread(_objectSpread({}, billing), {}, {
    billingDayOfMonth
  }) : billing;
}
export function setMaxCharges(service, enabled, limit) {
  const trigger = billingTrigger(service);
  if (isRecurring(trigger)) {
    return setBillingTrigger(service, _objectSpread(_objectSpread({}, trigger), {}, {
      maxCharges: {
        enabled,
        limit
      }
    }));
  } else {
    return service;
  }
}
export function setIntegratedClass(service, integratedClass) {
  return _objectSpread(_objectSpread({}, service), {}, {
    integratedClass
  });
}
export function deleteMaxCharges(service) {
  return produce(service, s => {
    const trigger = billingTrigger(s);
    if (isRecurring(trigger)) {
      trigger.maxCharges = makeMaxCharges(false);
    }
  });
}
export function setPaused(service, isPaused) {
  return produce(service, s => {
    if (isOngoing(s.billing) && isPaused !== s.billing.isPaused) {
      if (isAwaitingApproval(s.status)) {
        s.billing = toggleNewOngoingBilling(s.billing, ToggledField.IsPaused);
      } else {
        s.billing.isPaused = isPaused;
      }
    }
  });
}
export function setProrated(service, isProrated) {
  return produce(service, s => {
    if (isOngoing(s.billing) && isProrated !== s.billing.isProrated) {
      if (isAwaitingApproval(s.status)) {
        s.billing = toggleNewOngoingBilling(s.billing, ToggledField.IsProrated);
      } else {
        s.billing.isProrated = isProrated;
      }
    }
  });
}
export function setTerminated(service) {
  return _objectSpread(_objectSpread({}, service), {}, {
    status: makeTerminatedStatus(effectiveDate(service) || new Date())
  });
}
export function setCanceled(service) {
  return _objectSpread(_objectSpread({}, service), {}, {
    status: makeCanceledStatus()
  });
}
export function setAwaitingApproval(service, options) {
  return _objectSpread(_objectSpread({}, service), {}, {
    status: makeAwaitingApprovalStatus(options)
  });
}
export function discardChanges(service) {
  if (isAmended(service.status)) {
    return _objectSpread(_objectSpread({}, service), {}, {
      status: makeApprovedStatus(effectiveDate(service) || new Date())
    });
  } else if (isCompleted(service.status)) {
    return _objectSpread(_objectSpread({}, service), {}, {
      status: _objectSpread(_objectSpread({}, service.status), {}, {
        completed: {}
      })
    });
  } else {
    return service;
  }
}
export function setChanges(service, changes) {
  if (isAmended(service.status)) {
    return _objectSpread(_objectSpread({}, service), {}, {
      status: makeAmendedStatus(_objectSpread(_objectSpread({
        date: service.status.date
      }, service.status.amended), {}, {
        changes
      }))
    });
  } else if (isAwaitingApproval(service.status)) {
    return _objectSpread(_objectSpread({}, service), {}, {
      status: makeAmendedStatus({
        date: effectiveDate(service),
        approvalMethod: service.status.awaitingApproval.approvalMethod,
        amendedOn: service.status.awaitingApproval.addedOn,
        changes,
        note: service.status.awaitingApproval.note
      })
    });
  } else if (isCompleted(service.status)) {
    return _objectSpread(_objectSpread({}, service), {}, {
      status: makeCompletedStatus(service.status.date, changes)
    });
  } else {
    return _objectSpread(_objectSpread({}, service), {}, {
      status: makeAmendedStatus({
        changes,
        date: effectiveDate(service)
      })
    });
  }
}
export function setBillingChanges(service, billing) {
  const existingChanges = changes(service);
  const newChanges = existingChanges ? _objectSpread(_objectSpread({}, existingChanges), {}, {
    billing
  }) : makeChanges({
    billing
  });
  return setChanges(service, newChanges);
}
export function setBillingDayOfWeekChanges(service, dayOfWeek) {
  const newBilling = setBillingDayOfWeekOnBilling(applyChanges(service).billing, dayOfWeek);
  return setBillingChanges(service, newBilling);
}
export function setBillingDayOfMonthChanges(service, dayOfMonth) {
  const newBilling = setBillingDayOfMonthOnBilling(applyChanges(service).billing, dayOfMonth);
  return setBillingChanges(service, newBilling);
}
export function setCostChanges(service, cost) {
  const existingChanges = changes(service);
  const newChanges = existingChanges ? _objectSpread(_objectSpread({}, existingChanges), {}, {
    cost
  }) : makeChanges({
    cost
  });
  return setChanges(service, newChanges);
}
export function setPriceIncreaseChanges(service, priceIncrease) {
  const newCost = setPriceIncrease(applyChanges(service), priceIncrease).cost;
  return setCostChanges(service, newCost);
}
export function setNote(service, note) {
  if (isAmended(service.status)) {
    return _objectSpread(_objectSpread({}, service), {}, {
      status: _objectSpread(_objectSpread({}, service.status), {}, {
        amended: _objectSpread(_objectSpread({}, service.status.amended), {}, {
          note
        })
      })
    });
  } else if (isAwaitingApproval(service.status)) {
    return _objectSpread(_objectSpread({}, service), {}, {
      status: _objectSpread(_objectSpread({}, service.status), {}, {
        awaitingApproval: _objectSpread(_objectSpread({}, service.status.awaitingApproval), {}, {
          note
        })
      })
    });
  }
  return service;
}
export function applyChanges(service) {
  return isAmended(service.status) ? applyChangesOnService(service, service.status.amended.changes) : isCompleted(service.status) ? applyChangesOnService(service, service.status.completed.changes) : service;
}
function applyChangesOnService(service, changes) {
  if (!changes) {
    return service;
  }
  return _objectSpread(_objectSpread({}, service), {}, {
    name: changes.name != null ? changes.name : service.name,
    description: changes.description != null ? changes.description : service.description,
    billing: changes.billing != null ? changes.billing : service.billing,
    cost: changes.cost != null ? changes.cost : service.cost
  });
}
export function setPriceIncrease(service, priceIncrease) {
  return produce(service, s => {
    s.cost.priceIncrease = priceIncrease;
  });
}
export function setDiscount(service, discount) {
  return _objectSpread(_objectSpread({}, service), {}, {
    cost: _objectSpread(_objectSpread({}, service.cost), {}, {
      discount
    })
  });
}
export function setDiscountChanges(service, discount) {
  const newCost = setDiscount(applyChanges(service), discount).cost;
  return setCostChanges(service, newCost);
}
export function setPrice(service, cost) {
  switch (service.cost.pricingType) {
    case ServiceCostType.Fixed:
    case ServiceCostType.Variable:
      return _objectSpread(_objectSpread({}, service), {}, {
        cost: _objectSpread(_objectSpread({}, service.cost), {}, {
          price: selectors.price({
            cost
          })
        })
      });
    case ServiceCostType.Range:
      return _objectSpread(_objectSpread({}, service), {}, {
        cost: _objectSpread(_objectSpread({}, service.cost), {}, {
          maxPrice: selectors.price({
            cost
          }),
          minPrice: selectors.minPrice({
            cost
          })
        })
      });
  }
}
export function setPriceChanges(service, cost) {
  const newCost = setPrice(applyChanges(service), cost).cost;
  return setCostChanges(service, newCost);
}