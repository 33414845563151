import { useMemo } from 'react';
import { useGlobalContext, useStoreActions } from "../../contexts";
import { handleRequest, sendErrReport } from "../../infra/utils";
export function useIntegrations() {
  const {
    api
  } = useStoreActions();
  const {
    showErrorToast
  } = useGlobalContext();
  async function createIntegration(source, redirectUrl) {
    const integration = await handleRequest(api.integration.create(source, redirectUrl));
    if (!integration.success) {
      sendErrReport('Failed to create integration', integration.error);
      showErrorToast('Failed to connect', 'failed-to-connect-toast');
    } else {
      return integration.data;
    }
  }
  async function disconnectIntegration() {
    const integration = await handleRequest(api.integration.disconnect());
    if (!integration.success) {
      sendErrReport('Failed to pause integration', integration.error);
      showErrorToast('Failed to disconnect', 'failed-to-disconnect-toast');
    } else {
      return integration.data;
    }
  }
  async function syncIntegrationData() {
    const integration = await handleRequest(api.integration.syncData());
    if (!integration.success) {
      sendErrReport('Failed to sync integration data', integration.error);
      return [integration.data, integration.error];
    }
    return [integration.data, integration.error];
  }
  async function updateIntegrationSettings(settings) {
    const result = await handleRequest(api.integration.updateSettings(settings));
    if (!result.success) {
      sendErrReport('Failed to update integration settings', result.error);
      showErrorToast('Failed to update integration settings', 'failed-to-sync-toast');
      return result;
    }
    return result;
  }
  return useMemo(() => ({
    createIntegration,
    disconnectIntegration,
    syncIntegrationData,
    updateIntegrationSettings
  }), []);
}