function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { integrationFailureModel } from "./..";
import { StatusDTO } from "./dto";
import { Status } from "./model";
export function fromDTO(invoiceIntegrationDTO, isZeroAmount) {
  switch (invoiceIntegrationDTO.status) {
    case StatusDTO.Synced:
      {
        var _invoiceIntegrationDT;
        const date = (_invoiceIntegrationDT = invoiceIntegrationDTO.synced) === null || _invoiceIntegrationDT === void 0 ? void 0 : _invoiceIntegrationDT.date;
        return makeInvoiceIntegrationInvoiceRecorded(date);
      }
    case StatusDTO.SyncFailed:
      {
        const failure = integrationFailureModel.fromDTO(invoiceIntegrationDTO.syncFailed);
        return makeInvoiceIntegrationInvoiceSyncFailed(failure);
      }
    case StatusDTO.PaymentFailed:
      {
        const failure = integrationFailureModel.fromDTO(invoiceIntegrationDTO.paymentFailed);
        return makeInvoiceIntegrationPaymentSyncFailed(failure);
      }
    case StatusDTO.PendingSync:
      {
        var _invoiceIntegrationDT2, _invoiceIntegrationDT3;
        const customerLinked = (_invoiceIntegrationDT2 = invoiceIntegrationDTO.pendingSync) === null || _invoiceIntegrationDT2 === void 0 ? void 0 : _invoiceIntegrationDT2.customerLinked;
        const itemsLinked = (_invoiceIntegrationDT3 = invoiceIntegrationDTO.pendingSync) === null || _invoiceIntegrationDT3 === void 0 ? void 0 : _invoiceIntegrationDT3.itemsLinked;
        return makeInvoiceIntegrationPendingSync(customerLinked, itemsLinked);
      }
    case StatusDTO.Voided:
      {
        var _invoiceIntegrationDT4;
        const date = (_invoiceIntegrationDT4 = invoiceIntegrationDTO.voided) === null || _invoiceIntegrationDT4 === void 0 ? void 0 : _invoiceIntegrationDT4.date;
        return makeInvoiceIntegrationVoidedRecorded(date);
      }
    case StatusDTO.Paid:
      {
        var _invoiceIntegrationDT5;
        const date = (_invoiceIntegrationDT5 = invoiceIntegrationDTO.paid) === null || _invoiceIntegrationDT5 === void 0 ? void 0 : _invoiceIntegrationDT5.date;
        return isZeroAmount ? makeInvoiceIntegrationInvoiceRecorded(date) : makeInvoiceIntegrationPaymentRecorded(date);
      }
    case StatusDTO.Refunded:
      {
        var _invoiceIntegrationDT6;
        const date = (_invoiceIntegrationDT6 = invoiceIntegrationDTO.refunded) === null || _invoiceIntegrationDT6 === void 0 ? void 0 : _invoiceIntegrationDT6.date;
        return makeInvoiceIntegrationRefundRecorded(date);
      }
    case StatusDTO.RefundFailed:
      {
        const failure = integrationFailureModel.fromDTO(invoiceIntegrationDTO.refundFailed);
        return makeInvoiceIntegrationRefundSyncFailed(failure);
      }
  }
}
const makeInvoiceIntegrationInvoiceRecorded = date => {
  return {
    status: Status.InvoiceRecorded,
    date
  };
};
const makeInvoiceIntegrationVoidedRecorded = date => {
  return {
    status: Status.VoidedRecorded,
    date
  };
};
const makeInvoiceIntegrationInvoiceSyncFailed = failure => {
  return _objectSpread({
    status: Status.InvoiceSyncFailed
  }, failure);
};
const makeInvoiceIntegrationPaymentSyncFailed = failure => {
  return _objectSpread({
    status: Status.PaymentSyncFailed
  }, failure);
};
const makeInvoiceIntegrationPendingSync = (customerLinked, itemsLinked) => {
  return {
    status: Status.PendingSync,
    customerLinked,
    itemsLinked
  };
};
const makeInvoiceIntegrationPaymentRecorded = date => {
  return {
    status: Status.PaymentRecorded,
    date
  };
};
const makeInvoiceIntegrationRefundRecorded = date => {
  return {
    status: Status.RefundRecorded,
    date
  };
};
const makeInvoiceIntegrationRefundSyncFailed = failure => {
  return _objectSpread({
    status: Status.RefundSyncFailed
  }, failure);
};