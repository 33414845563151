// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nJcHn{display:inline-flex;align-items:center;gap:8px}.gRASh{display:block}.gRASh>svg{display:block}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `nJcHn`,
	"icon": `gRASh`
};
export default ___CSS_LOADER_EXPORT___;
