import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { returnToStorageKey, routes } from "./constants";

/** Signifies a returning OAuth flow. */
export default function OAuthLP() {
  const history = useHistory();
  useEffect(() => {
    localStorage.setItem('receivedRedirectUri', window.location.href);
    history.replace(localStorage.getItem(returnToStorageKey) || routes.home);
  });
  return null;
}