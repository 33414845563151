import { BillingDayOfMonthType } from "./model";
export function isLastDayOfMonth(billingDayOfMonth) {
  return billingDayOfMonth.type === BillingDayOfMonthType.LastDayOfMonth;
}
export function isEqual(dayOfMonth1, dayOfMonth2) {
  if (!dayOfMonth1) {
    return !dayOfMonth2;
  } else if (!dayOfMonth2) {
    return false;
  } else if (isLastDayOfMonth(dayOfMonth1)) {
    return isLastDayOfMonth(dayOfMonth2);
  } else {
    return !isLastDayOfMonth(dayOfMonth2) && dayOfMonth1.day === dayOfMonth2.day;
  }
}