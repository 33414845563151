function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { getDisplayedStatus } from "../../business-logic/invoiceLogic";
import * as invoiceIntegrationModel from "../invoice-integration";
import * as timeModel from "../time";
import * as moneyModel from "../money";
import * as serviceModel from "../service";
import { InvoicePaymentMethodStatus, InvoiceStatus } from "../../types/api.model";
import { isAfter, startOfDay } from 'date-fns';
import Decimal from 'decimal.js';
import { utils } from "../../utils";
import { InvoicePaymentDisplayStatusType } from 'anchor-scout/types';
export function fromScoutDTO(isVendor, invoiceSummary) {
  return {
    id: invoiceSummary.id,
    formattedNumber: invoiceSummary.formattedNumber,
    pdfExists: invoiceSummary.pdfExists,
    paymentMethodStatus: invoiceSummary.paymentMethodStatus,
    createdOn: invoiceSummary.createdOn,
    issuedOn: invoiceSummary.issuedOn,
    //items: invoiceSummary.items,
    relationship: invoiceSummary.relationship,
    dueDate: invoiceSummary.dueDate,
    totalAmount: invoiceSummary.totalAmount,
    number: numberFromFormattedNumber(invoiceSummary.formattedNumber),
    rawCreatedOn: new Date(invoiceSummary.createdOn),
    rawDueDate: new Date(invoiceSummary.dueDate),
    rawIssuedOn: new Date(invoiceSummary.issuedOn),
    displayedStatus: invoiceSummary.displayStatus,
    paymentStatus: isVendor ? invoiceSummary.vendorPaymentDisplayStatus : invoiceSummary.customerPaymentDisplayStatus,
    status: isVendor ? invoiceSummary.vendorStatus : invoiceSummary.customerStatus,
    integration: invoiceSummary.integration,
    rawTotalAmount: new Decimal(invoiceSummary.rawTotalAmount).div(100).toDP(2),
    items: invoiceSummary.items
  };
}
export function fromDTO(dto, now, isVendor, hasLinkedBankAccount, kycCompleted, disablePayments) {
  const rawDueDate = timeModel.fromDatestampObject(dto.dueDate);
  const status = dto.statusDetails;
  const paymentMethodStatus = dto.paymentMethodStatus;
  const items = dto.items.map(itemFromDTO);
  const rawTotalAmount = items.reduce((total, item) => total.add(item.totalAmount), new Decimal(0));
  const rawIssuedOn = timeModel.fromDatestampObject(dto.issuedOn ? dto.issuedOn : dto.createdOn);
  const rawCreatedOn = timeModel.fromDatestampObject(dto.createdOn);
  return {
    id: dto.id,
    formattedNumber: dto.formattedNumber,
    number: numberFromFormattedNumber(dto.formattedNumber),
    status,
    pdfExists: dto.pdfExists,
    paymentMethodStatus,
    rawCreatedOn,
    createdOn: timeModel.formatDate(rawCreatedOn),
    rawIssuedOn,
    issuedOn: rawIssuedOn ? timeModel.formatDate(rawIssuedOn) : null,
    rawDueDate,
    dueDate: timeModel.formatDate(rawDueDate),
    relationship: dto.relationship,
    integration: dto.integration ? invoiceIntegrationModel.fromDTO(dto.integration, rawTotalAmount.isZero()) : null,
    items: items.map(item => _objectSpread(_objectSpread({}, item), {}, {
      rate: utils.formatDollarAmount(new Decimal(item.rate).toDP(2)),
      totalAmount: utils.formatDollarAmount(new Decimal(item.totalAmount).toDP(2))
    })),
    rawTotalAmount,
    totalAmount: utils.formatDollarAmount(rawTotalAmount),
    displayedStatus: getDisplayedStatus(dto.statusDetails.type, rawDueDate, now),
    paymentStatus: getPaymentStatus(status, paymentMethodStatus, rawDueDate, rawTotalAmount, isVendor, now, hasLinkedBankAccount, kycCompleted, disablePayments)
  };
}
function getPaymentStatus(status, paymentMethodStatus, dueDate, totalAmount, isVendor, now, hasLinkedBankAccount, kycCompleted, disablePayments) {
  if (paymentMethodStatus === InvoicePaymentMethodStatus.PaymentMethodNotSet && supportsPaymentMethodNotSet(status.type)) {
    return {
      type: InvoicePaymentDisplayStatusType.PaymentMethodNotSet
    };
  } else if (paymentMethodStatus === InvoicePaymentMethodStatus.PaymentMethodUnverified && supportsPaymentMethodNotSet(status.type)) {
    return {
      type: InvoicePaymentDisplayStatusType.PaymentMethodUnverified
    };
  } else {
    switch (status.type) {
      case InvoiceStatus.PaymentFailed:
        return {
          type: InvoicePaymentDisplayStatusType.PaymentFailed
        };
      case InvoiceStatus.Paid:
        return isVendor && !totalAmount.isZero() ? {
          type: InvoicePaymentDisplayStatusType.Paid,
          timestamp: status.paid.paymentDate,
          paidInFutureDay: isAfter(startOfDay(new Date(status.paid.paymentDate)), startOfDay(now))
        } : null;
      case InvoiceStatus.PaidExternally:
        return isVendor ? {
          type: InvoicePaymentDisplayStatusType.PaidExternally,
          timestamp: status.paidExternally.timestamp
        } : null;
      case InvoiceStatus.RequiresPaymentApproval:
        return {
          type: InvoicePaymentDisplayStatusType.RequiresPaymentApproval
        };
      case InvoiceStatus.PaymentActionRequired:
        return {
          type: InvoicePaymentDisplayStatusType.RequiresPaymentValidation
        };
      case InvoiceStatus.Pending:
        return isVendor && hasLinkedBankAccount && kycCompleted && !disablePayments && paymentMethodStatus === InvoicePaymentMethodStatus.PaymentMethodSet && !isAfter(startOfDay(dueDate), startOfDay(now)) ? {
          type: InvoicePaymentDisplayStatusType.Pending
        } : null;
      case InvoiceStatus.ProcessingCharge:
        return isVendor ? {
          type: InvoicePaymentDisplayStatusType.Processing
        } : null;
      case InvoiceStatus.PaymentConfirmed:
        return isVendor ? {
          type: InvoicePaymentDisplayStatusType.Collected
        } : null;
      case InvoiceStatus.Refunded:
        return {
          type: InvoicePaymentDisplayStatusType.Refunded,
          timestamp: status.refunded.timestamp
        };
      case InvoiceStatus.PaymentStopped:
        return {
          type: InvoicePaymentDisplayStatusType.StoppedPayment,
          timestamp: status.paymentStopped.timestamp
        };
      case InvoiceStatus.PaymentDisputed:
        return _objectSpread({
          type: InvoicePaymentDisplayStatusType.Disputed
        }, status.paymentDisputed);
      default:
        return null;
    }
  }
}
function supportsPaymentMethodNotSet(status) {
  return [InvoiceStatus.Pending, InvoiceStatus.PaymentActionRequired, InvoiceStatus.PaymentFailed].includes(status);
}
function itemFromDTO(dto) {
  const rate = moneyModel.fromDTO(dto.temp_rate).amount().toDP(2).toString();
  const discount = serviceModel.discountFromDTO(dto.discount);
  const quantity = dto.temp_quantity != null ? new Decimal(dto.temp_quantity).div(100).toDP(2).toString() : null;
  const proRata = dto.temp_proRata != null ? new Decimal(dto.temp_proRata).div(100).toDP(2).toString() : null;
  return {
    name: dto.name,
    quantity,
    rate,
    totalAmount: utils.calculateFinalPrice(new Decimal(rate), discount, quantity != null ? new Decimal(quantity) : null).mul(proRata != null ? new Decimal(proRata) : 1).toDP(2).toString(),
    serviceId: null
  };
}
function numberFromFormattedNumber(formattedNumber) {
  const match = formattedNumber.match(/(\d+)$/);
  const numberPart = match ? match[1] : '';
  const asNumber = numberPart ? +numberPart : -1;
  return Number.isNaN(asNumber) ? -1 : asNumber;
}