import { moneyModel } from "./..";
import { utils } from "../../utils";
const getAmount = value => moneyModel.isMoney(value) ? value.amount() : value;
const _mul = (value, quantity) => make(value.mul(getAmount(quantity)));
const _div = (value, quantity) => make(value.div(getAmount(quantity)));
const _add = (value1, value2) => make(value1.add(getAmount(value2)));
const _sub = (value1, value2) => make(value1.sub(getAmount(value2)));
const _abs = value => make(value.abs());
const _neg = value => make(value.neg());
const _toFixed = (value, decimalPoint) => value.toFixed(decimalPoint);
const _isZero = value => value.isZero();
const _isNegative = value => value.isNegative();
const _isInteger = value => value.isInteger();
export function make(_amount) {
  return {
    amount: () => _amount,
    mul: quantity => _mul(_amount, quantity),
    div: quantity => _div(_amount, quantity),
    add: amount2 => _add(_amount, amount2),
    sub: amount2 => _sub(_amount, amount2),
    abs: () => _abs(_amount),
    neg: () => _neg(_amount),
    toFixed: decimalPoint => _toFixed(_amount, decimalPoint),
    isZero: () => _isZero(_amount),
    isNegative: () => _isNegative(_amount),
    isInteger: () => _isInteger(_amount),
    display: () => utils.formatDollarAmount(_amount)
  };
}