function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import compact from 'lodash/compact';
import parse from 'csv-parse/lib/browser/sync';
import { sortAlphabetically } from "../../infra/utils";
import pluralize from 'pluralize';

// eslint-disable-next-line @typescript-eslint/no-explicit-any

// eslint-disable-next-line @typescript-eslint/no-explicit-any

// eslint-disable-next-line @typescript-eslint/no-explicit-any

export const isBoolField = field => ['yes', 'no'].includes(field.toLowerCase());
export const isBoolTrue = bool => bool.toLowerCase() === 'yes';
export const getEmptyStringError = fieldName => "".concat(fieldName, " must have a value");
export const invalidEmailError = 'Please provide a valid email address';
export const phoneNumberError = 'This value must match the international telephone number format';

// e164 is a standard for "International Telephone Input Validation". This regex is the same one the BE uses.
export const e164Regex = /^\+[1-9]?[0-9]{7,14}$/;

/** Makes sure that a phone number starts with a "+" as the initial character. Doesn't touch an empty phone number. */
export const normalizePhoneNumber = phone => phone ? "".concat(phone.startsWith('+') ? '' : '+').concat(phone) : phone;
export const createErrorObject = (headerName, rowNumber, error) => console.error("Column \"".concat(headerName, "\": ").concat(error, ".")) || {
  rowNumber,
  message: "Column \"".concat(headerName, "\": ").concat(error, ".")
};
export const createRowErrorsObject = errorObjects => {
  if (!errorObjects.length) return null;
  const sortedMessages = sortAlphabetically(errorObjects, 'message', {
    ascending: true,
    ignoreCase: true
  });
  return sortedMessages.reduce((rowToMessages, error) => {
    const {
      rowNumber,
      message
    } = error;
    const key = rowNumber.toString();
    rowToMessages[key] = rowToMessages[key] ? rowToMessages[key].concat(message) : [message];
    return rowToMessages;
  }, {});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseCsv = (data, csvColConfig) => {
  return parse(data, {
    skip_empty_lines: true,
    trim: true,
    columns: header => header.map(columnName => {
      const lowerCaseColumnName = columnName.trim().toLowerCase();
      if (csvColConfig[lowerCaseColumnName]) {
        return csvColConfig[lowerCaseColumnName].inputName;
      } else {
        throw new Error("Could not parse unsupported field: \"".concat(columnName, "\""));
      }
    })
  });
};
export function validateCsvColumns(data, csvColConfig) {
  const expectedColumns = new Set(Object.values(csvColConfig).map(c => c.inputName));
  const receivedColumns = new Set(Object.keys(data[0]));
  const unexpectedColumns = [];
  receivedColumns.forEach(column => {
    if (!expectedColumns.has(column)) {
      unexpectedColumns.push(column);
    } else {
      expectedColumns.delete(column);
    }
  });
  function columnNameFromInputName(inputName) {
    const columnEntry = Object.entries(csvColConfig).find(_ref => {
      let [, value] = _ref;
      return value.inputName === inputName;
    });
    return columnEntry ? columnEntry[0] : inputName;
  }
  if (expectedColumns.size !== 0) throw new Error("".concat(pluralize('Column', expectedColumns.size), " ").concat(expectedColumns.size > 1 ? 'are' : 'is', " missing: ").concat(Array.from(expectedColumns).map(columnNameFromInputName).join(', ')));
  if (unexpectedColumns.length > 0) throw new Error("Unexpected columns: ".concat(unexpectedColumns.join(', ')));
}
export function addCsvLineNumbers(data) {
  return data.map((item, i) => _objectSpread(_objectSpread({}, item), {}, {
    lineNumber: i + 2
  }));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function validateCsvRow(rowObject, csvColConfig, extraValidation, now) {
  return compact(Object.keys(csvColConfig).map(columnName => {
    const columnConfig = csvColConfig[columnName];
    const {
      inputName,
      required,
      requiredError,
      validate,
      validateError
    } = columnConfig;
    const value = rowObject[inputName];
    if (extraValidation) {
      const extraError = extraValidation(columnConfig, columnName);
      if (extraError) return extraError;
    }
    if (required && !value && requiredError) {
      return requiredError(columnName, rowObject.lineNumber);
    }
    if (validate && validateError) {
      const validationResult = validate(value, {
        rowObject,
        now
      });
      const isBoolean = typeof validationResult === 'boolean';
      const isValid = isBoolean ? validationResult : validationResult === null || validationResult === void 0 ? void 0 : validationResult.valid;
      const errorType = isBoolean ? undefined : validationResult.errorType;
      return !isValid && validateError(columnName, rowObject.lineNumber, errorType);
    }
  }));
}