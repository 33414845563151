import { payoutIntegrationModel } from "./..";
import { StatusEnum } from "./model";
export const id = _ref => {
  let {
    id
  } = _ref;
  return id;
};
export const createdAt = _ref2 => {
  let {
    createdAt
  } = _ref2;
  return createdAt;
};
export const amount = _ref3 => {
  let {
    amount
  } = _ref3;
  return amount;
};
export const status = _ref4 => {
  let {
    status
  } = _ref4;
  return status;
};
export const integration = _ref5 => {
  let {
    integration
  } = _ref5;
  return integration;
};
export const invoices = _ref6 => {
  let {
    invoices
  } = _ref6;
  return invoices;
};
export const hasFailedMessage = _ref7 => {
  let {
    payoutFailure
  } = _ref7;
  return !!(payoutFailure !== null && payoutFailure !== void 0 && payoutFailure.message);
};
export const message = _ref8 => {
  let {
    payoutFailure
  } = _ref8;
  return payoutFailure === null || payoutFailure === void 0 ? void 0 : payoutFailure.message;
};
export function isSynced(payout) {
  return !!payout.integration && payoutIntegrationModel.isSynced(payout.integration);
}
export function isNotSynced(payout) {
  return !!payout.integration && !payoutIntegrationModel.isSynced(payout.integration);
}
export function isPaid(payout) {
  return payout.status === StatusEnum.Paid;
}