import Decimal from 'decimal.js';
import { make } from "./makers";
import { utils } from "../../utils";
export function fromDTO(dto) {
  const amount = new Decimal(dto.amount).div(100).toDP(2);
  return make(amount);
}
export function toDTO(money) {
  return {
    amount: utils.dollarsToCents(money.amount())
  };
}