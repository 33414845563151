import Decimal from 'decimal.js';
import { DiscountType } from "../../../../models/service/model";
import { getUnitName, pluralizeUnitName } from "../../../../utils/unit-name.utils";
import { utils } from "../../../../utils";
import { serviceModel } from "../../../../models";
export const currencyFormatter = function currencyFormatter(price) {
  let amount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Decimal(0);
  let type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DiscountType.Value;
  const discountedPrice = utils.applyDiscount(price, {
    type,
    amount
  });
  return utils.formatDollarAmount(discountedPrice);
};
export const stringifyServiceCost = cost => {
  const {
    discount,
    pricingType
  } = cost;
  const {
    amount,
    type
  } = discount !== null && discount !== void 0 ? discount : {
    amount: new Decimal(0),
    type: DiscountType.Value
  };
  if (pricingType === 'fixed') {
    return {
      costDisplay: currencyFormatter(cost.price, amount, type)
    };
  } else if (pricingType === 'variable') {
    const unitCap = cost.unitCap;
    const unitName = getUnitName(cost.unitName);
    const unitCapDisplay = unitCap ? "".concat(unitCap, " ").concat(pluralizeUnitName(unitCap, unitName), " preapproved") : undefined;
    return {
      costDisplay: "".concat(currencyFormatter(cost.price, amount, type), "/").concat(unitName),
      unitCapDisplay
    };
  } else if (cost.minPrice) {
    return {
      costDisplay: "".concat(currencyFormatter(cost.minPrice, amount, type), "\u2014").concat(currencyFormatter(cost.maxPrice, amount, type))
    };
  } else {
    return {
      costDisplay: "Up to ".concat(currencyFormatter(cost.maxPrice, amount, type))
    };
  }
};
export function applyChangesToService(s) {
  if (!serviceModel.isAmended(s.status)) {
    return s;
  }
  const newService = serviceModel.applyChanges(s);
  const billing = s.billing;
  return serviceModel.isOngoing(billing) ? serviceModel.setPaused(newService, billing.isPaused) : newService;
}
export function getPauseDisabledTooltip(isCredit) {
  const type = isCredit ? 'Credit' : 'Automatic service';
  return "".concat(type, " status changes take effect immediately, and are therefore only available when managing the upcoming invoice");
}