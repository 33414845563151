import { contactModel } from "../../models";
export function getAccountantAccessItem(accountantAccess) {
  switch (accountantAccess) {
    case contactModel.AccountantAccess.NonCandidate:
      return null;
    case contactModel.AccountantAccess.Active:
      return {
        id: 'remove',
        text: 'Remove Accountant Access',
        skin: 'error'
      };
    case contactModel.AccountantAccess.Invited:
      return {
        id: 'cancel',
        text: 'Cancel request',
        skin: 'error'
      };
    case contactModel.AccountantAccess.Candidate:
      return {
        id: 'request',
        text: 'Request Accountant Access'
      };
  }
}