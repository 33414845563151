// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Slate$AnchorFrontend = require("./bindings/Slate.bs.js");
function renderBold(text, children) {
  if (Slate$AnchorFrontend.$$Text.bold(text)) {
    return React.createElement("strong", undefined, children);
  } else {
    return children;
  }
}
function renderItalic(text, children) {
  if (Slate$AnchorFrontend.$$Text.italic(text)) {
    return React.createElement("em", undefined, children);
  } else {
    return children;
  }
}
function renderUnderline(text, children) {
  if (Slate$AnchorFrontend.$$Text.underline(text)) {
    return React.createElement("u", undefined, children);
  } else {
    return children;
  }
}
function renderStrikethrough(text, children) {
  if (Slate$AnchorFrontend.$$Text.strikethrough(text)) {
    return React.createElement("s", undefined, children);
  } else {
    return children;
  }
}
function RichTextEditor_Leaf(Props) {
  var attributes = Props.attributes;
  var leaf = Props.leaf;
  var children = Props.children;
  var elementToRender = renderStrikethrough(leaf, renderUnderline(leaf, renderItalic(leaf, renderBold(leaf, children))));
  return React.createElement("span", attributes, elementToRender);
}
var make = RichTextEditor_Leaf;
exports.make = make;
/* react Not a pure module */