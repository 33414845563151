// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.X02QI{width:400px}.uXrQc{display:flex;flex-direction:row;gap:4px}.uXrQc .b6LsF{display:flex;flex-direction:row}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autocompleteWrapper": `X02QI`,
	"accountToSetColumn": `uXrQc`,
	"textWrapper": `b6LsF`
};
export default ___CSS_LOADER_EXPORT___;
