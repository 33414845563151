import { contactModel } from "./..";
export function id(contact) {
  return contact.id;
}
export function edit(contact) {
  return contact.edit;
}
export function isSample(contact) {
  return contact.isSample;
}
export function version(contact) {
  return contact.version;
}
export function isEditable(editMode) {
  return editMode.mode === contactModel.EditMode.Editable;
}
export function isEmailEditable(contact) {
  return isEditable(contact.edit) ? contact.edit.isEmailEditable : false;
}
export function status(contact) {
  return contact.status;
}
export function companyName(contact) {
  return contact.companyName;
}
export function firstName(contact) {
  return contact.firstName;
}
export function lastName(contact) {
  return contact.lastName;
}
export function fullName(contact) {
  return "".concat(contact.firstName, " ").concat(contact.lastName);
}
export function email(contact) {
  return contact.email;
}
export function integratedId(contact) {
  return contact.integratedId;
}
export function customerId(contact) {
  return contact.customerId;
}
export function phone(contact) {
  return contact.phone;
}
export function uniqueKey(_ref) {
  let {
    companyName,
    email
  } = _ref;
  return (companyName.trim() + email.trim()).toLowerCase();
}
export function isActive(status) {
  return status === contactModel.Status.Active;
}
export function hasSignedAgreements(contact) {
  return contact.hasSignedAgreements;
}
export function hasPendingProposals(contact) {
  return contact.hasPendingProposals;
}