function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { Status } from "./model";
import { StatusDTO } from "./dto";
import { integrationFailureModel } from "./..";
export function fromDTO(payoutIntegrationDTO) {
  switch (payoutIntegrationDTO.status) {
    case StatusDTO.Synced:
      {
        var _payoutIntegrationDTO;
        const date = (_payoutIntegrationDTO = payoutIntegrationDTO.synced) === null || _payoutIntegrationDTO === void 0 ? void 0 : _payoutIntegrationDTO.date;
        return makeSyncedPayoutIntegration(date);
      }
    case StatusDTO.SyncFailed:
      {
        const failure = integrationFailureModel.fromDTO(payoutIntegrationDTO.syncFailed);
        return makeSyncFailedPayoutIntegration(failure);
      }
    case StatusDTO.PendingSync:
      {
        var _payoutIntegrationDTO2;
        const invoicesReady = (_payoutIntegrationDTO2 = payoutIntegrationDTO.pendingSync) === null || _payoutIntegrationDTO2 === void 0 ? void 0 : _payoutIntegrationDTO2.invoicesReady;
        return makePendingSyncPayoutIntegration(invoicesReady);
      }
    case StatusDTO.SyncInProgress:
      return makeSyncInProgressPayoutIntegration();
  }
}
const makeSyncedPayoutIntegration = date => {
  return {
    status: Status.Synced,
    date
  };
};
const makeSyncFailedPayoutIntegration = failure => {
  return _objectSpread({
    status: Status.SyncFailed
  }, failure);
};
const makePendingSyncPayoutIntegration = invoicesReady => {
  return {
    status: Status.PendingSync,
    invoicesReady
  };
};
const makeSyncInProgressPayoutIntegration = () => {
  return {
    status: Status.SyncInProgress
  };
};