function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { BillingTriggerType } from "../../types/api.model";
import { RecurrencePeriodTypes, ServiceCostType, DiscountTypeDTO, DiscountType, ServiceStatusType } from "./model";
import { utils } from "../../utils";
import * as makers from "./makers";
import * as selectors from "./selectors";
import { defaultBillingDayOfWeek } from "../../constants";
import { billingDayOfMonthModel, priceIncreaseModel, serviceTemplateModel, timeModel } from '..';
import { makeManualApprovalMethodDate } from "../approval-method-date";
import Decimal from 'decimal.js';
export function discountFromDTO(dto) {
  if (dto == null) return null;
  switch (dto.type) {
    case DiscountTypeDTO.Percent:
      return makers.makePercentDiscount(dto.amount);
    case DiscountTypeDTO.Value:
      return makers.makeValueDiscount(dto.amount);
    case DiscountTypeDTO.None:
      return null;
  }
}
export function discountToDTO(discount) {
  if (discount == null) return null;
  return {
    type: discountTypeToDTO(discount.type),
    amount: discount.amount.toNumber(),
    temp_amount: discount.amount.mul(100).toNumber()
  };
}
export function discountTypeToDTO(discountType) {
  return discountType === DiscountType.Percent ? DiscountTypeDTO.Percent : DiscountTypeDTO.Value;
}
export function costFromDTO(cost) {
  const discount = discountFromDTO(cost.discount);
  const priceIncrease = priceIncreaseModel.fromDTO(cost.priceIncrease);
  switch (cost.pricingType) {
    case ServiceCostType.Fixed:
      return makers.makeFixedCost(cost.price, {
        discount,
        priceIncrease
      });
    case ServiceCostType.Variable:
      return makers.makeVariableCost(cost.price, {
        discount,
        unitCap: cost.unitCapStr != null ? new Decimal(cost.unitCapStr.replace(/,/g, '')) : null,
        unitName: cost.unitName,
        priceIncrease
      });
    case ServiceCostType.Range:
      return makers.makeRangeCost(cost.maxPrice, {
        discount,
        minPrice: cost.minPrice,
        priceIncrease
      });
  }
}
export function costToDTO(cost) {
  const discount = discountToDTO(cost.discount);
  const priceIncrease = priceIncreaseModel.toDTO(cost.priceIncrease);
  return cost.pricingType === ServiceCostType.Fixed ? {
    pricingType: cost.pricingType,
    price: cost.price.toNumber(),
    discount,
    priceIncrease
  } : cost.pricingType === ServiceCostType.Range ? {
    pricingType: cost.pricingType,
    minPrice: cost.minPrice ? cost.minPrice.toNumber() : null,
    maxPrice: cost.maxPrice.toNumber(),
    discount,
    priceIncrease
  } : {
    pricingType: cost.pricingType,
    unitName: cost.unitName,
    price: cost.price.toNumber(),
    unitCapStr: cost.unitCap ? cost.unitCap.toDP(2).toString() : null,
    discount,
    priceIncrease
  };
}
export function billingFromDTO(dto, isAwaitingApproval) {
  const {
    trigger,
    recurrencePeriod,
    billingDayOfMonth,
    billingDayOfWeek,
    isBilledUpfront,
    billUpfrontState,
    isPaused,
    isProrated,
    maxCharges
  } = dto;
  switch (trigger) {
    case 'onApproval':
    case BillingTriggerType.OnApproval:
      return makers.makeOnApprovalBilling();
    case BillingTriggerType.OneTimeManual:
      return makers.makeOneTimeManualBilling();
    case BillingTriggerType.OneTimeDelivery:
      return makers.makeOneTimeDeliveryBilling();
    case BillingTriggerType.RepeatableManual:
      if (recurrencePeriod !== '' && recurrencePeriod != null) return makers.makeRepeatableManualBilling(recurrencePeriod, {
        billingDayOfMonth,
        billingDayOfWeek,
        isBilledUpfront,
        maxCharges
      });else break;
    case BillingTriggerType.Ongoing:
      if (recurrencePeriod !== '' && recurrencePeriod != null) return makers.makeOngoingBilling(recurrencePeriod, {
        billingDayOfMonth,
        billingDayOfWeek,
        isBilledUpfront,
        billUpfrontState: billUpfrontState != null ? billUpfrontState : undefined,
        isProrated,
        isPaused,
        maxCharges,
        isAwaitingApproval
      });else break;
  }
}
export function billingToDTO(billing) {
  switch (billing.trigger) {
    case BillingTriggerType.OnApproval:
      return {
        trigger: BillingTriggerType.OnApproval,
        billingDayOfMonth: null,
        billingDayOfWeek: null,
        isBilledUpfront: false,
        isPaused: false,
        isProrated: false,
        maxCharges: makers.makeMaxCharges(false),
        recurrencePeriod: ''
      };
    case BillingTriggerType.OneTimeManual:
      return {
        trigger: BillingTriggerType.OneTimeManual,
        billingDayOfMonth: null,
        billingDayOfWeek: null,
        isBilledUpfront: false,
        isPaused: false,
        isProrated: false,
        maxCharges: makers.makeMaxCharges(false),
        recurrencePeriod: ''
      };
    case BillingTriggerType.OneTimeDelivery:
      return {
        trigger: BillingTriggerType.OneTimeDelivery,
        billingDayOfMonth: null,
        billingDayOfWeek: null,
        isBilledUpfront: false,
        isPaused: false,
        isProrated: false,
        maxCharges: makers.makeMaxCharges(false),
        recurrencePeriod: ''
      };
    case BillingTriggerType.Ongoing:
      return {
        trigger: BillingTriggerType.Ongoing,
        isBilledUpfront: billing.isBilledUpfront,
        billUpfrontState: billing.isBilledUpfront ? billing.billUpfrontState : null,
        recurrencePeriod: billing.recurrencePeriod,
        billingDayOfMonth: billing.billingDayOfMonth,
        billingDayOfWeek: billing.billingDayOfWeek,
        isProrated: billing.isProrated,
        isPaused: billing.isPaused,
        maxCharges: billing.maxCharges
      };
    case BillingTriggerType.RepeatableManual:
      return {
        trigger: BillingTriggerType.RepeatableManual,
        billingDayOfMonth: billing.billingDayOfMonth,
        billingDayOfWeek: billing.billingDayOfWeek,
        isBilledUpfront: billing.isBilledUpfront,
        recurrencePeriod: billing.recurrencePeriod,
        maxCharges: billing.maxCharges,
        isPaused: false,
        isProrated: false
      };
  }
}
export function fromDTO(service) {
  const status = statusFromDTO(service.status);
  const billing = billingFromDTO(service.billing, selectors.isAwaitingApproval(status));
  const cost = costFromDTO(service.cost);
  if (billing != null) {
    return makers.makeService(_objectSpread(_objectSpread({}, service), {}, {
      billing,
      cost,
      status,
      participation: !service.participation.type ? makers.makeMandatoryParticipation() : service.participation
    }));
  }
}
export function toDTO(s) {
  const dto = {
    id: s.id,
    name: s.name,
    description: s.description,
    serviceTemplateId: s.serviceTemplateId,
    participation: s.participation,
    isBillable: s.isBillable,
    cost: costToDTO(s.cost),
    billing: billingToDTO(s.billing),
    status: statusToDTO(s.status)
  };
  if (s.integratedClass) {
    dto.integratedClass = s.integratedClass;
  }
  return dto;
}
export function fromServiceTemplate(template) {
  var _context$billingDayOf, _context$billingDayOf2, _context$isPaused, _context$prorateFirst, _context$isBilledUpfr, _context$billingDayOf3, _context$billingDayOf4, _context$isBilledUpfr2;
  let context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    id,
    integratedId,
    name,
    description,
    billing,
    cost
  } = template;
  return makers.makeService({
    name,
    description,
    integratedId: integratedId || undefined,
    serviceTemplateId: id,
    status: makers.makeAwaitingApprovalStatus(),
    cost,
    billing: serviceTemplateModel.isOnApproval(billing) ? makers.makeOnApprovalBilling() : serviceTemplateModel.isOneTimeManual(billing) ? makers.makeOneTimeManualBilling() : serviceTemplateModel.isOneTimeDelivery(billing) ? makers.makeOneTimeDeliveryBilling() : serviceTemplateModel.isOngoing(billing) ? makers.makeOngoingBilling(billing.recurrencePeriod, {
      billingDayOfMonth: (_context$billingDayOf = context.billingDayOfMonth) !== null && _context$billingDayOf !== void 0 ? _context$billingDayOf : billingDayOfMonthModel.makeBillingDayOfMonth(),
      billingDayOfWeek: (_context$billingDayOf2 = context.billingDayOfWeek) !== null && _context$billingDayOf2 !== void 0 ? _context$billingDayOf2 : defaultBillingDayOfWeek,
      isPaused: (_context$isPaused = context.isPaused) !== null && _context$isPaused !== void 0 ? _context$isPaused : false,
      isProrated: (_context$prorateFirst = context.prorateFirstInvoice) !== null && _context$prorateFirst !== void 0 ? _context$prorateFirst : false,
      isBilledUpfront: (_context$isBilledUpfr = context.isBilledUpfront) !== null && _context$isBilledUpfr !== void 0 ? _context$isBilledUpfr : false,
      billUpfrontState: context.isBilledUpfront ? makers.makeBillUpfrontState(context.skipBillingOnAcceptance || !!context.isPaused) : undefined
    }) : makers.makeRepeatableManualBilling(billing.recurrencePeriod, {
      billingDayOfMonth: (_context$billingDayOf3 = context.billingDayOfMonth) !== null && _context$billingDayOf3 !== void 0 ? _context$billingDayOf3 : billingDayOfMonthModel.makeBillingDayOfMonth(),
      billingDayOfWeek: (_context$billingDayOf4 = context.billingDayOfWeek) !== null && _context$billingDayOf4 !== void 0 ? _context$billingDayOf4 : defaultBillingDayOfWeek,
      isBilledUpfront: (_context$isBilledUpfr2 = context.isBilledUpfront) !== null && _context$isBilledUpfr2 !== void 0 ? _context$isBilledUpfr2 : false
    })
  });
}
export function statusFromDTO(dto) {
  if (dto.type === ServiceStatusType.AwaitingApproval) {
    return {
      type: dto.type,
      awaitingApproval: _objectSpread(_objectSpread({}, dto.awaitingApproval), {}, {
        addedOn: timeModel.fromDatestampObject(dto.awaitingApproval.addedOn)
      })
    };
  } else if (dto.type === ServiceStatusType.Amended) {
    return {
      type: dto.type,
      date: timeModel.fromDatestampObject(dto.date),
      amended: {
        amendedOn: timeModel.fromDatestampObject(dto.amended.amendedOn),
        approvalMethod: dto.amended.approvalMethod,
        note: dto.amended.note,
        changes: changesFromDTO(dto.amended.changes)
      }
    };
  } else if (dto.type === ServiceStatusType.Canceled) {
    return {
      type: dto.type
    };
  } else if (dto.type === ServiceStatusType.Completed) {
    const obj = {
      type: dto.type,
      date: timeModel.fromDatestampObject(dto.date),
      completed: {}
    };
    if (dto.completed.changes) {
      obj.completed.changes = changesFromDTO(dto.completed.changes);
    }
    return obj;
  } else {
    return {
      type: dto.type,
      date: timeModel.fromDatestampObject(dto.date)
    };
  }
}
export function statusToDTO(status) {
  if (status.type === ServiceStatusType.AwaitingApproval) {
    return {
      type: status.type,
      awaitingApproval: {
        addedOn: timeModel.toDatestampObject(status.awaitingApproval.addedOn),
        approvalMethod: status.awaitingApproval.approvalMethod || makeManualApprovalMethodDate(),
        note: status.awaitingApproval.note
      }
    };
  } else if (status.type === ServiceStatusType.Amended) {
    return {
      type: status.type,
      date: timeModel.toDatestampObject(status.date),
      amended: {
        amendedOn: timeModel.toDatestampObject(status.amended.amendedOn),
        approvalMethod: status.amended.approvalMethod,
        note: status.amended.note,
        changes: changesToDTO(status.amended.changes)
      }
    };
  } else if (status.type === ServiceStatusType.Canceled) {
    return {
      type: status.type
    };
  } else if (status.type === ServiceStatusType.Completed) {
    const dto = {
      type: status.type,
      date: timeModel.toDatestampObject(status.date),
      completed: {}
    };
    if (status.completed.changes) {
      dto.completed.changes = changesToDTO(status.completed.changes);
    }
    return dto;
  } else {
    return {
      type: status.type,
      date: timeModel.toDatestampObject(status.date)
    };
  }
}
export function toDBService(service) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    cost
  } = service;
  return {
    id: service.id,
    name: service.name,
    description: service.description,
    billedUpon: toDBServiceBillingTrigger(service.billing),
    cost: {
      pricingType: selectors.isFixedPrice(cost) ? {
        type: cost.pricingType,
        fixed: {
          price: utils.dollarsToCents(cost.price)
        },
        variable: null,
        range: null
      } : selectors.isVariablePrice(cost) ? {
        type: cost.pricingType,
        fixed: null,
        variable: {
          unitPrice: utils.dollarsToCents(cost.price),
          cap: cost.unitCap != null ? utils.dollarsToCents(cost.unitCap) : options.forceZeroCap ? 0 : null,
          name: cost.unitName
        },
        range: null
      } : {
        type: cost.pricingType,
        fixed: null,
        variable: null,
        range: {
          minPrice: cost.minPrice && utils.dollarsToCents(cost.minPrice),
          maxPrice: utils.dollarsToCents(cost.maxPrice)
        }
      },
      discount: service.cost.discount != null ? {
        type: service.cost.discount.type,
        decimal: utils.dollarsToCents(service.cost.discount.amount)
      } : null,
      priceIncrease: priceIncreaseModel.toDB(cost.priceIncrease)
    },
    note: options.note || null,
    status: selectors.isTerminated(service.status) || selectors.isCanceled(service.status) ? service.status.type : undefined,
    serviceTemplateId: service.serviceTemplateId,
    participation: !service.participation.type ? makers.makeMandatoryParticipation() : service.participation
  };
}
function changesFromDTO(changesDTO) {
  return _objectSpread(_objectSpread({}, changesDTO), {}, {
    billing: changesDTO.billing && billingFromDTO(changesDTO.billing) || null,
    cost: changesDTO.cost && costFromDTO(changesDTO.cost)
  });
}
function changesToDTO(changes) {
  return _objectSpread(_objectSpread({}, changes), {}, {
    cost: changes.cost ? costToDTO(changes.cost) : null,
    billing: changes.billing ? billingToDTO(changes.billing) : null
  });
}
export function toDBServiceBillingTrigger(billing) {
  switch (billing.trigger) {
    case BillingTriggerType.OnApproval:
      return {
        type: BillingTriggerType.OnApproval
      };
    case BillingTriggerType.OneTimeManual:
      return {
        type: BillingTriggerType.OneTimeManual
      };
    case BillingTriggerType.OneTimeDelivery:
      return {
        type: BillingTriggerType.OneTimeDelivery
      };
    case BillingTriggerType.Ongoing:
      return {
        type: BillingTriggerType.Ongoing,
        ongoing: {
          billUpfront: billing.isBilledUpfront,
          billUpfrontState: billing.billUpfrontState,
          isPaused: billing.isPaused,
          recurrence: toDBRecurrenceData(billing),
          prorateFirstInvoice: billing.isProrated,
          maxCharges: {
            enabled: selectors.maxChargesEnabled({
              billing
            }),
            limit: selectors.maxChargesLimit({
              billing
            })
          }
        }
      };
    case BillingTriggerType.RepeatableManual:
      return {
        type: BillingTriggerType.RepeatableManual,
        repeatableManual: {
          billUpfront: billing.isBilledUpfront,
          recurrence: toDBRecurrenceData(billing),
          maxCharges: {
            enabled: selectors.maxChargesEnabled({
              billing
            }),
            limit: selectors.maxChargesLimit({
              billing
            })
          }
        }
      };
  }
}
function toDBRecurrenceData(billing) {
  const {
    recurrencePeriod
  } = billing;
  const billingDayOfMonth = billing.billingDayOfMonth || billingDayOfMonthModel.makeBillingDayOfMonth();
  const billingDayOfWeek = billing.billingDayOfWeek || defaultBillingDayOfWeek;
  return {
    period: {
      type: recurrencePeriod,
      monthly: recurrencePeriod === RecurrencePeriodTypes.Monthly ? {
        dayOfMonth: billingDayOfMonthModel.toGoBillingDayOfMonth(billingDayOfMonth)
      } : null,
      weekly: recurrencePeriod === RecurrencePeriodTypes.Weekly ? {
        day: billingDayOfWeek
      } : null,
      biweekly: recurrencePeriod === RecurrencePeriodTypes.BiWeekly ? {
        day: billingDayOfWeek
      } : null
    }
  };
}