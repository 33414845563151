import flattenDeep from 'lodash/flattenDeep';
import uniqBy from 'lodash/uniqBy';
import xor from 'lodash/xor';
import { getUSPhoneNumberValidator, usPhoneNumberError } from 'validators';
import { parseCsv, validateCsvColumns, addCsvLineNumbers, validateCsvRow, createErrorObject } from "../../components/ImportWizard/ImportWizard.utils";
import { contactModel } from "../../models";
export const nonUniqueContactError = 'A client with the same email address and company name already exists';
function validateField(valid) {
  return valid === true ? {
    valid: true
  } : {
    valid: false,
    errorType: typeof valid === 'string' ? valid : undefined
  };
}
const contactsCsvColumnConfig = {
  ['first name']: {
    inputName: 'firstName',
    validate: firstName => validateField(contactModel.validateFirstName(firstName)),
    validateError: (headerName, rowNumber, errorType) => {
      return createErrorObject(headerName, rowNumber, errorType || 'First name is invalid');
    }
  },
  ['last name']: {
    inputName: 'lastName',
    validate: lastName => validateField(contactModel.validateLastName(lastName)),
    validateError: (headerName, rowNumber, errorType) => {
      return createErrorObject(headerName, rowNumber, errorType || 'Last name is invalid');
    }
  },
  ['company name']: {
    inputName: 'companyName',
    validate: companyName => validateField(contactModel.validateCompanyName(companyName)),
    validateError: (headerName, rowNumber, errorType) => {
      return createErrorObject(headerName, rowNumber, errorType || 'Company name is invalid');
    }
  },
  ['email']: {
    inputName: 'email',
    validate: email => validateField(contactModel.validateEmail(email)),
    validateError: (headerName, rowNumber, errorType) => {
      return createErrorObject(headerName, rowNumber, errorType || 'Email is invalid');
    }
  },
  ['phone number']: {
    inputName: 'phone',
    validate: phone => !phone || getUSPhoneNumberValidator()('+1' + phone) === true,
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, usPhoneNumberError)
  }
};
function createContacts(parsedCsv) {
  return parsedCsv.map(row => ({
    firstName: row.firstName,
    lastName: row.lastName,
    companyName: row.companyName,
    email: row.email,
    phone: row.phone ? '+1' + row.phone : null,
    isSample: false
  }));
}
function runValidation(data, existingContacts) {
  if (!data.length) throw new Error('No data');
  validateCsvColumns(data, contactsCsvColumnConfig);
  const withLineNumbers = addCsvLineNumbers(data);
  const errors = withLineNumbers.map(rowObject => validateCsvRow(rowObject, contactsCsvColumnConfig));
  const dataForUniquenessCheck = withLineNumbers.map(c => ({
    companyName: c.companyName,
    email: c.email,
    lineNumber: c.lineNumber
  }));
  const nonUniqueContactsByName = xor(dataForUniquenessCheck, uniqBy(existingContacts.map(c => ({
    companyName: c.companyName,
    email: c.email,
    lineNumber: -1
  })).concat(dataForUniquenessCheck), contactModel.uniqueKey)).filter(s => s.lineNumber !== -1);
  if (nonUniqueContactsByName.length) {
    nonUniqueContactsByName.forEach(rowObject => errors.push([createErrorObject('company name / email', rowObject.lineNumber, nonUniqueContactError)]));
  }
  return flattenDeep(errors);
}
function tryToParseError(error, or) {
  try {
    return JSON.parse(error);
  } catch (e) {
    return or;
  }
}
export const parseAndValidateContactsCsv = async (data, existingContacts) => {
  try {
    const parsedData = await parseCsv(data, contactsCsvColumnConfig);
    const errors = runValidation(parsedData, existingContacts);
    if (!errors.length) {
      return {
        items: createContacts(parsedData),
        errors
      };
    } else {
      return {
        items: [],
        errors
      };
    }
  } catch (exn) {
    var _tryToParseError;
    return {
      items: [],
      errors: [{
        message: '' + exn,
        rowNumber: (exn === null || exn === void 0 ? void 0 : exn.message) != null ? (_tryToParseError = tryToParseError(exn.message, 0)) === null || _tryToParseError === void 0 ? void 0 : _tryToParseError.lineNumber : 0
      }]
    };
  }
};