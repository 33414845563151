import * as Sentry from '@sentry/react';
import { ErrorResponse } from "./ErrorResponse";
import Decimal from 'decimal.js';
import pluralize from 'pluralize';
import groupBy from 'lodash/groupBy';
import filter from 'lodash/filter';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import { Base64 } from 'js-base64';
export function notEmpty(value) {
  return value != null;
}
export function netTermsToString(netTerms) {
  return netTerms === 0 ? 'Payment on receipt of invoice' : "".concat(numberAndUnit(netTerms, 'day'));
}
export function downloadFromBlob(blob, filename) {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
export function sortAlphabetically(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
arr, key) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return arr.sort((a, b) => {
    const aKey = options.ignoreCase ? a[key].toLowerCase() : a[key];
    const bKey = options.ignoreCase ? b[key].toLowerCase() : b[key];
    if (aKey < bKey) return options.ascending ? -1 : 1;
    if (aKey > bKey) return options.ascending ? 1 : -1;
    return 0;
  });
}
export function getUserInitials(userInfo) {
  const {
    firstName,
    lastName,
    email
  } = userInfo;
  const initials = firstName && lastName ? firstName[0] + lastName[0] : email ? email.substring(0, 2) : '';
  return initials.toUpperCase();
}
export function isNonEmptyInput(value) {
  return !!(value || '').trim().length;
}
export function sendErrReport(err) {
  let errData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  let errObjectToReport;
  if (err instanceof Error) {
    errObjectToReport = err;
  } else if (typeof err === 'string') {
    errObjectToReport = new Error(err);
  } else if (typeof err === 'object') {
    errObjectToReport = new Error(JSON.stringify(err));
  } else if (typeof err === 'undefined') {
    errObjectToReport = new Error('no error message was provided');
  } else {
    errObjectToReport = new Error('' + err);
  }
  console.error("".concat(errObjectToReport.toString(), "\n").concat(errObjectToReport.stack), errData);
  Sentry.captureException(errObjectToReport);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any

export function makeSuccess(data) {
  return {
    success: true,
    data,
    error: undefined
  };
}
export function makeFailure(error) {
  return {
    success: false,
    data: undefined,
    error
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any

/**
 * @description ### Resovle async response with error handling in order to make multiple async-await actions more predictable
 *
 * - Example response [ data, undefined ]
 * - Example response [ undefined, Error ]
 *
 *
 * When used with Promise.all([req1, req2, req3])
 * - Example response [ [data1, data2, data3], undefined ]
 * - Example response [ undefined, Error ]
 *
 *
 * When used with Promise.race([req1, req2, req3])
 * - Example response [ data, undefined ]
 * - Example response [ undefined, Error ]
 *
 * @param {Promise} promise
 * @returns {Promise} [ data, undefined ]
 * @returns {Promise} [ undefined, Error ]
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handlePromise = promise => {
  return promise.then(makeSuccess).catch(makeFailure);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleRequest = request => handlePromise(request.catch(e => {
  throw new ErrorResponse(e);
}));

/** Call this in the `default` case of a `switch` with the switched-on value to assert that all cases of a union type are handled. */
export function assertUnreachable(x) {
  throw new Error("Didn't expect to get here: ".concat(x));
}
const ordinalRules = new Intl.PluralRules('en', {
  type: 'ordinal'
});
const suffixes = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th'
};

/**
 * Returns the formatted ordinal for the number.
 * @example
 * ordinal(1) => '1st'
 * ordinal(2) => '2nd'
 * ordinal(3) => '3rd'
 * ordinal(4) => '4th'
 */
export const ordinal = number => number + suffixes[ordinalRules.select(number)];

/** Returns a Promise that resolves after the specified amount of time. */
export const wait = time => new Promise(r => setTimeout(r, time));
export function isObject(obj) {
  return typeof obj === 'object' && !!obj;
}
export function scrollIntoView(element) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  element.scrollIntoView({
    behavior: options.disableScrollAnimation ? 'instant' : 'smooth',
    block: options.block || 'center'
  });
}
export function getHelpArticle(name) {
  return "https://help.sayanchor.com/en/articles/".concat(name);
}
export function getIntercomHelpArticle(name) {
  return "https://intercom.help/anchor-billing/en/articles/".concat(name);
}
export function numberAndUnit(number, unit) {
  return "".concat(number, " ").concat(pluralize(unit, number));
}
export function ignoreError(fn) {
  try {
    return fn();
  } catch (_unused) {
    return null;
  }
}
export function decimalFromStr(numberAsString) {
  if (!numberAsString) return null;
  const number = +numberAsString;
  if (Number.isNaN(number)) return null;
  return new Decimal(number);
}
export async function uploadFileToCloudStorage(url, file, headers) {
  const uploadResponse = await fetch(url.replace(/"/g, ''), {
    method: 'PUT',
    body: file,
    headers
  });
  if (uploadResponse.status > 399) {
    throw new Error('uploadFileFailed');
  }
}

/** Returns items in the array that have duplicates in the array. */
export function findDuplicates(arr) {
  const grouped = groupBy(arr, n => n);
  return uniq(flatten(filter(grouped, n => n.length > 1)));
}
export function fullName(firstName, lastName) {
  return !firstName ? null : !lastName ? firstName : "".concat(firstName, " ").concat(lastName);
}
export function extractClaimsFromToken(authToken) {
  if (!authToken) {
    return null;
  }
  const payload = authToken.split('.', 3)[1];
  if (!payload) {
    return null;
  }
  try {
    const maybeClaims = JSON.parse(Base64.decode(payload));
    if (isObject(maybeClaims) && ['email', 'firstName', 'lastName'].every(key => key in maybeClaims)) {
      return maybeClaims;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}