import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { returnToStorageKey, routes } from "../constants";
export function RedirectPage() {
  const history = useHistory();
  useEffect(() => {
    const returnTo = localStorage.getItem(returnToStorageKey);
    if (returnTo) {
      localStorage.removeItem(returnToStorageKey);
      history.replace(returnTo);
    } else {
      history.replace(routes.home);
    }
  }, []);
  return null;
}