// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Slate = require("slate");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_module = require("rescript/lib/js/caml_module.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Js_undefined = require("rescript/lib/js/js_undefined.js");
function text(param) {
  return param.text;
}
function bold(param) {
  return Belt_Option.getWithDefault(Caml_option.undefined_to_opt(param.bold), false);
}
function italic(param) {
  return Belt_Option.getWithDefault(Caml_option.undefined_to_opt(param.italic), false);
}
function underline(param) {
  return Belt_Option.getWithDefault(Caml_option.undefined_to_opt(param.underline), false);
}
function strikethrough(param) {
  return Belt_Option.getWithDefault(Caml_option.undefined_to_opt(param.strikethrough), false);
}
function make(text, bold, italic, underline, strikethrough, param) {
  return {
    text: text,
    bold: Js_undefined.fromOption(bold),
    italic: Js_undefined.fromOption(italic),
    underline: Js_undefined.fromOption(underline),
    strikethrough: Js_undefined.fromOption(strikethrough)
  };
}
var $$Text = {
  text: text,
  bold: bold,
  italic: italic,
  underline: underline,
  strikethrough: strikethrough,
  make: make
};
function bold$1(param) {
  return Belt_Option.getWithDefault(Caml_option.undefined_to_opt(param.bold), false);
}
function italic$1(param) {
  return Belt_Option.getWithDefault(Caml_option.undefined_to_opt(param.italic), false);
}
function underline$1(param) {
  return Belt_Option.getWithDefault(Caml_option.undefined_to_opt(param.underline), false);
}
function strikethrough$1(param) {
  return Belt_Option.getWithDefault(Caml_option.undefined_to_opt(param.strikethrough), false);
}
var Marks = {
  bold: bold$1,
  italic: italic$1,
  underline: underline$1,
  strikethrough: strikethrough$1
};
function isFirstChild(path) {
  var length = path.length;
  if (length > 0) {
    return Belt_Option.mapWithDefault(Belt_Array.get(path, length - 1 | 0), false, function (index) {
      return index === 0;
    });
  } else {
    return false;
  }
}
function isDescendant(path, of_) {
  return Slate.Path.isDescendant(path, of_);
}
var Path = {
  isFirstChild: isFirstChild,
  isDescendant: isDescendant
};
function path(param) {
  return param.path;
}
function offset(param) {
  return param.offset;
}
function isAtRoot(point) {
  return point.path.length === 2;
}
var Point = {
  path: path,
  offset: offset,
  isAtRoot: isAtRoot
};
function anchor(param) {
  return param.anchor;
}
function focus(param) {
  return param.focus;
}
function isAtRoot$1(range) {
  if (isAtRoot(range.anchor)) {
    return true;
  } else {
    return isAtRoot(range.focus);
  }
}
var $$Range = {
  anchor: anchor,
  focus: focus,
  isAtRoot: isAtRoot$1
};
function path$1(path$2) {
  return path$2;
}
function point(point$1) {
  return point$1;
}
function range(range$1) {
  return range$1;
}
function classify($$location) {
  if (Slate.Path.isPath($$location)) {
    return {
      TAG: /* Path */0,
      _0: $$location
    };
  } else if (Slate.Point.isPoint($$location)) {
    return {
      TAG: /* Point */1,
      _0: $$location
    };
  } else {
    return {
      TAG: /* Range */2,
      _0: $$location
    };
  }
}
var $$Location = {
  path: path$1,
  point: point,
  range: range,
  classify: classify
};
var Span = {};
function $$location($$location$1) {
  return $$location$1;
}
function span(span$1) {
  return span$1;
}
function classify$1(at) {
  if (Slate.Location.isLocation(at)) {
    return {
      TAG: /* Location */0,
      _0: at
    };
  } else {
    return {
      TAG: /* Span */1,
      _0: at
    };
  }
}
var At = {
  $$location: $$location,
  span: span,
  classify: classify$1
};
var Operation = {};
function current(param) {
  return Caml_option.null_to_opt(param.current);
}
function affinity(param) {
  return Caml_option.null_to_opt(param.affinity);
}
function unref(param) {
  return Curry._1(param.unref, undefined);
}
function transform(pointRef, op) {
  return Slate.PointRef.transform(pointRef, op);
}
var PointRef = {
  current: current,
  affinity: affinity,
  unref: unref,
  transform: transform
};
function current$1(param) {
  return Caml_option.null_to_opt(param.current);
}
function affinity$1(param) {
  return Caml_option.null_to_opt(param.affinity);
}
function unref$1(param) {
  return Caml_option.null_to_opt(Curry._1(param.unref, undefined));
}
function transform$1(pathRef, op) {
  return Slate.PathRef.transform(pathRef, op);
}
var PathRef = {
  current: current$1,
  affinity: affinity$1,
  unref: unref$1,
  transform: transform$1
};
var $$Element = Caml_module.init_mod(["Slate.res", 241, 4], {
  TAG: /* Module */0,
  _0: [[/* Function */0, "typToJs"], [/* Function */0, "typFromJs"], [/* Function */0, "make"], [/* Function */0, "children"], [/* Function */0, "isList"], [/* Function */0, "typ"], [/* Function */0, "url"], [/* Function */0, "isElement"]]
});
var $$Node = Caml_module.init_mod(["Slate.res", 314, 4], {
  TAG: /* Module */0,
  _0: [[/* Function */0, "text"], [/* Function */0, "element"], [/* Function */0, "editor"], [/* Function */0, "classify"], [/* Function */0, "string"], [/* Function */0, "children"], [/* Function */0, "getNextSiblingNodes"]]
});
var Descendant = Caml_module.init_mod(["Slate.res", 377, 4], {
  TAG: /* Module */0,
  _0: [[/* Function */0, "text"], [/* Function */0, "element"], [/* Function */0, "classify"]]
});
var Ancestor = Caml_module.init_mod(["Slate.res", 413, 4], {
  TAG: /* Module */0,
  _0: [[/* Function */0, "element"], [/* Function */0, "editor"], [/* Function */0, "classify"], [/* Function */0, "string"], [/* Function */0, "has"], [/* Function */0, "isEmpty"]]
});
var Editor = Caml_module.init_mod(["Slate.res", 546, 4], {
  TAG: /* Module */0,
  _0: [[/* Function */0, "children"], [/* Function */0, "selection"], [{
    TAG: /* Module */0,
    _0: []
  }, "NodesOptions"], [/* Function */0, "marks"], [/* Function */0, "node"], [/* Function */0, "nodes"], [/* Function */0, "addMark"], [/* Function */0, "removeMark"], [/* Function */0, "unhangRange"], [/* Function */0, "isEditor"], [/* Function */0, "isInline"], [/* Function */0, "isStart"], [/* Function */0, "isSelectionAtStartOfBlock"], [/* Function */0, "getNodesByType"], [/* Function */0, "isNodeOfType"], [/* Function */0, "parent"], [/* Function */0, "above"], [/* Function */0, "getBlockAbove"], [/* Function */0, "isBlockAboveEmpty"], [/* Function */0, "isBlockEmptyAfterSelection"], [/* Function */0, "getAboveByType"], [/* Function */0, "withoutNormalizing"], [/* Function */0, "pointRef"], [/* Function */0, "pathRef"], [/* Function */0, "normalize"], [/* Function */0, "make"]]
});
var _map = {
  "p": "p",
  "ol": "ol",
  "ul": "ul",
  "li": "li",
  "h1": "h1",
  "h2": "h2",
  "h3": "h3",
  "h4": "h4",
  "h5": "h5",
  "h6": "h6",
  "a": "a"
};
function typToJs(param) {
  return param;
}
function typFromJs(param) {
  return _map[param];
}
function make$1(typ, children, url, param) {
  return {
    typ: typ,
    children: children,
    url: Js_undefined.fromOption(url)
  };
}
function children(param) {
  return param.children;
}
function isList(typ) {
  if (typ === "ul") {
    return true;
  } else {
    return typ === "ol";
  }
}
function typ(param) {
  return param.typ;
}
function url(param) {
  return Caml_option.undefined_to_opt(param.url);
}
Caml_module.update_mod({
  TAG: /* Module */0,
  _0: [[/* Function */0, "typToJs"], [/* Function */0, "typFromJs"], [/* Function */0, "make"], [/* Function */0, "children"], [/* Function */0, "isList"], [/* Function */0, "typ"], [/* Function */0, "url"], [/* Function */0, "isElement"]]
}, $$Element, {
  typToJs: typToJs,
  typFromJs: typFromJs,
  make: make$1,
  children: children,
  isList: isList,
  typ: typ,
  url: url,
  isElement: function isElement(prim) {
    return Slate.Element.isElement(prim);
  }
});
function text$1(text$2) {
  return text$2;
}
function element(element$1) {
  return element$1;
}
function editor(editor$1) {
  return editor$1;
}
function classify$2(n) {
  if (Slate.Text.isText(n)) {
    return {
      TAG: /* Text */0,
      _0: n
    };
  } else if (Curry._1($$Element.isElement, n)) {
    return {
      TAG: /* Element */1,
      _0: n
    };
  } else {
    return {
      TAG: /* Editor */2,
      _0: n
    };
  }
}
function children$1(node) {
  var element = classify$2(node);
  switch (element.TAG | 0) {
    case /* Text */0:
      return;
    case /* Element */1:
      return Curry._1($$Element.children, element._0);
    case /* Editor */2:
      return Curry._1(Editor.children, element._0);
  }
}
function getNextSiblingNodes(entry, path) {
  var leafIndex = Belt_Array.get(path, entry[1].length);
  var match = Curry._1($$Node.children, entry[0]);
  if (leafIndex !== undefined) {
    if (match !== undefined) {
      if ((leafIndex + 1 | 0) < match.length) {
        return Belt_Array.sliceToEnd(match, leafIndex + 1 | 0);
      } else {
        return [];
      }
    } else {
      return [];
    }
  } else {
    return [];
  }
}
Caml_module.update_mod({
  TAG: /* Module */0,
  _0: [[/* Function */0, "text"], [/* Function */0, "element"], [/* Function */0, "editor"], [/* Function */0, "classify"], [/* Function */0, "string"], [/* Function */0, "children"], [/* Function */0, "getNextSiblingNodes"]]
}, $$Node, {
  text: text$1,
  element: element,
  editor: editor,
  classify: classify$2,
  string: function string(prim) {
    return Slate.Node.string(prim);
  },
  children: children$1,
  getNextSiblingNodes: getNextSiblingNodes
});
function text$2(text$3) {
  return text$3;
}
function element$1(element$2) {
  return element$2;
}
function classify$3(n) {
  if (Slate.Text.isText(n)) {
    return {
      TAG: /* Text */0,
      _0: n
    };
  } else {
    return {
      TAG: /* Element */1,
      _0: n
    };
  }
}
Caml_module.update_mod({
  TAG: /* Module */0,
  _0: [[/* Function */0, "text"], [/* Function */0, "element"], [/* Function */0, "classify"]]
}, Descendant, {
  text: text$2,
  element: element$1,
  classify: classify$3
});
function element$2(element$3) {
  return element$3;
}
function editor$1(editor$2) {
  return editor$2;
}
function classify$4(n) {
  if (Curry._1($$Element.isElement, n)) {
    return {
      TAG: /* Element */0,
      _0: n
    };
  } else {
    return {
      TAG: /* Editor */1,
      _0: n
    };
  }
}
function isEmpty(editor, ancestor) {
  if (Curry._1($$Node.string, ancestor).length === 0) {
    return true;
  } else {
    return Belt_Option.mapWithDefault(Curry._1($$Node.children, ancestor), true, function (children) {
      return Belt_Array.some(children, function (node) {
        var element = Curry._1($$Node.classify, node);
        switch (element.TAG | 0) {
          case /* Text */0:
            return true;
          case /* Element */1:
            return Curry._2(Editor.isInline, editor, element._0);
          case /* Editor */2:
            return false;
        }
      });
    });
  }
}
function has(node, path) {
  return Slate.Node.has(node, path);
}
Caml_module.update_mod({
  TAG: /* Module */0,
  _0: [[/* Function */0, "element"], [/* Function */0, "editor"], [/* Function */0, "classify"], [/* Function */0, "string"], [/* Function */0, "has"], [/* Function */0, "isEmpty"]]
}, Ancestor, {
  element: element$2,
  editor: editor$1,
  classify: classify$4,
  string: $$Node.string,
  has: has,
  isEmpty: isEmpty
});
var _map$1 = {
  "start": "start",
  "end_": "end"
};
function edgeToJs(param) {
  return _map$1[param];
}
function children$2(param) {
  return param.children;
}
function selection(param) {
  return Caml_option.null_to_opt(param.selection);
}
function make$2(at, match_, mode, universal, reverse, voids, param) {
  return {
    at: Js_undefined.fromOption(at),
    match: Js_undefined.fromOption(match_),
    mode: Js_undefined.fromOption(mode),
    universal: Js_undefined.fromOption(universal),
    reverse: Js_undefined.fromOption(reverse),
    voids: Js_undefined.fromOption(voids)
  };
}
function nodes(editor, at, match_, mode, universal, reverse, voids, param) {
  return Array.from(Slate.Editor.nodes(editor, make$2(at, match_, mode, universal, reverse, voids, undefined)));
}
function make$3(depth, edge, param) {
  return {
    depth: Js_undefined.fromOption(depth),
    edge: Js_undefined.fromOption(Belt_Option.map(edge, edgeToJs))
  };
}
function node(editor, at, depth, edge, param) {
  return Slate.Editor.node(editor, at, make$3(depth, edge, undefined));
}
function addMark(editor, style, value) {
  Slate.Editor.addMark(editor, style, value);
}
function removeMark(editor, style) {
  Slate.Editor.removeMark(editor, style);
}
function unhangRange(editor, range, voids, param) {
  return Slate.Editor.unhangRange(editor, range, {
    voids: Js_undefined.fromOption(voids)
  });
}
function getNodesByType(editor, types, at, mode, universal, reverse, voids, param) {
  var match = Belt_Option.map(at, classify$1);
  var $$location$1;
  if (match !== undefined) {
    if (match.TAG === /* Location */0) {
      var range$1 = classify(match._0);
      switch (range$1.TAG | 0) {
        case /* Path */0:
        case /* Point */1:
          $$location$1 = at;
          break;
        case /* Range */2:
          $$location$1 = Caml_option.some(unhangRange(editor, range$1._0, undefined, undefined));
          break;
      }
    } else {
      $$location$1 = at;
    }
  } else {
    $$location$1 = Belt_Option.map(Belt_Option.map(Belt_Option.map(selection(editor), function (range) {
      return unhangRange(editor, range, undefined, undefined);
    }), range), $$location);
  }
  return nodes(editor, $$location$1, function (node) {
    return Belt_Array.some(types, function (typ) {
      var element = Curry._1($$Node.classify, node);
      switch (element.TAG | 0) {
        case /* Element */1:
          return typ === Curry._1($$Element.typ, element._0);
        case /* Text */0:
        case /* Editor */2:
          return false;
      }
    });
  }, mode, universal, reverse, voids, undefined);
}
function isNodeOfType(editor, types) {
  return getNodesByType(editor, types, undefined, undefined, undefined, undefined, undefined, undefined).length !== 0;
}
function isInline(editor, element) {
  return Slate.Editor.isInline(editor, element);
}
function isStart(editor, point, at) {
  return Slate.Editor.isStart(editor, point, at);
}
function parent(editor, at, depth, edge, param) {
  return Slate.Editor.parent(editor, at, make$3(depth, edge, undefined));
}
function make$4(at, match_, mode, voids, param) {
  return {
    at: Js_undefined.fromOption(at),
    match: Js_undefined.fromOption(match_),
    mode: Js_undefined.fromOption(mode),
    voids: Js_undefined.fromOption(voids)
  };
}
function above(editor, at, match_, mode, voids, param) {
  return Caml_option.undefined_to_opt(Slate.Editor.above(editor, make$4(at, match_, mode, voids, undefined)));
}
function getBlockAbove(editor, at, mode, voids, param) {
  return Belt_Option.getWithDefault(above(editor, at, function (node) {
    return Slate.Editor.isBlock(editor, node);
  }, mode, voids, undefined), [Curry._1(Ancestor.editor, editor), []]);
}
function isBlockAboveEmpty(editor) {
  var match = getBlockAbove(editor, undefined, undefined, undefined, undefined);
  return Curry._2(Ancestor.isEmpty, editor, match[0]);
}
function isSelectionAtStartOfBlock(editor) {
  return Belt_Option.mapWithDefault(Belt_Option.map(selection(editor), focus), false, function (point) {
    var match = getBlockAbove(editor, undefined, undefined, undefined, undefined);
    return Slate.Editor.isStart(editor, point, match[1]);
  });
}
function isBlockEmptyAfterSelection(editor) {
  var range = selection(editor);
  if (range === undefined) {
    return false;
  }
  var cursor = focus(range);
  if (!Slate.Editor.isEnd(editor, cursor, parent(editor, range, undefined, undefined, undefined)[1])) {
    return false;
  }
  var blockAbove = getBlockAbove(editor, undefined, undefined, undefined, undefined);
  var siblingNodes = Curry._2($$Node.getNextSiblingNodes, blockAbove, path(cursor));
  if (siblingNodes.length !== 0) {
    return !Belt_Array.some(siblingNodes, function (node) {
      var match = Curry._1($$Node.classify, node);
      switch (match.TAG | 0) {
        case /* Text */0:
          return true;
        case /* Element */1:
        case /* Editor */2:
          return false;
      }
    });
  } else {
    return Slate.Editor.isEnd(editor, cursor, blockAbove[1]);
  }
}
function getAboveByType(editor, typ, at, mode, voids, param) {
  return above(editor, at, function (node) {
    var element = Curry._1($$Node.classify, node);
    switch (element.TAG | 0) {
      case /* Element */1:
        return typ === Curry._1($$Element.typ, element._0);
      case /* Text */0:
      case /* Editor */2:
        return false;
    }
  }, mode, voids, undefined);
}
function pointRef(editor, point) {
  return Slate.Editor.pointRef(editor, point);
}
function pathRef(editor, path) {
  return Slate.Editor.pathRef(editor, path);
}
function normalize(editor, force, param) {
  Slate.Editor.normalize(editor, {
    force: Js_undefined.fromOption(force)
  });
}
Caml_module.update_mod({
  TAG: /* Module */0,
  _0: [[/* Function */0, "children"], [/* Function */0, "selection"], [{
    TAG: /* Module */0,
    _0: []
  }, "NodesOptions"], [/* Function */0, "marks"], [/* Function */0, "node"], [/* Function */0, "nodes"], [/* Function */0, "addMark"], [/* Function */0, "removeMark"], [/* Function */0, "unhangRange"], [/* Function */0, "isEditor"], [/* Function */0, "isInline"], [/* Function */0, "isStart"], [/* Function */0, "isSelectionAtStartOfBlock"], [/* Function */0, "getNodesByType"], [/* Function */0, "isNodeOfType"], [/* Function */0, "parent"], [/* Function */0, "above"], [/* Function */0, "getBlockAbove"], [/* Function */0, "isBlockAboveEmpty"], [/* Function */0, "isBlockEmptyAfterSelection"], [/* Function */0, "getAboveByType"], [/* Function */0, "withoutNormalizing"], [/* Function */0, "pointRef"], [/* Function */0, "pathRef"], [/* Function */0, "normalize"], [/* Function */0, "make"]]
}, Editor, {
  children: children$2,
  selection: selection,
  NodesOptions: {},
  marks: function marks(prim) {
    return Caml_option.nullable_to_opt(Slate.Editor.marks(prim));
  },
  node: node,
  nodes: nodes,
  addMark: addMark,
  removeMark: removeMark,
  unhangRange: unhangRange,
  isEditor: function isEditor(prim) {
    return Slate.Editor.isEditor(prim);
  },
  isInline: isInline,
  isStart: isStart,
  isSelectionAtStartOfBlock: isSelectionAtStartOfBlock,
  getNodesByType: getNodesByType,
  isNodeOfType: isNodeOfType,
  parent: parent,
  above: above,
  getBlockAbove: getBlockAbove,
  isBlockAboveEmpty: isBlockAboveEmpty,
  isBlockEmptyAfterSelection: isBlockEmptyAfterSelection,
  getAboveByType: getAboveByType,
  withoutNormalizing: function withoutNormalizing(prim0, prim1) {
    Slate.Editor.withoutNormalizing(prim0, prim1);
  },
  pointRef: pointRef,
  pathRef: pathRef,
  normalize: normalize,
  make: function make(prim) {
    return Slate.createEditor();
  }
});
function fromJson(json) {
  try {
    Json_decode.either(function (param) {
      return Json_decode.field("text", Json_decode.string, param);
    }, function (param) {
      return Json_decode.field("typ", Json_decode.string, param);
    })(json);
    return json;
  } catch (exn) {
    throw {
      RE_EXN_ID: Json_decode.DecodeError,
      _1: "not a Slate element",
      Error: new Error()
    };
  }
}
function make$5(childrenOpt, param) {
  var children = childrenOpt !== undefined ? childrenOpt : [];
  return Curry._4($$Element.make, "p", children, undefined, undefined);
}
var ParagraphElement = {
  make: make$5
};
function make$6(childrenOpt, param) {
  var children = childrenOpt !== undefined ? childrenOpt : [];
  return Curry._4($$Element.make, "ol", children, undefined, undefined);
}
var NumberedListElement = {
  make: make$6
};
function make$7(childrenOpt, param) {
  var children = childrenOpt !== undefined ? childrenOpt : [];
  return Curry._4($$Element.make, "ul", children, undefined, undefined);
}
var BulletsListElement = {
  make: make$7
};
function make$8(childrenOpt, param) {
  var children = childrenOpt !== undefined ? childrenOpt : [];
  return Curry._4($$Element.make, "li", children, undefined, undefined);
}
var ListItemElement = {
  make: make$8
};
function make$9(url, childrenOpt, param) {
  var children = childrenOpt !== undefined ? childrenOpt : [];
  return Curry._4($$Element.make, "a", children, url, undefined);
}
var LinkElement = {
  make: make$9
};
function make$10(childrenOpt, param) {
  var children = childrenOpt !== undefined ? childrenOpt : [];
  return Curry._4($$Element.make, "h1", children, undefined, undefined);
}
var HeadingOneElement = {
  make: make$10
};
function make$11(childrenOpt, param) {
  var children = childrenOpt !== undefined ? childrenOpt : [];
  return Curry._4($$Element.make, "h2", children, undefined, undefined);
}
var HeadingTwoElement = {
  make: make$11
};
function make$12(childrenOpt, param) {
  var children = childrenOpt !== undefined ? childrenOpt : [];
  return Curry._4($$Element.make, "h3", children, undefined, undefined);
}
var HeadingThreeElement = {
  make: make$12
};
function make$13(childrenOpt, param) {
  var children = childrenOpt !== undefined ? childrenOpt : [];
  return Curry._4($$Element.make, "h4", children, undefined, undefined);
}
var HeadingFourElement = {
  make: make$13
};
function make$14(childrenOpt, param) {
  var children = childrenOpt !== undefined ? childrenOpt : [];
  return Curry._4($$Element.make, "h5", children, undefined, undefined);
}
var HeadingFiveElement = {
  make: make$14
};
function make$15(childrenOpt, param) {
  var children = childrenOpt !== undefined ? childrenOpt : [];
  return Curry._4($$Element.make, "h6", children, undefined, undefined);
}
var HeadingSixElement = {
  make: make$15
};
function make$16(at, match_, mode, hanging, split, voids, param) {
  return {
    at: Js_undefined.fromOption(at),
    match: Js_undefined.fromOption(match_),
    mode: Js_undefined.fromOption(mode),
    hanging: Js_undefined.fromOption(hanging),
    split: Js_undefined.fromOption(split),
    voids: Js_undefined.fromOption(voids)
  };
}
var SetNodesOptions = {
  make: make$16
};
function make$17(at, match_, mode, split, voids, param) {
  return {
    at: Js_undefined.fromOption(at),
    match: Js_undefined.fromOption(match_),
    mode: Js_undefined.fromOption(mode),
    split: Js_undefined.fromOption(split),
    voids: Js_undefined.fromOption(voids)
  };
}
var WrapNodesOptions = {
  make: make$17
};
function make$18(to_, at, match_, mode, voids, param) {
  return {
    at: Js_undefined.fromOption(at),
    match: Js_undefined.fromOption(match_),
    mode: Js_undefined.fromOption(mode),
    to: to_,
    voids: Js_undefined.fromOption(voids)
  };
}
var MoveNodesOptions = {
  make: make$18
};
function make$19(at, distance, unit, reverse, hanging, voids, param) {
  return {
    at: Js_undefined.fromOption(at),
    distance: Js_undefined.fromOption(distance),
    unit: Js_undefined.fromOption(unit),
    reverse: Js_undefined.fromOption(reverse),
    hanging: Js_undefined.fromOption(hanging),
    voids: Js_undefined.fromOption(voids)
  };
}
var DeleteOptions = {
  make: make$19
};
function make$20(at, match_, mode, hanging, select, voids, param) {
  return {
    at: Js_undefined.fromOption(at),
    match: Js_undefined.fromOption(match_),
    mode: Js_undefined.fromOption(mode),
    hanging: Js_undefined.fromOption(hanging),
    select: Js_undefined.fromOption(select),
    voids: Js_undefined.fromOption(voids)
  };
}
var InsertNodesOptions = {
  make: make$20
};
function make$21(at, match_, mode, always, height, voids, param) {
  return {
    at: Js_undefined.fromOption(at),
    match: Js_undefined.fromOption(match_),
    mode: Js_undefined.fromOption(mode),
    always: Js_undefined.fromOption(always),
    height: Js_undefined.fromOption(height),
    voids: Js_undefined.fromOption(voids)
  };
}
var SplitNodesOptions = {
  make: make$21
};
function make$22(at, match_, mode, hanging, voids, param) {
  return {
    at: Js_undefined.fromOption(at),
    match: Js_undefined.fromOption(match_),
    mode: Js_undefined.fromOption(mode),
    hanging: Js_undefined.fromOption(hanging),
    voids: Js_undefined.fromOption(voids)
  };
}
var RemoveNodesOptions = {
  make: make$22
};
function make$23(at, match_, mode, hanging, voids, param) {
  return {
    at: Js_undefined.fromOption(at),
    match: Js_undefined.fromOption(match_),
    mode: Js_undefined.fromOption(mode),
    hanging: Js_undefined.fromOption(hanging),
    voids: Js_undefined.fromOption(voids)
  };
}
var MergeNodesOptions = {
  make: make$23
};
function make$24(at, match_, mode, voids, param) {
  return {
    at: Js_undefined.fromOption(at),
    match: Js_undefined.fromOption(match_),
    mode: Js_undefined.fromOption(mode),
    voids: Js_undefined.fromOption(voids)
  };
}
var LiftNodesOptions = {
  make: make$24
};
function make$25(at, hanging, voids, param) {
  return {
    at: Js_undefined.fromOption(at),
    hanging: Js_undefined.fromOption(hanging),
    voids: Js_undefined.fromOption(voids)
  };
}
var InsertFragmentOptions = {
  make: make$25
};
function setNodesByType(editor, types, typ, url, at, mode, hanging, split, voids, param) {
  var props = {};
  Belt_Option.forEach(typ, function (typ$p) {
    props["typ"] = typ$p;
  });
  Belt_Option.forEach(url, function (url$p) {
    props["url"] = url$p;
  });
  Slate.Transforms.setNodes(editor, props, make$16(at, function (node) {
    var element = Curry._1($$Node.classify, node);
    switch (element.TAG | 0) {
      case /* Element */1:
        var element$1 = element._0;
        return Belt_Array.some(types, function (typ) {
          return typ === element$1.typ;
        });
      case /* Text */0:
      case /* Editor */2:
        return false;
    }
  }, mode, hanging, split, voids, undefined));
}
function setNodes(editor, typ, url, at, match_, mode, hanging, split, voids, param) {
  var props = {};
  Belt_Option.forEach(typ, function (typ$p) {
    props["typ"] = typ$p;
  });
  Belt_Option.forEach(url, function (url$p) {
    props["url"] = url$p;
  });
  Slate.Transforms.setNodes(editor, props, make$16(at, match_, mode, hanging, split, voids, undefined));
}
function unwrapNodes(editor, at, match_, mode, split, voids, param) {
  Slate.Transforms.unwrapNodes(editor, make$17(at, match_, mode, split, voids, undefined));
}
function unwrapNodesByType(editor, types, at, mode, split, voids, param) {
  return unwrapNodes(editor, at, function (node) {
    var element = Curry._1($$Node.classify, node);
    switch (element.TAG | 0) {
      case /* Element */1:
        var element$1 = element._0;
        return Belt_Array.some(types, function (typ) {
          return typ === element$1.typ;
        });
      case /* Text */0:
      case /* Editor */2:
        return false;
    }
  }, mode, split, voids, undefined);
}
function wrapNodes(editor, element, at, match_, mode, split, voids, param) {
  Slate.Transforms.wrapNodes(editor, element, make$17(at, match_, mode, split, voids, undefined));
}
function moveNodes(editor, to_, at, match_, mode, voids, param) {
  Slate.Transforms.moveNodes(editor, make$18(to_, at, match_, mode, voids, undefined));
}
function $$delete(editor, at, distance, unit, reverse, hanging, voids, param) {
  Slate.Transforms.delete(editor, make$19(at, distance, unit, reverse, hanging, voids, undefined));
}
function insertNode(editor, node, at, match_, mode, hanging, select, voids, param) {
  Slate.Transforms.insertNodes(editor, [node], make$20(at, match_, mode, hanging, select, voids, undefined));
}
function insertNodes(editor, nodes, at, match_, mode, hanging, select, voids, param) {
  Slate.Transforms.insertNodes(editor, nodes, make$20(at, match_, mode, hanging, select, voids, undefined));
}
function splitNodes(editor, at, match_, mode, always, height, voids, param) {
  Slate.Transforms.splitNodes(editor, make$21(at, match_, mode, always, height, voids, undefined));
}
function select(editor, target) {
  Slate.Transforms.select(editor, target);
}
function removeNodes(editor, at, match_, mode, hanging, voids, param) {
  Slate.Transforms.removeNodes(editor, make$22(at, match_, mode, hanging, voids, undefined));
}
function mergeNodes(editor, at, match_, mode, hanging, voids, param) {
  Slate.Transforms.mergeNodes(editor, make$23(at, match_, mode, hanging, voids, undefined));
}
function liftNodes(editor, at, match_, mode, voids, param) {
  Slate.Transforms.liftNodes(editor, make$24(at, match_, mode, voids, undefined));
}
function liftNodesByType(editor, types, at, mode, voids, param) {
  return liftNodes(editor, at, function (node) {
    var element = Curry._1($$Node.classify, node);
    switch (element.TAG | 0) {
      case /* Element */1:
        var element$1 = element._0;
        return Belt_Array.some(types, function (typ) {
          return typ === element$1.typ;
        });
      case /* Text */0:
      case /* Editor */2:
        return false;
    }
  }, mode, voids, undefined);
}
function insertFragment(editor, nodes, at, hanging, voids, param) {
  Slate.Transforms.insertFragment(editor, nodes, make$25(at, hanging, voids, undefined));
}
var Transforms = {
  SetNodesOptions: SetNodesOptions,
  WrapNodesOptions: WrapNodesOptions,
  MoveNodesOptions: MoveNodesOptions,
  DeleteOptions: DeleteOptions,
  InsertNodesOptions: InsertNodesOptions,
  SplitNodesOptions: SplitNodesOptions,
  RemoveNodesOptions: RemoveNodesOptions,
  MergeNodesOptions: MergeNodesOptions,
  LiftNodesOptions: LiftNodesOptions,
  InsertFragmentOptions: InsertFragmentOptions,
  setNodesByType: setNodesByType,
  setNodes: setNodes,
  unwrapNodes: unwrapNodes,
  unwrapNodesByType: unwrapNodesByType,
  wrapNodes: wrapNodes,
  moveNodes: moveNodes,
  $$delete: $$delete,
  insertNode: insertNode,
  insertNodes: insertNodes,
  splitNodes: splitNodes,
  select: select,
  removeNodes: removeNodes,
  mergeNodes: mergeNodes,
  liftNodes: liftNodes,
  liftNodesByType: liftNodesByType,
  insertFragment: insertFragment
};
var Decode;
var $$Option;
var $$Array;
exports.Decode = Decode;
exports.$$Option = $$Option;
exports.$$Array = $$Array;
exports.$$Text = $$Text;
exports.Marks = Marks;
exports.Path = Path;
exports.Point = Point;
exports.$$Range = $$Range;
exports.$$Location = $$Location;
exports.Span = Span;
exports.At = At;
exports.Operation = Operation;
exports.PointRef = PointRef;
exports.PathRef = PathRef;
exports.$$Element = $$Element;
exports.$$Node = $$Node;
exports.Descendant = Descendant;
exports.Ancestor = Ancestor;
exports.Editor = Editor;
exports.fromJson = fromJson;
exports.ParagraphElement = ParagraphElement;
exports.NumberedListElement = NumberedListElement;
exports.BulletsListElement = BulletsListElement;
exports.ListItemElement = ListItemElement;
exports.LinkElement = LinkElement;
exports.HeadingOneElement = HeadingOneElement;
exports.HeadingTwoElement = HeadingTwoElement;
exports.HeadingThreeElement = HeadingThreeElement;
exports.HeadingFourElement = HeadingFourElement;
exports.HeadingFiveElement = HeadingFiveElement;
exports.HeadingSixElement = HeadingSixElement;
exports.Transforms = Transforms;
/* Element Not a pure module */