import * as invoiceIntegrationModel from "../invoice-integration";
export const formattedNumber = inv => inv.formattedNumber;
export const status = inv => inv.status.type;
export const relationshipId = inv => inv.relationship.id;
export const customerName = inv => inv.relationship.customer.name;
export const vendorName = inv => inv.relationship.vendor.name;
export const relationshipTitle = inv => inv.relationship.title;
export const otherPartyCompanyName = (inv, isVendor) => isVendor ? customerName(inv) : vendorName(inv);
export const paymentMethodStatus = invoice => invoice.paymentMethodStatus;
export const issueDate = invoice => invoice.issuedOn || invoice.createdOn;
export function isSynced(inv) {
  return !!inv.integration && invoiceIntegrationModel.isSynced(inv.integration);
}
export function isNotSynced(inv) {
  return !!inv.integration && !invoiceIntegrationModel.isSynced(inv.integration);
}