import compact from 'lodash/compact';
import { Intent } from "../infra/access-control";
import { invoiceIntegrationModel, timeModel } from "../models";
import { getInvoiceQboErrorMessage } from "../utils/getQboErrorMessage";
export const failedIntegrationStatuses = new Set([invoiceIntegrationModel.Status.PaymentSyncFailed, invoiceIntegrationModel.Status.InvoiceSyncFailed, invoiceIntegrationModel.Status.RefundSyncFailed]);
export function getInvoiceQboActions(options) {
  const {
    integration,
    syncAction,
    retrySyncAction,
    mapContactsAction,
    mapServicesAction
  } = options;
  if (!integration) return [];
  const isPendingSync = integration.status === invoiceIntegrationModel.Status.PendingSync;
  const isPendingCustomerLinked = isPendingSync && !integration.customerLinked;
  const isPendingItemsLinked = isPendingSync && !integration.itemsLinked;
  const canBeSynced = isPendingSync && !isPendingCustomerLinked && !isPendingItemsLinked;
  return compact([failedIntegrationStatuses.has(integration.status) && retrySyncAction, isPendingCustomerLinked && mapContactsAction, isPendingItemsLinked && mapServicesAction, canBeSynced && syncAction]);
}
export function getInvoiceQboInfoStatus(status) {
  switch (status) {
    case invoiceIntegrationModel.Status.InvoiceRecorded:
    case invoiceIntegrationModel.Status.PaymentRecorded:
    case invoiceIntegrationModel.Status.VoidedRecorded:
    case invoiceIntegrationModel.Status.RefundRecorded:
      return 'success';
    case invoiceIntegrationModel.Status.InvoiceSyncFailed:
    case invoiceIntegrationModel.Status.PaymentSyncFailed:
    case invoiceIntegrationModel.Status.RefundSyncFailed:
      return 'error';
    default:
      return 'pending';
  }
}
export function makeRetrySyncAction(isSyncing, syncInvoice) {
  return {
    id: 'retry-sync-invoice',
    text: 'Retry sync',
    loading: isSyncing,
    loadingText: 'Syncing...',
    onClick: () => syncInvoice({
      isRetry: true
    })
  };
}
export function makeSyncAction(isSyncing, syncInvoice) {
  return {
    id: 'sync-invoice',
    text: 'Sync',
    loading: isSyncing,
    loadingText: 'Syncing...',
    onClick: () => syncInvoice()
  };
}
export function makeMapContactsAction(openSyncContactsModal) {
  return {
    id: 'map-contact',
    text: 'Map contact',
    onClick: openSyncContactsModal
  };
}
export function makeMapServicesAction(openSyncServicesModal) {
  return {
    id: 'map-services',
    text: 'Map services',
    intent: Intent.QboMapServices,
    onClick: openSyncServicesModal
  };
}
export function getInvoiceQboInfo(integration) {
  if (!integration) return '';
  switch (integration.status) {
    case invoiceIntegrationModel.Status.PendingSync:
      {
        const {
          customerLinked,
          itemsLinked
        } = integration;
        if (!customerLinked && !itemsLinked) {
          return 'Pending contact and services mapping with QuickBooks Online.';
        }
        if (!customerLinked) return 'Pending contact mapping with QuickBooks Online customers.';
        if (!itemsLinked) return 'Pending services mapping with QuickBooks Online items.';
        return 'Invoice is ready to sync with QuickBooks Online.';
      }
    case invoiceIntegrationModel.Status.InvoiceRecorded:
      return "Invoice recorded in QuickBooks Online on ".concat(timeModel.formatDateWithTime(integration.date), ".");
    case invoiceIntegrationModel.Status.VoidedRecorded:
      return "Invoice voided status was updated in QuickBooks on ".concat(timeModel.formatDateWithTime(integration.date), ".");
    case invoiceIntegrationModel.Status.PaymentRecorded:
      return "Payment recorded in QuickBooks Online on ".concat(timeModel.formatDateWithTime(integration.date), ".");
    case invoiceIntegrationModel.Status.RefundSyncFailed:
    case invoiceIntegrationModel.Status.PaymentSyncFailed:
    case invoiceIntegrationModel.Status.InvoiceSyncFailed:
      return getInvoiceQboErrorMessage(integration);
    default:
      return '';
  }
}