import * as moneyModel from "../money";
import * as timeModel from "../time";
export const fromDTO = dto => ({
  id: dto.id,
  billingDayOfMonth: dto.billingDayOfMonth,
  customer: dto.customer,
  adHocChargeStatus: dto.adHocChargeStatus,
  invoiceStatus: dto.invoiceStatus,
  serviceStatus: dto.serviceStatus,
  isSample: dto.isSample,
  progressStatus: dto.progressStatus,
  progressStatusTimestamp: dto.progressStatusTimestamp,
  status: dto.status,
  suggestedCustomerName: dto.suggestedCustomerName,
  title: dto.title,
  vendor: dto.vendor,
  seller: dto.seller,
  fundingSource: dto.fundingSource,
  agreementStatus: dto.agreementStatus,
  lastBilledDate: dto.lastBilledDate != null ? new Date(dto.lastBilledDate) : null,
  acv: moneyModel.fromDTO(dto.temp_acv),
  createdOn: new Date(dto.createdOn),
  effectiveDate: dto.effectiveDate && timeModel.fromDatestampArray(dto.effectiveDate)
});