import { useStoreActions } from "../contexts";
import { useSwitchBusiness } from "./useSwitchBusiness";
import * as Sentry from '@sentry/react';
export function useEnterAccountantAccess() {
  const {
    logBI
  } = useStoreActions();
  const switchBusiness = useSwitchBusiness();
  return function enterAccountantAccess(customerBusinessId, customerCompanyName) {
    logBI(13106, {
      customerId: customerBusinessId,
      companyName: customerCompanyName
    });
    Sentry.addBreadcrumb({
      type: 'enterAccountantAccess',
      data: {
        customerBusinessId
      }
    });
    return switchBusiness(customerBusinessId);
  };
}