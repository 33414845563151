import { Status } from "./model";
export function date(T) {
  switch (T.status) {
    case Status.InvoiceRecorded:
    case Status.VoidedRecorded:
    case Status.PaymentRecorded:
    case Status.InvoiceSyncFailed:
    case Status.PaymentSyncFailed:
      return new Date(T.date);
    default:
      return null;
  }
}
export function customerLinked(T) {
  return T.status === Status.PendingSync ? T.customerLinked : null;
}
export function itemsLinked(T) {
  return T.status === Status.PendingSync ? T.itemsLinked : null;
}
export function isSynced(integration) {
  return [Status.InvoiceRecorded, Status.VoidedRecorded, Status.PaymentRecorded, Status.RefundRecorded].includes(integration.status);
}
export function isSyncFailed(integration) {
  return [Status.InvoiceSyncFailed, Status.PaymentSyncFailed, Status.RefundSyncFailed].includes(integration.status);
}
export function isPendingSync(integration) {
  return integration.status === Status.PendingSync;
}